import { QuestionRes } from '../../../../../response/question.response';

export interface IntegerAnsComponentProps {
  question: QuestionRes;
}

const IntegerAnsComponent: React.FC<IntegerAnsComponentProps> = ({
  question,
}) => {
  return (
    <div className="single-choice-type">
      <p>{question.answerList}</p>
    </div>
  );
};

export default IntegerAnsComponent;
