import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ItemProp } from '../model/item-prop.model';

export interface SingleSelectDropDownProps {
  title: string;
  id: string;
  placeholder: string;
  dataList: ItemProp[];
  onChange: (value: string | number) => void;
  defaultSelected?: string;
  isInvalid: boolean | false;
  validationMessage: string | '';
}

const SingleSelectDropdown: React.FC<SingleSelectDropDownProps> = ({
  title,
  id,
  defaultSelected,
  dataList,
  onChange,
}) => {
  const [selectedElement, setSelectedElement] = useState<any>(defaultSelected);

  const handleSelectedElement = (eventKey: any, event: Object) => {
    setSelectedElement(eventKey);
  };

  useEffect(() => {
    onChange(selectedElement);
  }, [selectedElement]);

  return (
    <>
      <Dropdown
        onSelect={handleSelectedElement}
        className={`text-dimgray ft-14 tags--dropdown me-3 pt-2`}
      >
        <Dropdown.Toggle
          variant="white"
          id={id}
          className="tags--select-list border-gray text-dimgray w-100 text-start d-flex align-items-center justify-content-between position-relative heading--top-section ft-14 py-2"
        >
          <span className="position-absolute bg-white px-2 heading-name ft-12 font-weight-600 text-nero">
            {title}
          </span>
          {selectedElement
            ? dataList.filter((data: ItemProp) => {
                return data.value == selectedElement;
              })[0]?.label
            : '-Select-'}
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100 bg-off-white border-0 shadow ft-14 pt-2 custom--menu">
          {dataList.map((data: ItemProp, index: number) => {
            return (
              <Dropdown.Item
                as="button"
                eventKey={data.value}
                className={`py-2 ${
                  selectedElement === data.value ? 'bg-blue-200' : ''
                }  `}
              >
                {data.label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SingleSelectDropdown;
