import { SubjectRequest, SubjectSearchReq } from '../request/subject.request';
import { SubjectRes } from '../response/subject.response';
import httpClient from '../util/http-client';

export const SubjectService = {
  getSubjectById: async (id: string): Promise<(SubjectRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/subject/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getSubjectList: async (
    SubjectSearchReq: SubjectSearchReq,
  ): Promise<(SubjectRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/subject-search', SubjectSearchReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveSubject: async (
    subjectRequest: SubjectRequest,
  ): Promise<(SubjectRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/subject`, subjectRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateSubject: async (
    id: string,
    subjectRequest: SubjectRequest,
  ): Promise<(SubjectRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(`/subject/${id}`, subjectRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
