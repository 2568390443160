export enum I18nKey {
  DONOT_WAIT_LEARN_TEXT = 'donot_wait_learn',
  GRAB_OPPORTUNITY_NOW_TEXT = 'grab_opportunity_now',
  STUDENT_LOGIN_TEXT = 'student_login',
  STUDENT_ID_TEXT = 'student_id',
  ENTER_YOUR_ID_TEXT = 'enter_your_id',
  PASSWORD_KEY_TEXT = 'password',
  ENTER_YOUR_PASSCODE_TEXT = 'enter_your_passcode',
  LOGIN_TEXT = 'login',
  HOME_TEXT = 'home_key',
  REPORT_TEXT = 'report_key',
  TESTS_TEXT = 'tests_key',
  POWRED_BY_TEXT = 'powered_by',
  ADMIN_KEY = 'admin_key',
  ADMIN_ID_TEXT = 'admin_id_key',
  CREATE_TEXT = 'create_key',
  TEST_SETUP_TEXT = 'test_setup_key',
  SUMMARY_TEXT = 'summary_key',
  QUESTIONS_TEXT = 'questions_key',
  MARKS_TEXT = 'marks_key',
  ADD_QUESTION_TEXT = 'add_question_key',
  NEGATIVE_TEXT = 'negative_key',
  POSITIVE_TEXT = 'positive_key',
  PARTIAL_TEXT = 'partial_key',
  DURATION_TEXT = 'duration_key',
  SEC_TEXT = 'sec_key',
  SAVE_CHANGES_TEXT = 'save_changes_key',
  RESET_TEXT = 'reset_key',
  VIEW_FULL_TEXT = 'view_full_key',
  CLOSE_TEXT = 'close_key',
  ADD_SECTION_TEXT = 'add_section_key',
  TITLE_TEXT = 'title_key',
  SELECT_EXAM_TEXT = 'select_exam_key',
  DATE_TIME_TEXT = 'date_time_key',
  MINUTES_TEXT = 'minutes_key',
  LANGUAGE_TEXT = 'language_key',
  BATCH_LIST_TEXT = 'batch_list_key',
  ADD_INSTRUCTION_TEXT = 'add_instructions_key',
  INSTRUCTION_TEXT = 'instructions_key',
  SASSY_CBT_COPYRIGHT_TEXT = 'sassy_cbt_copyright',
  NEED_HELP_CONTACT_US_TEXT = 'need_help_contact_us',
  PREVIEW_TEXT = 'preview_key',
  PUBLISH_TEXT = 'publish_key',
  OVERALL_SUMMARY_TEXT = 'overall_summary_key',
  QUESTION_COUNT_TEXT = 'question_count_key',
  SECTION_COUNT_TEXT = 'section_count_key',
  SECTION_TEXT = 'section_key',
  TEST_DURATION_TEXT = 'test_duration_key',
  TOTAL_MARKS_TEXT = 'total_marks_key',
  ONLINE_TEXT = 'online_key',
  START_DATE_TIME_TEXT = 'start_date_time_key',
  END_DATE_TIME_TEXT = 'end_date_time_key',
  ALLOCATION_TEXT = 'allocation_key',
  EXAM_TEXT = 'exam_key',
  SECTION_DETAILS_TEXT = 'section_details_key',
  SECTION_NAME_TEXT = 'section_name_key',
  NO_OF_QUESTION_TEXT = 'no_of_question_key',
  TIME_DURATION_TEXT = 'time_duration_key',
  SEE_LESS_TEXT = 'see_less_key',
  ARE_YOU_SURE_TEXT = 'are_you_sure_key',
  CONTINUE_TEXT = 'continue_key',
  DISCARD_TEXT = 'discard_key',
  VIEW_DETAILS_TEXT = 'view_details_key',
  VIEW_LESS_TEXT = 'view_less_key',
  REMOVE_QUESTION_TEXT = 'remove_question',
  SEE_ALL_TEXT = 'see_all_key',
  DIFFICULTY_TEXT = 'difficulty_key',
  EXAM_HISTORY_TEXT = 'exam_history_key',
  MODE_TEXT = 'mode_key',
  STUDENTS_APPEARED_TEXT = 'students_appeared_key',
  SUBMIT = 'submit',
  ADD_SUBJECT = 'add_subject',
  SUBJECT_NAME_TEXT = 'subject_name_text',
  ENTER_SUBJECT_NAME_TEXT = 'enter_subject_name_text',
  UPDATE_SUBJECT = 'update_subject',
  ADD_TOPIC = 'add_topic',
  TOPIC_NAME_TEXT = 'topic_name_text',
  ENTER_TOPIC_NAME_TEXT = 'enter_topic_name_text',
  UPDATE_TOPIC = 'update_topic',
  SELECT_SUBJECT = 'select_subject',
  ADD_EXAM = 'add_exam',
  EXAM_NAME_TEXT = 'exam_name_text',
  ENTER_EXAM_NAME_TEXT = 'enter_exam_name_text',
  UPDATE_EXAM = 'update_exam',
  SELECT_EXAM = 'select_exam',
  ADD_PROGRAM = 'add_program',
  PROGRAM_NAME_TEXT = 'program_name_text',
  ENTER_PROGRAM_NAME_TEXT = 'enter_program_name_text',
  UPDATE_PROGRAM = 'update_program',
  SELECT_PROGRAM = 'select_program',
  ADD_CENTER = 'add_center',
  CENTER_NAME_TEXT = 'center_name_text',
  ENTER_CENTER_NAME_TEXT = 'enter_center_name_text',
  UPDATE_CENTER = 'update_center',
  SELECT_CENTER = 'select_center',
  ADD_CANDIDATE = 'add_candidate',
  CANDIDATE_NAME_TEXT = 'candidate_name_text',
  ENTER_CANDIDATE_NAME_TEXT = 'enter_candidate_name_text',
  UPDATE_CANDIDATE = 'update_candidate',
  SELECT_CANDIDATE = 'select_candidate',
  ADD_BATCH = 'add_batch',
  BATCH_NAME_TEXT = 'batch_name_text',
  ENTER_BATCH_NAME_TEXT = 'enter_batch_name_text',
  UPDATE_BATCH = 'update_batch',
  SELECT_BATCH = 'select_batch',
  SELECT_GENDER = 'select_gender',
  ENTER_CANDIDATE_DOB_TEXT = 'enter_candidate_dob_text',
  CANDIDATE_DOB_TEXT = 'candidate_dob_text',
  ENTER_CANDIDATE_EMAIL_TEXT = 'enter_candidate_email_text',
  CANDIDATE_EMAIL_TEXT = 'candidate_email_text',
  ENTER_CANDIDATE_PHONE_TEXT = 'enter_candidate_phone_text',
  CANDIDATE_PHONE_TEXT = 'candidate_phone_text',
  ADDRESS_LINE_ONE_TEXT = 'address_line_one_text',
  ENTER_ADDRESS_LINE_ONE_TEXT = 'enter_address_line_one_text',
  ADDRESS_LINE_TWO_TEXT = 'address_line_two_text',
  ENTER_ADDRESS_LINE_TWO_TEXT = 'enter_address_line_two_text',
  ADDRESS_STREET_TEXT = 'address_street_text',
  ENTER_ADDRESS_STREET_TEXT = 'enter_address_street_text',
  ADDRESS_CITY_TEXT = 'adress_city_text',
  ENTER_ADDRESS_CITY_TEXT = 'enter_adress_city_text',
  ADDRESS_STATE_TEXT = 'address_state_text',
  ENTER_ADDRESS_STATE_TEXT = 'enter_address_state_textt',
  ADDRESS_PIN_CODE_TEXT = 'address_pin_code_text',
  ENTER_ADDRESS_PIN_CODE_TEXT = 'enter_address_pin_code_text',
  STATUS_TEXT = 'status_text',
  SELECT_STATUS = 'select_status',
  SUBJECT_TEXT = 'subject_text',
  CENTER_TEXT = 'center_text',
  PROGRAM_TEXT = 'program_text',
  BATCH_TEXT = 'batch_text',
  PARENT_NAME_TEXT = 'parent_name_text',
  ENTER_PARENT_NAME_TEXT = 'enter_parent_name_text',
  ENTER_PARENT_EMAIL_TEXT = 'enter_parent_email_text',
  PARENT_PHONE_TEXT = 'parent_phone_text',
  ENTER_PARENT_PHONE_TEXT = 'enter_parent_phone_text',
  ENTER_PARENT_RELATIONSHIP_TEXT = 'enter_parent_relationship_text',
  PARENT_RELATIONSHIP_TEXT = 'parent_relationship_text',
  ENTER_PARENT_OCCUPATION_TEXT = 'enter_parent_relationship_text',
  PARENT_OCCUPATION_TEXT = 'parent_occupation_text',
  PARENT_EMAIL_TEXT = 'parent_email_text',
  ADD_QUESTION = 'add_question',
  ADD_TEST_TEXT = 'add_test_text',
  PREVIOUS_TEXT = 'previous_text',
  NEXT_TEXT = 'next_text',
  SAVE_DRAFT_TEXT = 'save_draft_text',
  UPDATE_QUESTION = 'update_question',
  LOG_IN_SUCCESS = 'log_in_success',
  FOR_GOT_PASS_LINK_SENT = 'for_got_pass_link_sent',
  LINK_COPIED = 'link_copied',
}
