import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../i18n/i18n.key';
import { TestRequest, TestSection } from '../../../../request/test.request';
import dayjs from 'dayjs';

export interface OverallSummaryComponentProps {
  testReq: TestRequest;
}

const OverallSummaryComponent: React.FC<OverallSummaryComponentProps> = ({
  testReq,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column w-100">
      <h6 className="text-nero ft-16 py-1 m-0 z-0">
        {t(I18nKey.OVERALL_SUMMARY_TEXT)}
      </h6>
      <div className="py-2 mx-3 my-2 border border-gray rounded d-flex">
        <div className="d-flex">
          <div className="px-4 border-end border-gray d-flex flex-column">
            <span className="mb-2">{t(I18nKey.QUESTION_COUNT_TEXT)}</span>
            <span className="ft-16 my-1 font-weight-700">
              {testReq.sectionList
                ?.map((section: TestSection) => {
                  return section.questionList.length;
                })
                .reduce((partialSum, a) => partialSum + a, 0)}{' '}
              {t(I18nKey.QUESTIONS_TEXT)}
            </span>
          </div>
          <div className="px-4 border-end border-gray d-flex flex-column">
            <span className="mb-2">{t(I18nKey.SECTION_COUNT_TEXT)}</span>
            <span className="ft-16 my-1 font-weight-700">
              {testReq.sectionList?.length} {t(I18nKey.SECTION_TEXT)}
            </span>
          </div>
          <div className="px-4 border-end border-gray d-flex flex-column">
            <span className="mb-2">{t(I18nKey.TEST_DURATION_TEXT)}</span>
            <span className="ft-16 my-1 font-weight-700">
              {testReq.duration / 60} {t(I18nKey.MINUTES_TEXT)}
            </span>
          </div>
          <div className="px-4 border-end border-gray d-flex flex-column">
            <span className="mb-2">{t(I18nKey.TOTAL_MARKS_TEXT)}</span>
            <span className="ft-16 my-1 font-weight-700">
              {testReq.fullMark}
            </span>
          </div>
          <div className="px-4 border-end border-gray d-flex flex-column">
            <span className="mb-2">{t(I18nKey.START_DATE_TIME_TEXT)}</span>
            <span className="ft-16 my-1 font-weight-700">
              {dayjs(testReq.startDate).format('D MMMM YYYY, h:mm A')}
            </span>
          </div>
          <div className="px-4 d-flex flex-column">
            <span className="mb-2">{t(I18nKey.END_DATE_TIME_TEXT)}</span>
            <span className="ft-16 my-1 font-weight-700">
              {dayjs(testReq.endDate).format('D MMMM YYYY, h:mm A')}
            </span>
          </div>
        </div>
        <div className="ms-auto d-flex align-items-center px-3">
          <span className="active-circle bg-green"></span>
          <span className="ft-14 text-nero font-weight-600">
            <span className="mx-2">{testReq.mode}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default OverallSummaryComponent;
