import { useState } from 'react';
import { MenuItem } from '../../../model/menu-item.model';
import { useTheme } from '../../../themes/theme.provider';
import { useWindow } from '../../../util/window-helper';
import MenuItemComponent from './menu-item.component';
import CollapsedMenuItemComponent from './collapsed-menu-item';

const HeaderComponent = () => {
  const { isMobile } = useWindow();

  const { theme } = useTheme();
  const [collapsed, setCollapsed] = useState(isMobile);

  const menuList = [
    {
      id: 'manage',
      name: 'Manage',
      icon: 'manage',
      path: '/config',
      subMenuList: [
        {
          id: 'candidate',
          name: 'Candidate',
          icon: 'candidate.svg',
          path: '/candidate',
          subMenuList: [],
        } as MenuItem,
        {
          id: 'batch',
          name: 'Batch',
          icon: 'batch.svg',
          path: '/batch',
          subMenuList: [],
        } as MenuItem,
        {
          id: 'exam',
          name: 'Exam',
          icon: 'exam.svg',
          path: '/exam',
          subMenuList: [],
        } as MenuItem,
        {
          id: 'center',
          name: 'Center',
          icon: 'center.png',
          path: '/center',
          subMenuList: [],
        } as MenuItem,
        {
          id: 'program',
          name: 'Program',
          icon: 'program.svg',
          path: '/program',
          subMenuList: [],
        } as MenuItem,
        {
          id: 'subject',
          name: 'Subject',
          icon: 'subject.svg',
          path: '/subject',
          subMenuList: [],
        } as MenuItem,
        {
          id: 'topic',
          name: 'Topic',
          icon: 'topic.svg',
          path: '/topic',
          subMenuList: [],
        } as MenuItem,
      ],
    } as MenuItem,
    {
      id: 'test',
      name: 'Test',
      icon: 'test',
      path: '/test',
      subMenuList: [
        {
          id: 'history',
          name: 'History',
          icon: 'history.svg',
          path: '/history',
          subMenuList: [],
        } as MenuItem,
        {
          id: 'create',
          name: 'create',
          icon: 'create_test.svg',
          path: '/create',
          subMenuList: [],
        } as MenuItem,
      ],
    } as MenuItem,
    {
      id: 'question',
      name: 'Question',
      icon: 'question',
      path: '/question',
      subMenuList: [],
    } as MenuItem,
  ];

  return (
    <>
      {collapsed ? (
        <div
          style={{
            backgroundColor: theme.primaryColor,
            width: 84,
            height: '100vh',
            padding: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'space-between',
              width: '100%',
            }}
          >
            <img src="/images/saissy_logo.svg" alt="" width={64} height={30} />
          </div>

          <div
            style={{
              marginTop: 16,
              overflow: 'auto',
            }}
          >
            {menuList.map((menuItem: MenuItem, index: number) => {
              return (
                <CollapsedMenuItemComponent
                  isFirst={index == 0}
                  isLast={index == menuList.length - 1}
                  menuItem={menuItem}
                />
              );
            })}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignContent: 'start',
              width: '100%',
              marginTop: 16,
              cursor: 'pointer',
            }}
          >
            <img
              src="/images/settings.svg"
              alt=""
              width={64}
              height={64}
              style={{ padding: 16 }}
            />
          </div>
          <img
            src="/images/open_menu.svg"
            alt=""
            width={64}
            height={64}
            style={{
              padding: 16,
              cursor: 'pointer',
            }}
            onClick={() => {
              setCollapsed(false);
            }}
          />
        </div>
      ) : (
        <div
          style={{
            backgroundColor: theme.primaryColor,
            width: 284,
            height: '100vh',
            padding: 24,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'space-between',
              width: '100%',
            }}
          >
            <img src="/images/saissy_logo.svg" alt="" width={64} height={30} />
            <img
              src="/images/close_menu.svg"
              alt=""
              width={32}
              height={32}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setCollapsed(true);
              }}
            />
          </div>

          <div
            style={{
              height: `calc(100% - 100px)`,
              marginTop: 16,
              overflow: 'auto',
            }}
          >
            {menuList.map((menuItem: MenuItem, index: number) => {
              return (
                <MenuItemComponent
                  isFirst={index == 0}
                  isLast={index == menuList.length - 1}
                  menuItem={menuItem}
                />
              );
            })}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignContent: 'start',
              width: `calc('100%' - '48px')`,
              paddingTop: 16,
              paddingBottom: 16,
              position: 'absolute',
              bottom: 0,
            }}
          >
            <img src="/images/settings.svg" alt="" width={20} height={20} />
            <p style={{ marginLeft: 16, color: theme.white }}>Settings</p>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderComponent;
