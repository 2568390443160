import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../../../../i18n/i18n.key';
import { Marking } from '../../../../../../../model/marking.model';

export interface ScoreComponentProps {
  marking: Marking;
}

const ScoreComponent: React.FC<ScoreComponentProps> = ({ marking }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className={`bg-white py-1 rounded d-flex align-items-center px-2`}>
        <div className="d-flex">
          <div className="d-flex align-items-baseline me-3 ft-12">
            <span className="bg-white ft-20 rounded-circle d-flex align-items-center justify-content-center white-circle text-red">
              {marking.negative}
            </span>
            {t(I18nKey.NEGATIVE_TEXT)}
          </div>
          <div className="d-flex align-items-baseline me-3 ft-12">
            <span className="bg-white ft-20 rounded-circle d-flex align-items-center justify-content-center white-circle text-dark-green">
              +{marking.positive}
            </span>
            {t(I18nKey.POSITIVE_TEXT)}
          </div>
          <div className="d-flex align-items-baseline ft-12">
            <span className="bg-white ft-20 rounded-circle d-flex align-items-center justify-content-center white-circle text-blue">
              +{marking.partial}
            </span>
            {t(I18nKey.PARTIAL_TEXT)}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ScoreComponent;
