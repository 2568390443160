export const groupList = <T, K>(list: T[], getKey: (item: T) => K): T[][] =>
  Array.from(
    list
      .reduce<Map<K, T[]>>((map, item) => {
        const key = getKey(item);
        const group = map.get(key) || [];
        group.push(item);
        map.set(key, group);
        return map;
      }, new Map<K, T[]>())
      .values(),
  )
    .sort((a: any, b: any) => {
      return (getKey(a[0]) as any).localeCompare(getKey(b[0]));
    })
    .reverse();

export const groupData = <T, K>(
  list: T[],
  getKey: (item: T) => K,
): Map<K, T[]> =>
  list.reduce<Map<K, T[]>>((map, item) => {
    const key = getKey(item);
    const group = map.get(key) || [];
    group.push(item);
    map.set(key, group);
    return map;
  }, new Map<K, T[]>());
