export const lightTheme = {
  primaryColor: '#002A52',
  secondaryColor: '#003F91',
  white: '#FFFFFF',
  themePrimary: '#ffffff',
  themeSecondary: '#222222',
  themeColor: '#676767',
  whiteColor: '#ffffff',
  greyColor: '#D9D9D9',
};
