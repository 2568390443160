import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../../i18n/i18n.key';
import QuestionHolderComponent from '../../../../shared/question/read/question-holder.component';
import ScoreComponent from '../score/score.component';
import QuestionInfoComponent from './question-info.component';
import ConfirmationView from '../../../../../view/confirmation.view';
import { Marking } from '../../../../../model/marking.model';
import { SectionQuestion } from '../../../../../request/test.request';

export interface QuestionDetailsComponentProps {
  sectionQuestion: SectionQuestion;
  deleteQuestion: (questionId: string) => void;
  updateQuestionMarkingAndDuration: (
    questionId: string,
    marking: Marking,
    duration: number,
  ) => void;
}

const QuestionDetailsComponent: React.FC<QuestionDetailsComponentProps> = ({
  sectionQuestion,
  deleteQuestion,
  updateQuestionMarkingAndDuration,
}) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [isRemoveModalShow, setIsRemoveModalShow] = useState(false);

  const updateQuestionMark = (marking: Marking, maxTime: number) => {
    updateQuestionMarkingAndDuration(sectionQuestion.id, marking, maxTime);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <Button
        variant="white"
        onClick={handleShow}
        className="p-0 ft-14 font-weight-600 border-0 text-decoration-underline rounded-0 mx-2 mb-2"
      >
        {t(I18nKey.VIEW_FULL_TEXT)}
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="add-edit-question--canvas w-75 rounded-start"
      >
        <Offcanvas.Header className="pb-0 py-2">
          <Button
            variant="white"
            onClick={handleClose}
            className="p-0 ms-auto my-3 me-2 d-flex align-items-center font-weight-600 ft-14 border-0"
          >
            <img src="/images/close.svg" alt="" className="me-2" />
            {t(I18nKey.CLOSE_TEXT)}
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <div className="d-flex align-items-center">
            {/* <QuestionInfoComponent /> */}

            <div className="d-flex align-items-center ms-auto">
              <div className="bg-white px-2 py-2">
                <b className="me-1">Difficulty:</b>
                <span className="bg-light-pink py-2 px-3 text-orange font-weight-600 rounded">
                  Medium
                </span>
              </div>

              <ScoreComponent
                updateQuestionMark={updateQuestionMark}
                marking={sectionQuestion.marking}
                maxTime={sectionQuestion.maxTime}
              />
            </div>
          </div>

          <QuestionHolderComponent question={sectionQuestion.question} />

          <div className="position-absolute canvas--footer border-top border-gray w-100 py-2 px-3 text-end bg-white d-flex justify-content-end">
            <Button
              variant="pale-lavender"
              onClick={() => setIsRemoveModalShow(true)}
              className="ft-14 border-0 text-nero mx-2 px-3 py-2 font-weight-600 d-flex align-items-center"
            >
              <img
                src="/images/delete.svg"
                alt=""
                height={14}
                className="me-2"
              />
              {t(I18nKey.REMOVE_QUESTION_TEXT)}
            </Button>
          </div>

          {isRemoveModalShow && (
            <ConfirmationView
              isModalShow={isRemoveModalShow}
              onSuccess={() => {
                setIsRemoveModalShow(false);
                handleClose();
                deleteQuestion(sectionQuestion.id);
              }}
              onCancel={() => {
                setIsRemoveModalShow(false);
              }}
              message={
                'Are you sure you want to remove this question from test?'
              }
              primaryBtnName="Remove"
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};

export default QuestionDetailsComponent;
