import { Badge, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../i18n/i18n.key';
import { useState, useEffect } from 'react';
import { BatchSearchReq } from '../../../../request/batch.request';
import { CenterSearchReq } from '../../../../request/center.request';
import { ExamSearchReq } from '../../../../request/exam.request';
import { ProgramSearchReq } from '../../../../request/program.request';
import { SubjectSearchReq } from '../../../../request/subject.request';
import { BatchRes } from '../../../../response/batch.response';
import { CenterRes } from '../../../../response/center.response';
import { ExamRes } from '../../../../response/exam.response';
import { ProgramRes } from '../../../../response/program.response';
import { SubjectRes } from '../../../../response/subject.response';
import { BatchService } from '../../../../service/batch.service';
import { CenterService } from '../../../../service/center.service';
import { ExamService } from '../../../../service/exam.service';
import { ProgramService } from '../../../../service/program.service';
import { SubjectService } from '../../../../service/subject.service';
import { TestRequest } from '../../../../request/test.request';

export interface AllocationComponentProps {
  testReq: TestRequest;
}

const AllocationComponent: React.FC<AllocationComponentProps> = ({
  testReq,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [batchList, setBatchList] = useState<BatchRes[]>([]);
  const [programList, setProgramList] = useState<ProgramRes[]>([]);
  const [centerList, setCenterList] = useState<CenterRes[]>([]);
  const [subjectList, setSubjectList] = useState<SubjectRes[]>([]);
  const [examList, setExamList] = useState<ExamRes[]>([]);

  const fetchData = async () => {
    const [batchList, err] = await BatchService.getBatchList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as BatchSearchReq);

    const [programList, err1] = await ProgramService.getProgramList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as ProgramSearchReq);

    const [centerList, err2] = await CenterService.getCenterList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as CenterSearchReq);

    const [subjectList, err3] = await SubjectService.getSubjectList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as SubjectSearchReq);

    const [examList, err4] = await ExamService.getExamList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as ExamSearchReq);

    setBatchList(batchList);
    setProgramList(programList);
    setCenterList(centerList);
    setSubjectList(subjectList);
    setExamList(examList);

    if (!err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <div className="d-flex flex-column w-100 my-3">
          <h6 className="text-nero ft-16 py-1 m-0">
            <b>{t(I18nKey.ALLOCATION_TEXT)}</b>
          </h6>
          <div className="py-2 px-3 mx-3 my-2 border border-gray rounded d-flex flex-column align-items-start gap-2">
            <div className="d-flex align-items-center w-100">
              <span className="w-7">{t(I18nKey.BATCH_LIST_TEXT)}</span>
              <div className="mx-3">
                {batchList
                  .filter((batch: BatchRes) => {
                    return testReq.batchIdList?.includes(batch.id);
                  })
                  .map((batch: BatchRes) => {
                    return (
                      <Badge
                        bg="light-700"
                        className="text-nero ft-14 me-2 px-3 py-2 font-weight-600"
                      >
                        {batch.name.en}
                      </Badge>
                    );
                  })}
              </div>
            </div>

            <div className="d-flex align-items-center w-100">
              <span className="w-7">{t(I18nKey.EXAM_TEXT)}</span>
              <div className="mx-3">
                {examList
                  .filter((exam: ExamRes) => {
                    return testReq.examIdList?.includes(exam.id);
                  })
                  .map((exam: ExamRes) => {
                    return (
                      <Badge
                        bg="light-700"
                        className="text-nero ft-14 me-2 px-3 py-2 font-weight-600"
                      >
                        {exam.name.en}
                      </Badge>
                    );
                  })}
              </div>
            </div>

            <div className="d-flex align-items-center w-100">
              <span className="w-7">{t(I18nKey.SUBJECT_TEXT)}</span>
              <div className="mx-3">
                {subjectList
                  .filter((subject: SubjectRes) => {
                    return testReq.subjectIdList?.includes(subject.id);
                  })
                  .map((subject: SubjectRes) => {
                    return (
                      <Badge
                        bg="light-700"
                        className="text-nero ft-14 me-2 px-3 py-2 font-weight-600"
                      >
                        {subject.name.en}
                      </Badge>
                    );
                  })}
              </div>
            </div>

            <div className="d-flex align-items-center w-100">
              <span className="w-7">{t(I18nKey.PROGRAM_TEXT)}</span>
              <div className="mx-3">
                {programList
                  .filter((program: ProgramRes) => {
                    return testReq.programIdList?.includes(program.id);
                  })
                  .map((program: ProgramRes) => {
                    return (
                      <Badge
                        bg="light-700"
                        className="text-nero ft-14 me-2 px-3 py-2 font-weight-600"
                      >
                        {program.name.en}
                      </Badge>
                    );
                  })}
              </div>
            </div>

            <div className="d-flex align-items-center w-100">
              <span className="w-7">{t(I18nKey.CENTER_TEXT)}</span>
              <div className="mx-3">
                {centerList
                  .filter((center: CenterRes) => {
                    return testReq.centerIdList?.includes(center.id);
                  })
                  .map((center: CenterRes) => {
                    return (
                      <Badge
                        bg="light-700"
                        className="text-nero ft-14 me-2 px-3 py-2 font-weight-600"
                      >
                        {center.name.en}
                      </Badge>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllocationComponent;
