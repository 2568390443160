import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { CKInputView } from '../../../../../view/ck-input.view';
import { QuestionOption } from '../../../../../response/question.response';

export interface MultipleChoiceEditComponentProps {
  updateOptionList: (optionList: QuestionOption[]) => void;
  optionList?: QuestionOption[];
}

export const MultipleChoiceEditComponent: React.FC<
  MultipleChoiceEditComponentProps
> = ({ updateOptionList, optionList }) => {
  const [newOptionList, setOptionList] = useState<QuestionOption[]>([]);

  useEffect(() => {
    updateOptionList(newOptionList);
  }, [newOptionList]);

  const addOption = () => {
    setOptionList((preState) => {
      const newOptionList = [...preState];
      newOptionList.push({
        id: `${newOptionList.length}`,
        value: {
          en: '',
        },
        correct: false,
      } as QuestionOption);
      return newOptionList;
    });
  };

  useEffect(() => {
    if (optionList) {
      setOptionList(optionList);
    }
  }, []);

  return (
    <>
      <h6>Please add options</h6>
      {newOptionList.map((option: QuestionOption, index: number) => {
        return (
          <div className="single-choice-type">
            <Form.Check
              type="checkbox"
              id={`option_${index}`}
              label={`Option ${index + 1}`}
              name={`option_name_${index}`}
              checked={option.correct}
              onChange={(e: any) => {
                setOptionList((preState) => {
                  const newOptionList = preState.map(
                    (_option: QuestionOption) => {
                      return {
                        ..._option,
                        correct:
                          option.id == _option.id
                            ? !_option.correct
                            : _option.correct,
                      };
                    },
                  );
                  return newOptionList;
                });
              }}
              className={`mb-2 bg-white d-flex align-items-start py-2 px-0 signle-type-inputs`}
            />

            <div className="custom-ckeditor--edit-view border border-light-blue rounded pb-2">
              <CKInputView
                initData={option.value.en}
                placeholder={'Please enter details'}
                onChange={(data: string) => {
                  setOptionList((preState) => {
                    const newOptionList = preState.map(
                      (_option: QuestionOption) => {
                        if (option.id == _option.id) {
                          return {
                            ..._option,
                            value: {
                              en: data,
                            },
                          };
                        } else {
                          return _option;
                        }
                      },
                    );
                    return newOptionList;
                  });
                }}
              />
            </div>
          </div>
        );
      })}
      <Button
        variant="primary"
        type="submit"
        className="text-white mt-4 w-64 font-weight-600"
        style={{
          width: 192,
        }}
        onClick={addOption}
      >
        Add New Option
      </Button>
    </>
  );
};

export default MultipleChoiceEditComponent;
