import React, { useState } from 'react';
import { Button, Col, Offcanvas, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../../../i18n/i18n.key';
import QuestionComponent from './component/question.component';
import { QuestionRes } from '../../../../../../response/question.response';

export interface AddQuestionComponentProps {
  selectedQuestionList: QuestionRes[];
  updateSelectedQuestion: (selectedQuestionList: QuestionRes[]) => void;
}

const AddQuestionComponent: React.FC<AddQuestionComponentProps> = ({
  selectedQuestionList,
  updateSelectedQuestion,
}) => {
  const { t } = useTranslation();

  const [newSelectedQuestionList, setSelectedQuestionList] =
    useState<QuestionRes[]>(selectedQuestionList);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <Button
        variant="pale-lavender"
        onClick={handleShow}
        className="ft-14 border-0 text-nero mx-2 px-3 py-2 d-flex align-items-center "
      >
        <img src="/images/add.svg" alt=""></img>
        <span className="ms-2 font-weight-600">
          {t(I18nKey.ADD_QUESTION_TEXT)}
        </span>
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="w-80 rounded-start"
      >
        <Offcanvas.Header>
          <Button
            variant="white"
            onClick={handleClose}
            className="p-0 ms-auto d-flex align-items-center font-weight-600 ft-14 border-0 shadow-none"
          >
            <img src="/images/close.svg" alt="" className="me-2" />
            {t(I18nKey.CLOSE_TEXT)}
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0 pb-5 d-flex align-items-center flex-column position-relative">
          <div className="pb-3 w-100">
            <QuestionComponent
              setSelectedQuestion={setSelectedQuestionList}
              selectedQuestionList={newSelectedQuestionList}
            />
          </div>

          <div className="position-absolute canvas--footer border-top border-gray w-100 py-2 px-3 text-end bg-white">
            <Button
              variant="pale-lavender"
              onClick={() => {
                updateSelectedQuestion(newSelectedQuestionList);
                handleClose();
              }}
              className="ft-14 border-0 text-nero mx-2 px-3 py-2"
            >
              <span className="ms-2 font-weight-600">
                Add {newSelectedQuestionList.length} Questions in Test
              </span>
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};

export default AddQuestionComponent;
