import { useEffect, useState } from 'react';
import { Badge, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface InputSelectViewProps {
  title: string;
  badgeBackgroud: string;
  badgeClassName: string;
  id: string;
  defaultValues?: string[];
  onChange: (dataList: string[]) => void;
  showBadge: boolean;
}

const InputSelectView: React.FC<InputSelectViewProps> = ({
  title,
  badgeBackgroud,
  badgeClassName,
  id,
  defaultValues,
  onChange,
  showBadge,
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [addedTagList, setAddedTagList] = useState<string[]>(
    defaultValues ? defaultValues : [],
  );

  const handleEnteredTags = (event: any) => {
    if (event.which == 13) {
      let enteredText = event.target.value;
      if (!addedTagList.includes(enteredText)) {
        setAddedTagList((previous) => [...previous, enteredText]);
      }
      setInputValue('');
    }
  };

  const handleOnChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const removeTag = (removeTag: string) => {
    setAddedTagList((previous) => {
      return previous.filter((tag: string) => {
        return tag != removeTag;
      });
    });
  };

  useEffect(() => {
    onChange(addedTagList);
  }, [addedTagList]);

  return (
    <>
      <div className="d-flex align-items-center pt-2">
        <div className="position-relative heading--top-section me-3">
          <span className="position-absolute bg-white px-2 heading-name ft-12 font-weight-700 text-nero mx-2">
            {addedTagList.length > 0
              ? `${addedTagList.length} ${title}`
              : title}
          </span>
          <Form.Control
            type="text"
            id={id}
            className="d-flex shadow-none rounded-2"
            onKeyDown={handleEnteredTags}
            onChange={handleOnChange}
            value={inputValue}
            placeholder={`Enter ${title}`}
          />
        </div>

        {addedTagList.length > 0 &&
          showBadge &&
          addedTagList.map((tag: string, index: number) => {
            return (
              <Badge
                bg={badgeBackgroud}
                key={`tag_name_badge_${index}`}
                className={`me-2 p-2 text-nero font-weight-500 d-flex align-items-center ft-12 ${badgeClassName}`}
              >
                <span className="me-3">{tag}</span>
                <Button
                  className="p-0 bg-transparent border-0 ms-auto d-flex"
                  onClick={() => removeTag(tag)}
                >
                  <img src="/images/cancel.svg" height={14} alt="" />
                </Button>
              </Badge>
            );
          })}
      </div>
    </>
  );
};

export default InputSelectView;
