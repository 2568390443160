import { Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n/i18n.key';
import { TestInfoRes } from '../../response/test.response';
import { useEffect, useState } from 'react';
import { TestService } from '../../service/test.service';
import { TestSearchReq } from '../../request/test.request';
import TestInfoComponent from './detail/test-info/test-info.component';
import { FloatingButtonView } from '../../view/floating-button.view';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import TestMonthTitleComponent from './detail/test-month-title.component';
import { groupList } from '../../util/group-convertor';
import dayjs from 'dayjs';

const TestPage = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const { openTestCreate, openUpdateTest, openTestDetail } = useSSNavigate();

  const [testList, setTestList] = useState<TestInfoRes[]>([]);

  const fetchTest = async () => {
    const [testList, err] = await TestService.getTestList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as TestSearchReq);

    setTestList(testList);
    setLoading(false);
  };

  useEffect(() => {
    fetchTest();
  }, []);

  return (
    <section className="text-nero">
      <div className="d-flex align-items-center">
        {/* <h6 className="m-0 ft-20 font-weight-600">
          {t(I18nKey.EXAM_HISTORY_TEXT)}
        </h6>
        <TestFiltersComponent /> */}
      </div>

      <>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <>
            {groupList(testList, (test: TestInfoRes) => {
              return dayjs(test.createdOn).format('MMM, YYYY');
            })
              .sort((a: TestInfoRes[], b: TestInfoRes[]) => {
                return dayjs(a[0].createdOn).millisecond >
                  dayjs(b[0].createdOn).millisecond
                  ? 1
                  : -1;
              })
              .map((testInfoList: TestInfoRes[]) => {
                return (
                  <div className="mb-3">
                    <TestMonthTitleComponent
                      title={dayjs(testInfoList[0].createdOn).format(
                        'MMM, YYYY',
                      )}
                      testCount={testInfoList.length}
                    />
                    {testInfoList.map((testInfoRes: TestInfoRes) => {
                      return (
                        <Row
                          className="m-0 py-2 px-2 my-2 rounded-1 border"
                          onClick={() => {
                            if (testInfoRes.status == 'live') {
                              openTestDetail(testInfoRes.id);
                            } else {
                              openUpdateTest(testInfoRes.id);
                            }
                          }}
                        >
                          <TestInfoComponent testInfoRes={testInfoRes} />
                        </Row>
                      );
                    })}
                  </div>
                );
              })}
          </>
        )}
      </>

      <FloatingButtonView
        innerElement={
          <>
            <img src="/images/add.svg" alt="" />
            <span className="ms-2">{t(I18nKey.ADD_TEST_TEXT)}</span>
          </>
        }
        onClick={() => {
          openTestCreate();
        }}
      />
    </section>
  );
};

export default TestPage;
