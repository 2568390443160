import { Row, Col } from 'react-bootstrap';
import MultiSelectDropdown from '../../../../../../../view/multi-select-drop-down.view';
import { QuestionSearchReq } from '../../../../../../../request/question.request';
import React, { useEffect, useState } from 'react';
import { SubjectSearchReq } from '../../../../../../../request/subject.request';
import { TopicSearchReq } from '../../../../../../../request/topic.request';
import { SubjectRes } from '../../../../../../../response/subject.response';
import { TopicRes } from '../../../../../../../response/topic.response';
import { SubjectService } from '../../../../../../../service/subject.service';
import { TopicService } from '../../../../../../../service/topic.service';
import { ItemProp } from '../../../../../../../model/item-prop.model';
import InputSelectView from '../../../../../../../view/input.select.view';

export interface QuestionFilterComponentProp {
  questionSearchReq: QuestionSearchReq;
  updateSearchReq: (questionSearchReq: QuestionSearchReq) => void;
}

const QuestionFilterComponent: React.FC<QuestionFilterComponentProp> = ({
  questionSearchReq,
  updateSearchReq,
}) => {
  const [topicList, setTopicList] = useState<TopicRes[]>([]);
  const [subjectList, setSubjectList] = useState<SubjectRes[]>([]);

  const fetchData = async () => {
    const [topicList, err] = await TopicService.getTopicList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as TopicSearchReq);

    const [subjectList, err1] = await SubjectService.getSubjectList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as SubjectSearchReq);

    setTopicList(topicList);
    setSubjectList(subjectList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Row className="">
      <Col className="ms-auto text-end d-flex justify-content-end align-items-center">
        <InputSelectView
          title={'Tags'}
          badgeBackgroud={'light-700'}
          badgeClassName={'rounded'}
          id="add_question_tag"
          onChange={(dataList: string[]) => {
            updateSearchReq({
              tagList: dataList,
            } as QuestionSearchReq);
          }}
          showBadge={false}
        />

        <MultiSelectDropdown
          title={'Subject'}
          badgeBackgroud={'light-700'}
          badgeClassName={'rounded'}
          id={'subject'}
          placeholder={''}
          dataList={subjectList.map((subject: SubjectRes) => {
            return {
              label: subject.name.en,
              value: subject.id,
            } as ItemProp;
          })}
          onChange={(values: any[]) => {
            updateSearchReq({
              subjectIdList: values,
            } as QuestionSearchReq);
          }}
          isInvalid={false}
          validationMessage={''}
          showBadge={true}
        />

        <MultiSelectDropdown
          title={'Type'}
          badgeBackgroud={'light-700'}
          badgeClassName={'rounded'}
          id={'type'}
          placeholder={''}
          dataList={[
            {
              label: 'Single choice',
              value: 'Single choice',
            } as ItemProp,
            {
              label: 'Multiple choice',
              value: 'Multiple choice',
            } as ItemProp,
            {
              label: 'Integer answer',
              value: 'Integer answer',
            } as ItemProp,
          ]}
          onChange={(values: any[]) => {
            updateSearchReq({
              typeList: values,
            } as QuestionSearchReq);
          }}
          isInvalid={false}
          validationMessage={''}
          showBadge={true}
        />

        <MultiSelectDropdown
          title={'Topic'}
          badgeBackgroud={'light-700'}
          badgeClassName={'rounded'}
          id={'topic'}
          placeholder={''}
          dataList={topicList.map((topic: TopicRes) => {
            return {
              label: topic.name.en,
              value: topic.id,
            } as ItemProp;
          })}
          onChange={(values: any[]) => {
            updateSearchReq({
              topicIdList: values,
            } as QuestionSearchReq);
          }}
          isInvalid={false}
          validationMessage={''}
          showBadge={true}
        />

        {/* <div className="pt-2">
          <Button
            variant="white"
            className="border border-dimgray p-2 ft-14 font-weight-600 d-flex justify-content-end align-items-center rounded-2"
          >
            <img src="/images/sort-by.svg" alt="" />
            <span className="mx-2">Sort By</span>
            <Badge bg="dimgray" className="rounded-circle small filter-badge">
              +9
            </Badge>
          </Button>
        </div> */}
      </Col>
    </Row>
  );
};

export default QuestionFilterComponent;
