import { useNavigate } from 'react-router';

export const useSSNavigate = () => {
  const navigate = useNavigate();

  const openAuth = () => {
    navigate('/auth', { replace: true });
  };

  const openHome = () => {
    navigate(`/`, { replace: true });
  };

  const openQuestion = () => {
    navigate(`/question`, { replace: false });
  };

  const openAddQuestion = () => {
    navigate(`/question/add`, { replace: false });
  };

  const openAddBulkQuestion = () => {
    navigate(`/question/bulk-add`, { replace: false });
  };

  const openUpdateQuestion = (id: string) => {
    navigate(`/question/${id}/update`, { replace: false });
  };

  const openTest = () => {
    navigate(`/test`, { replace: false });
  };

  const openTestCreate = () => {
    navigate(`/test/create`, { replace: false });
  };

  const openUpdateTest = (id: string) => {
    navigate(`/test/${id}/update`, { replace: false });
  };

  const openTestDetail = (id: string) => {
    navigate(`/test/${id}`, { replace: false });
  };

  const openCandidate = () => {
    navigate(`/config/candidate`, { replace: false });
  };

  const openAddCandidate = () => {
    navigate(`/config/candidate/add`, { replace: false });
  };

  const openAddBulkCandidate = () => {
    navigate(`/config/candidate/bulk-add`, { replace: false });
  };

  const openUpdateCandidate = (id: string) => {
    navigate(`/config/candidate/${id}/update`, { replace: false });
  };

  const openSubject = () => {
    navigate(`/config/subject`, { replace: false });
  };

  const openAddSubject = () => {
    navigate(`/config/subject/add`, { replace: false });
  };

  const openUpdateSubject = (id: string) => {
    navigate(`/config/subject/${id}/update`, { replace: false });
  };

  const openTopic = () => {
    navigate(`/config/topic`, { replace: false });
  };

  const openAddTopic = () => {
    navigate(`/config/topic/add`, { replace: false });
  };

  const openUpdateTopic = (id: string) => {
    navigate(`/config/topic/${id}/update`, { replace: false });
  };

  const openExam = () => {
    navigate(`/config/exam`, { replace: false });
  };

  const openAddExam = () => {
    navigate(`/config/exam/add`, { replace: false });
  };

  const openUpdateExam = (id: string) => {
    navigate(`/config/exam/${id}/update`, { replace: false });
  };

  const openProgram = () => {
    navigate(`/config/program`, { replace: false });
  };

  const openAddProgram = () => {
    navigate(`/config/program/add`, { replace: false });
  };

  const openUpdateProgram = (id: string) => {
    navigate(`/config/program/${id}/update`, { replace: false });
  };

  const openCenter = () => {
    navigate(`/config/center`, { replace: false });
  };

  const openAddCenter = () => {
    navigate(`/config/center/add`, { replace: false });
  };

  const openUpdateCenter = (id: string) => {
    navigate(`/config/center/${id}/update`, { replace: false });
  };

  const openBatch = () => {
    navigate(`/config/batch`, { replace: false });
  };

  const openAddBatch = () => {
    navigate(`/config/batch/add`, { replace: false });
  };

  const openUpdateBatch = (id: string) => {
    navigate(`/config/batch/${id}/update`, { replace: false });
  };

  const openPath = (path: string) => {
    navigate(path, { replace: false });
  };

  return {
    openAuth,
    openHome,
    openQuestion,
    openAddQuestion,
    openAddBulkQuestion,
    openUpdateQuestion,
    openTest,
    openTestCreate,
    openUpdateTest,
    openTestDetail,
    openCandidate,
    openAddCandidate,
    openAddBulkCandidate,
    openUpdateCandidate,
    openSubject,
    openAddSubject,
    openUpdateSubject,
    openTopic,
    openAddTopic,
    openUpdateTopic,
    openExam,
    openAddExam,
    openUpdateExam,
    openProgram,
    openAddProgram,
    openUpdateProgram,
    openCenter,
    openAddCenter,
    openUpdateCenter,
    openBatch,
    openAddBatch,
    openUpdateBatch,
    openPath,
  };
};
