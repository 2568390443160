import { Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../i18n/i18n.key';
import QuestionInfoComponent from './cell-info.component';
import { TestInfoRes } from '../../../../response/test.response';
import dayjs from 'dayjs';
import { MiniBatch } from '../../../../model/mini-batch.model';

export interface TestInfoComponentProps {
  testInfoRes: TestInfoRes;
}

const TestInfoComponent: React.FC<TestInfoComponentProps> = ({
  testInfoRes,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Col md={3} className="py-1 d-flex align-items-center">
        <div>
          <h6 className="ft-14 font-weight-600 text-nero">
            {testInfoRes.name.en}
          </h6>
          <span className="ft-14 text-dimgray font-weight-500 bg-anti-flash-white p-1 rounded-4 flex flex-row">
            <img
              src={`/images/${
                testInfoRes.status == 'live' ? 'green-dot.svg' : 'red-dot.svg'
              }`}
              alt=""
              width={12}
              height={12}
              style={{ marginLeft: 8 }}
            />
            <span className="p-2">{testInfoRes.status}</span>
          </span>
        </div>
      </Col>
      <Col md={2} className="py-1 d-flex align-items-center">
        <QuestionInfoComponent
          title={t(I18nKey.START_DATE_TIME_TEXT)}
          value={dayjs(testInfoRes.startDate).format('DD MMM YYYY hh:mm A')}
        />
      </Col>
      <Col md={2} className="py-1 d-flex align-items-center">
        <QuestionInfoComponent
          title={t(I18nKey.END_DATE_TIME_TEXT)}
          value={dayjs(testInfoRes.endDate).format('DD MMM YYYY hh:mm A')}
        />
      </Col>
      <Col md={3} className="py-1 d-flex align-items-center">
        <QuestionInfoComponent
          title={t(I18nKey.BATCH_TEXT)}
          value={testInfoRes?.batchList
            ?.map((bath: MiniBatch) => {
              return bath.name;
            })
            ?.toString()}
        />
      </Col>

      <Col md={2} className="py-1 d-flex align-items-center">
        {testInfoRes.status == 'live' ? (
          <Button className="border-0" style={{ background: '#BACAFF' }}>
            View Summary
          </Button>
        ) : (
          <Button className="border-0" style={{ background: '#BACAFF' }}>
            Update Test
          </Button>
        )}
      </Col>
    </>
  );
};

export default TestInfoComponent;
