import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { QuestionRes } from '../../../../../../../response/question.response';
import { I18nKey } from '../../../../../../../i18n/i18n.key';
import { CKTextView } from '../../../../../../../view/ck-text.view';

export interface QuestionListComponentProps {
  selectedQuestionList: QuestionRes[];
  questionList: QuestionRes[];
  setActiveQuestion: (question: QuestionRes) => void;
  activeQuestion: QuestionRes;
  setSelectedQuestion: (selectedQuestionList: QuestionRes[]) => void;
}

const QuestionListComponent: React.FC<QuestionListComponentProps> = ({
  selectedQuestionList,
  questionList,
  setActiveQuestion,
  activeQuestion,
  setSelectedQuestion,
}) => {
  const { t } = useTranslation();

  const selectQuestion = (question: QuestionRes) => {
    let _selectedQuestionList = [...selectedQuestionList];
    const added =
      _selectedQuestionList.filter((q: QuestionRes) => {
        return q.id == question.id;
      }).length > 0;
    if (added) {
      _selectedQuestionList = _selectedQuestionList.filter((q: QuestionRes) => {
        return q.id != question.id;
      });
    } else {
      _selectedQuestionList.push({ ...question });
    }
    setSelectedQuestion(_selectedQuestionList);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <Form.Check
          type="checkbox"
          className="mx-2 text-nero font-weight-600 ft-14"
          label="Select All"
          checked={selectedQuestionList.length == questionList.length}
          onChange={(e: any) => {
            if (e.target.checked) {
              setSelectedQuestion([...questionList]);
            } else {
              setSelectedQuestion([]);
            }
          }}
        />
        <div className="ms-auto ft-14 font-weight-600">
          ({selectedQuestionList.length} selected)
        </div>
      </div>
      <div className="flex-column question--list overflow-y-auto h-75vh">
        {questionList.map((question: QuestionRes, index: number) => {
          const checked =
            selectedQuestionList.filter((q: QuestionRes) => {
              return q.id == question.id;
            }).length > 0;

          return (
            <div
              id={`question_item_${question.id}`}
              className={`cursor-pointer text-nero p-2 d-flex mb-1 rounded-2 question--item ${
                question.id == activeQuestion.id ? 'active' : ''
              }`}
            >
              {checked && (
                <Form.Check
                  id={`question_item_check_${question.id}`}
                  type="checkbox"
                  className="me-2"
                  checked={true}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    selectQuestion(question);
                  }}
                />
              )}
              {!checked && (
                <Form.Check
                  id={`question_item_check_${question.id}`}
                  type="checkbox"
                  className="me-2"
                  checked={false}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    selectQuestion(question);
                  }}
                />
              )}
              <div
                className="d-flex flex-column"
                onClick={(e: any) => {
                  setActiveQuestion(question);
                }}
              >
                <h6 className="m-0 font-weight-600 mb-1">
                  Question {index + 1}
                </h6>
                <div className="text-nero ft-12">
                  <div
                    className="custom-ckeditor--view"
                    style={{
                      maxHeight: 84,
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${question.description.en}`,
                      }}
                    ></div>
                    {/* <CKTextView initData={question.description.en} /> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default QuestionListComponent;
