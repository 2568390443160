export interface TestMonthTitleComponentProps {
  title: string;
  testCount: number;
}

const TestMonthTitleComponent: React.FC<TestMonthTitleComponentProps> = ({
  title,
  testCount,
}) => {
  return (
    <div className="d-flex align-items-center ft-14">
      <div>
        <b className="text-nero">{title}</b>
        <span className="ms-1 me-2 text-dimgray">({testCount} papers)</span>
      </div>
      <span className="h-divider flex-grow-1 border-bottom border-gray"></span>
    </div>
  );
};

export default TestMonthTitleComponent;
