import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface TooltipProps {
  id: any;
  children: any;
  message: string;
}

export const TooltipView: React.FC<TooltipProps> = ({
  id,
  children,
  message,
}) => {
  return (
    <OverlayTrigger overlay={<Tooltip id={id}>{message}</Tooltip>}>
      <a href="#">{children}</a>
    </OverlayTrigger>
  );
};
