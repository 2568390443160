import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { BatchRes } from '../../response/batch.response';
import { BatchService } from '../../service/batch.service';
import { BatchSearchReq } from '../../request/batch.request';
import { FloatingButtonView } from '../../view/floating-button.view';
import { I18nKey } from '../../i18n/i18n.key';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useTheme } from '../../themes/theme.provider';

const BatchPage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [batchList, setBatchList] = useState<BatchRes[]>([]);
  const { openAddBatch, openUpdateBatch } = useSSNavigate();

  const fetchBatch = async () => {
    const [batchList, err] = await BatchService.getBatchList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as BatchSearchReq);

    setBatchList(batchList);
  };

  useEffect(() => {
    fetchBatch();
  }, []);

  return (
    <section>
      <Container>
        <Row
          className="rounded p-2"
          style={{
            backgroundColor: theme.greyColor,
          }}
        >
          <Col xs={3}>Name</Col>
          <Col xs={2}>Status</Col>
          <Col xs={2}>Start Date</Col>
          <Col xs={2}>End Date</Col>
          <Col xs={2}>Actions</Col>
        </Row>
        {batchList?.map((batch: BatchRes) => {
          return (
            <Row className="rounded border p-2">
              <Col xs={3}>{batch.name.en}</Col>
              <Col xs={2}>{batch.status}</Col>
              <Col xs={2}>{dayjs(batch.start).format('DD MMM YYYY')}</Col>
              <Col xs={2}>{dayjs(batch.end).format('DD MMM YYYY')}</Col>
              <Col xs={2}>
                <Button
                  variant="link"
                  className="text-primary text-decoration-underline p-0 font-weight-600"
                  onClick={() => {
                    openUpdateBatch(batch.id);
                  }}
                >
                  <img
                    src="/images/edit_button.svg"
                    alt=""
                    width={20}
                    height={20}
                  />
                </Button>
              </Col>
            </Row>
          );
        })}
      </Container>
      <FloatingButtonView
        innerElement={
          <>
            <img src="/images/add.svg" alt="" />
            <span className="ms-2">{t(I18nKey.ADD_BATCH)}</span>
          </>
        }
        onClick={() => {
          openAddBatch();
        }}
      />
    </section>
  );
};

export default BatchPage;
