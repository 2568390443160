import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ExamRes } from '../../response/exam.response';
import { ExamService } from '../../service/exam.service';
import { FloatingButtonView } from '../../view/floating-button.view';
import { I18nKey } from '../../i18n/i18n.key';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useTranslation } from 'react-i18next';
import { ExamSearchReq } from '../../request/exam.request';
import { useTheme } from '../../themes/theme.provider';

const ExamPage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [examList, setExamList] = useState<ExamRes[]>([]);
  const { openAddExam, openUpdateExam } = useSSNavigate();

  const fetchExam = async () => {
    const [examList, err] = await ExamService.getExamList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as ExamSearchReq);

    setExamList(examList);
  };

  useEffect(() => {
    fetchExam();
  }, []);

  return (
    <section>
      <Container>
        <Row
          className="rounded p-2"
          style={{
            backgroundColor: theme.greyColor,
          }}
        >
          <Col xs={6}>Name</Col>
          <Col xs={6}>Actions</Col>
        </Row>
        {examList.map((exam: ExamRes) => {
          return (
            <Row className="rounded border p-2">
              <Col xs={6}>{exam.name.en}</Col>
              <Col xs={6}>
                <Button
                  variant="link"
                  className="text-primary text-decoration-underline p-0 font-weight-600"
                  onClick={() => {
                    openUpdateExam(exam.id);
                  }}
                >
                  <img
                    src="/images/edit_button.svg"
                    alt=""
                    width={20}
                    height={20}
                  />
                </Button>
              </Col>
            </Row>
          );
        })}
      </Container>

      <FloatingButtonView
        innerElement={
          <>
            <img src="/images/add.svg" alt="" />
            <span className="ms-2">{t(I18nKey.ADD_EXAM)}</span>
          </>
        }
        onClick={() => {
          openAddExam();
        }}
      />
    </section>
  );
};

export default ExamPage;
