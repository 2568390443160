import { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { MiniBatch } from '../../../model/mini-batch.model';
import { MiniCenter } from '../../../model/mini-center.model';
import { MiniExam } from '../../../model/mini-exam.model';
import { MiniProgram } from '../../../model/mini-program.model';
import { MiniSubject } from '../../../model/mini-subject.model';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { TestSection, TestRequest } from '../../../request/test.request';
import { TestService } from '../../../service/test.service';
import SummaryComponent from '../create/summary/summary.component';

const TestDetailComponent = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { openTest } = useSSNavigate();

  let { id } = useParams();

  const [testReq, setTestReq] = useState({
    sectionList: [] as TestSection[],
    status: 'live',
    mode: 'online',
  } as TestRequest);

  const getTest = async () => {
    setLoading(true);
    const [test, error] = await TestService.getTestById(id as string);

    setLoading(false);
    setTestReq((preState) => {
      return {
        ...preState,
        name: test.name,
        description: test.description,
        instruction: test.instruction,
        status: test.status,
        mode: test.mode,
        startDate: test.startDate,
        endDate: test.endDate,
        fullMark: test.fullMark,
        duration: test.duration,
        batchIdList: test.batchList.map((batch: MiniBatch) => {
          return batch.id;
        }),
        programIdList: test.programList.map((program: MiniProgram) => {
          return program.id;
        }),
        centerIdList: test.centerList?.map((center: MiniCenter) => {
          return center.id;
        }),
        subjectIdList: test.subjectList?.map((subject: MiniSubject) => {
          return subject.id;
        }),
        examIdList: test.examList.map((exam: MiniExam) => {
          return exam.id;
        }),
        sectionList: test.sectionList.map((section: TestSection) => {
          return {
            ...section,
            questionList: section.questionList.map((question: any) => {
              return {
                ...question,
                question: question,
              };
            }),
          };
        }),
      };
    });
  };

  useEffect(() => {
    getTest();
  }, []);

  return (
    <section className="d-flex">
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <div className="position-relative create--test-tab-wrapper w-100 ">
          <SummaryComponent
            testReq={testReq}
            moveToStep={() => {}}
            saveDraft={() => {}}
            saveTest={() => {}}
            readonly={true}
          />
        </div>
      )}
    </section>
  );
};

export default TestDetailComponent;
