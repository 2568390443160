import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { CenterRes } from '../../response/center.response';
import { CenterService } from '../../service/center.service';
import { CenterSearchReq } from '../../request/center.request';
import { FloatingButtonView } from '../../view/floating-button.view';
import { I18nKey } from '../../i18n/i18n.key';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../themes/theme.provider';

const CenterPage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [centerList, setCenterList] = useState<CenterRes[]>([]);
  const { openAddCenter, openUpdateCenter } = useSSNavigate();

  const fetchCenter = async () => {
    const [centerList, err] = await CenterService.getCenterList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as CenterSearchReq);

    setCenterList(centerList);
  };

  useEffect(() => {
    fetchCenter();
  }, []);

  return (
    <section>
      <Container>
        <Row
          className="rounded p-2"
          style={{
            backgroundColor: theme.greyColor,
          }}
        >
          <Col xs={4}>Name</Col>
          <Col xs={4}>Status</Col>
          <Col xs={4}>Actions</Col>
        </Row>
        {centerList?.map((center: CenterRes) => {
          return (
            <Row className="rounded border p-2">
              <Col xs={4}>{center.name.en}</Col>
              <Col xs={4}>{center.status}</Col>
              <Col xs={4}>
                <Button
                  variant="link"
                  className="text-primary text-decoration-underline p-0 font-weight-600"
                  onClick={() => {
                    openUpdateCenter(center.id);
                  }}
                >
                  <img
                    src="/images/edit_button.svg"
                    alt=""
                    width={20}
                    height={20}
                  />
                </Button>
              </Col>
            </Row>
          );
        })}
      </Container>
      <FloatingButtonView
        innerElement={
          <>
            <img src="/images/add.svg" alt="" />
            <span className="ms-2">{t(I18nKey.ADD_CENTER)}</span>
          </>
        }
        onClick={() => {
          openAddCenter();
        }}
      />
    </section>
  );
};

export default CenterPage;
