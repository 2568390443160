import { useState } from 'react';
import { Tabs, Tab, ProgressBar, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CreateTestStepsEnum } from '../../../enum/test-type.enum';
import { I18nKey } from '../../../i18n/i18n.key';
import CreateTestComponent from './component/create-test.component';
import SummaryComponent from './summary/summary.component';
import TestSetupComponent from './test-setup/test-setup.component';
import {
  SectionQuestion,
  TestRequest,
  TestSection,
} from '../../../request/test.request';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { TestService } from '../../../service/test.service';
import ConfirmationView from '../../../view/confirmation.view';

const CreateTestPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [testSaveAlert, setTestSaveAlert] = useState(false);

  const { openTest, openUpdateTest } = useSSNavigate();

  const [testReq, setTestReq] = useState({
    name: {
      en: '',
    },
    description: {
      en: '',
    },
    instruction: {
      en: '',
    },
    sectionList: [] as TestSection[],
    status: 'draft',
    mode: 'online',
    startDate: new Date(),
    endDate: new Date(),
  } as TestRequest);

  const [step, setStep] = useState<CreateTestStepsEnum>(
    CreateTestStepsEnum.Create,
  );

  const updateTestReq = (data: any) => {
    setTestReq((preState) => {
      return {
        ...preState,
        ...data,
      };
    });
  };

  const addNewSection = () => {
    setTestReq((preState) => {
      const newTestReq = { ...preState };
      const testSection: TestSection = {
        id: `${newTestReq.sectionList.length}`,
        name: {
          en: '',
        },
        instruction: {
          en: '',
        },
        maxTime: 0,
        timed: false,
        fullMark: 0,
        questionList: [],
        level: 0.5,
        shuffling: false,
        order: newTestReq.sectionList.length,
      };
      newTestReq.sectionList.push(testSection);
      return newTestReq;
    });
  };

  const deleteSection = (id: string) => {
    setTestReq((preState) => {
      const newTestReq = { ...preState };
      newTestReq.sectionList = newTestReq.sectionList.filter(
        (section: TestSection) => {
          return id != section.id;
        },
      );
      return newTestReq;
    });
  };

  const updateSection = (_section: TestSection) => {
    setTestReq((preState) => {
      const newTestReq = { ...preState };
      newTestReq.sectionList = newTestReq.sectionList.map(
        (section: TestSection) => {
          if (_section.id == section.id) {
            return {
              ..._section,
              fullMark: _section.questionList.reduce(
                (partialSum, sectionQuestion: SectionQuestion) =>
                  partialSum + sectionQuestion.marking.positive,
                0,
              ),
            };
          } else {
            return section;
          }
        },
      );
      return {
        ...newTestReq,
        fullMark: newTestReq.sectionList.reduce(
          (partialSum, testSection: TestSection) =>
            partialSum + testSection.fullMark,
          0,
        ),
      };
    });
  };

  const saveTest = async () => {
    setTestSaveAlert(true);
  };

  const saveTestMain = async () => {
    setLoading(true);
    const [data, error] = await TestService.saveTest({
      ...testReq,
      status: 'live',
    });

    if (error) {
      setLoading(false);
    } else {
      openTest();
    }
  };

  const moveToStep = (step: CreateTestStepsEnum) => {
    setStep(step);
  };

  const saveDraft = async () => {
    setLoading(true);
    const [testRes, error] = await TestService.saveTest({
      ...testReq,
      status: 'draft',
    });

    if (error) {
      setLoading(false);
    } else {
      openUpdateTest(testRes.id);
    }
  };

  return (
    <section className="d-flex">
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <div className="position-relative create--test-tab-wrapper w-100 ">
          <Tabs
            id="createTestTabs"
            activeKey={step}
            onSelect={(k: any) => setStep(k)}
            className="mb-4 create--test-styles border-0"
          >
            <Tab
              eventKey={CreateTestStepsEnum.Create}
              className="border-0"
              title={
                <div className="d-flex align-items-center">
                  <img
                    src={`/images/${
                      step === CreateTestStepsEnum.Create
                        ? 'create.svg'
                        : 'create-gray.svg'
                    } `}
                    alt=""
                    height={18}
                  />{' '}
                  <b className="ms-1">{t(I18nKey.CREATE_TEXT)}</b>
                </div>
              }
            >
              <CreateTestComponent
                addNewSection={addNewSection}
                deleteSection={deleteSection}
                testReq={testReq}
                updateSection={updateSection}
                moveToStep={moveToStep}
                saveDraft={saveDraft}
              />
            </Tab>
            <Tab
              eventKey={CreateTestStepsEnum.TestSetup}
              title={
                <div>
                  <img
                    src={`/images/${
                      step === CreateTestStepsEnum.TestSetup
                        ? 'setup.svg'
                        : 'setup-gray.svg'
                    } `}
                    height={18}
                    alt=""
                  />{' '}
                  <b className="ms-1">{t(I18nKey.TEST_SETUP_TEXT)}</b>
                </div>
              }
            >
              <TestSetupComponent
                updateTestReq={updateTestReq}
                testReq={testReq}
                moveToStep={moveToStep}
                saveDraft={saveDraft}
              />
            </Tab>
            <Tab
              eventKey={CreateTestStepsEnum.Summary}
              title={
                <div>
                  <img
                    src={`/images/${
                      step === CreateTestStepsEnum.Summary
                        ? 'summary.svg'
                        : 'summary-gray.svg'
                    } `}
                    height={18}
                    alt=""
                  />{' '}
                  <b className="ms-1">{t(I18nKey.SUMMARY_TEXT)}</b>
                </div>
              }
            >
              <SummaryComponent
                testReq={testReq}
                moveToStep={moveToStep}
                saveTest={saveTest}
                saveDraft={saveDraft}
              />
            </Tab>
          </Tabs>
          <div className="position-absolute test--progress-status">
            <ProgressBar
              now={
                step === CreateTestStepsEnum.Create
                  ? 33
                  : step === CreateTestStepsEnum.TestSetup
                  ? 66
                  : 100
              }
              className="bg-skyblue border border-blue-yonder"
            />
          </div>

          <div className="position-absolute test--info-data d-flex align-items-center">
            <div className="ms-3">
              <strong>
                {testReq.sectionList
                  ?.map((section: TestSection) => {
                    return section.questionList.length;
                  })
                  .reduce((partialSum, a) => partialSum + a, 0)}
                <span className="mx-1 ft-12">{t(I18nKey.QUESTIONS_TEXT)}</span>
              </strong>
            </div>
            <div className="ms-3">
              <strong>
                {testReq.fullMark}
                <span className="mx-1 ft-12">{t(I18nKey.MARKS_TEXT)}</span>{' '}
              </strong>
            </div>
          </div>
        </div>
      )}

      {testSaveAlert && (
        <ConfirmationView
          isModalShow={testSaveAlert}
          onSuccess={() => {
            saveTestMain();
          }}
          onCancel={() => {
            setTestSaveAlert(false);
          }}
          message={
            'Are you sure you want to save test, you can not make any change after publish?'
          }
          primaryBtnName="Publish"
        />
      )}
    </section>
  );
};

export default CreateTestPage;
