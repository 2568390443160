interface CellInfoComponentProps {
  title: string;
  value: any;
}

const CellInfoComponent: React.FC<CellInfoComponentProps> = ({
  title,
  value,
}) => {
  return (
    <div className="text-nero w-100">
      <div className="d-flex flex-col align-items-center">
        <span className="font-weight-400 me-2 ft-14">{title}</span>
      </div>
      <div className="d-flex flex-col align-items-center">
        <b className="ft-14 font-weight-600">{value}</b>
      </div>
    </div>
  );
};

export default CellInfoComponent;
