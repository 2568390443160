import { QuestionType } from '../../../../enum/question-type.enum';
import { QuestionRes } from '../../../../response/question.response';
import { CKTextView } from '../../../../view/ck-text.view';
import IntegerAnsComponent from './type/intiger-ans.component';
import MultipleChoiceComponent from './type/multiple-choice.component';
import SingleChoiceComponent from './type/single-choice.component';

export interface QuestionHolderComponentProp {
  question: QuestionRes;
}

const QuestionHolderComponent: React.FC<QuestionHolderComponentProp> = ({
  question,
}) => {
  const getAnswerView = (type: QuestionType): React.ReactNode => {
    const components: Record<QuestionType, React.ReactNode> = {
      [QuestionType.SINGLE_CHOICE]: (
        <SingleChoiceComponent question={question} />
      ),
      [QuestionType.MULTIPLE_CHOICE]: (
        <MultipleChoiceComponent question={question} />
      ),
      [QuestionType.INT_ANS]: <IntegerAnsComponent question={question} />,
    };

    return components[type] || <p>Select Question Type</p>;
  };

  const questionDataView = getAnswerView(question.type);

  return (
    <>
      <div className="custom-ckeditor--view">
        <CKTextView initData={question.description.en} />
      </div>

      <div className="mt-3 mx-2">{questionDataView}</div>
    </>
  );
};

export default QuestionHolderComponent;
