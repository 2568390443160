import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

interface FilePickerButtonProps {
  id: string;
  allowMultiple: boolean;
  enableDragAndDrop: boolean;
  allowedMimeTypes?: string[];
  onUpload: (files: File[]) => void;
}

export const FilePickerView: React.FC<FilePickerButtonProps> = ({
  id,
  allowMultiple,
  enableDragAndDrop,
  allowedMimeTypes = [],
  onUpload,
}) => {
  const [fileList, setFileList] = useState<{ file: File; id: string }[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const filesArray = Array.from(selectedFiles)
        .filter((file) => {
          const fileType = file.type;
          return allowedMimeTypes.includes(fileType);
        })
        .slice(0, allowMultiple ? undefined : 1) // Slice to handle single file if needed
        .map((file) => ({ file, id: generateId() }));

      setFileList((prevFileList) => [...prevFileList, ...filesArray]);
    }
  };

  const handleDeleteFile = (id: string) => {
    setFileList((prevFileList) =>
      prevFileList.filter((file) => file.id !== id),
    );
  };

  const handleUploadFiles = () => {
    onUpload(fileList.map(({ file }) => file));
  };

  const handleButtonClick = () => {
    const fileInput = document.getElementById(id) as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click(); // Click the hidden file input
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles) {
      const filesArray = Array.from(droppedFiles)
        .filter((file) => {
          const fileType = file.type;
          return allowedMimeTypes.includes(fileType);
        })
        .slice(0, allowMultiple ? undefined : 1) // Slice to handle single file if needed
        .map((file) => ({ file, id: generateId() }));

      setFileList((prevFileList) => [...prevFileList, ...filesArray]);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const generateId = () => {
    return Math.random().toString(36).slice(2, 11);
  };

  return (
    <>
      {fileList.length == 0 ? (
        <>
          {enableDragAndDrop ? (
            <div
              className="card-body border rounded p-4 m-4"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <div>
                <img
                  style={{
                    width: 152,
                    height: 152,
                  }}
                  src="/images/file_select.svg"
                  className="card-img-top"
                  alt="..."
                />
                <p>Drag and drop your file here.</p>
              </div>
              <p>-or-</p>
              <Button
                type="button"
                className="btn btn-secondary mt-2"
                onClick={handleButtonClick}
              >
                Browse
              </Button>
            </div>
          ) : (
            <Button
              type="button"
              className="btn btn-secondary mt-2"
              onClick={handleButtonClick}
            >
              Browse
            </Button>
          )}
          <input
            type="file"
            id={id}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            multiple={allowMultiple} // Allow multiple file selection based on the prop
            accept={allowedMimeTypes.join(',')}
          />
        </>
      ) : (
        <div className="card-body border rounded p-4 m-4">
          <h5 style={{ textAlign: 'left' }}>Selected file's:</h5>
          <ul className="list-group">
            {fileList.map(({ file, id }) => (
              <li
                key={id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <span>{file.name}</span>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDeleteFile(id)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
          <Button
            variant="primary"
            className="mt-3"
            onClick={handleUploadFiles}
          >
            Upload
          </Button>
        </div>
      )}
    </>
  );
};
