import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../i18n/i18n.key';
import {
  SectionQuestion,
  TestRequest,
  TestSection,
} from '../../../../request/test.request';
import { groupList } from '../../../../util/group-convertor';

export interface SectionDetailsComponentProps {
  testReq: TestRequest;
}

const SectionDetailsComponent: React.FC<SectionDetailsComponentProps> = ({
  testReq,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column w-100 mb-3">
      <h6 className="text-nero ft-16 py-1 m-0">
        <b>{t(I18nKey.SECTION_DETAILS_TEXT)}</b>
      </h6>
      <div className="px-3 mx-3 my-2 border border-gray rounded ft-14">
        <Row className="border-bottom border-gray mb-2 py-2 mx-0">
          <Col md={5} className="py-1 px-1">
            <span className="text-uppercase font-weight-700">
              {t(I18nKey.SECTION_NAME_TEXT)}
            </span>
          </Col>
          <Col md={2} className="py-1 px-1 text-center">
            <span className="text-uppercase font-weight-700">
              {t(I18nKey.NO_OF_QUESTION_TEXT)}
            </span>
          </Col>
          <Col md={2} className="py-1 px-1 text-center">
            <span className="text-uppercase font-weight-700">
              {t(I18nKey.MARKS_TEXT)}
            </span>
          </Col>
          <Col md={3} className="py-1 px-1 text-end">
            <span className="text-uppercase font-weight-700">
              {t(I18nKey.TIME_DURATION_TEXT)}
            </span>
          </Col>
        </Row>

        <Row className="my-2 mx-0">
          {testReq.sectionList.map(
            (section: TestSection, sectionIndex: number) => {
              return (
                <>
                  <Col md={5} className=" px-1">
                    <div className="d-flex flex-column">
                      <span className="font-weight-600 mb-1">
                        {sectionIndex + 1}. {section.name.en}
                      </span>
                    </div>
                  </Col>
                  <Col md={2} className=" px-1">
                    <div className="d-flex flex-column text-center">
                      <span className="font-weight-600 mb-1 ">
                        {section.questionList.length}
                      </span>
                    </div>
                  </Col>
                  <Col md={2} className=" px-1">
                    <div className="d-flex flex-column text-center">
                      <span className="font-weight-600 mb-1 ">
                        {section.fullMark}
                      </span>
                    </div>
                  </Col>
                  <Col md={3} className="px-1 text-end">
                    {section.timed ? (
                      <span className="font-weight-600">
                        {section.maxTime / 60} minutes
                      </span>
                    ) : (
                      <span className="font-weight-600">Untimed</span>
                    )}
                  </Col>
                  {groupList(
                    section.questionList,
                    (sectionQuestion: SectionQuestion) => {
                      return sectionQuestion.question.type;
                    },
                  ).map(
                    (sectionQuestionList: SectionQuestion[], index: number) => {
                      return (
                        <>
                          <Col md={5} className=" px-1">
                            <div className="d-flex flex-column">
                              <div className="d-flex flex-column px-3">
                                <span className="mb-1">
                                  {sectionQuestionList[0].question.type}{' '}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md={2} className=" px-1">
                            <div className="d-flex flex-column text-center">
                              <span className="mb-1">
                                {sectionQuestionList.length}
                              </span>
                            </div>
                          </Col>
                          <Col md={2} className=" px-1">
                            <div className="d-flex flex-column text-center">
                              <span className="mb-1">
                                {sectionQuestionList.reduce(
                                  (
                                    partialSum,
                                    sectionQuestion: SectionQuestion,
                                  ) =>
                                    partialSum +
                                    sectionQuestion.marking.positive,
                                  0,
                                )}
                              </span>
                            </div>
                          </Col>
                        </>
                      );
                    },
                  )}
                </>
              );
            },
          )}
        </Row>
      </div>
    </div>
  );
};

export default SectionDetailsComponent;
