import { useEffect, useState } from 'react';
import { Button, Spinner, Form, InputGroup } from 'react-bootstrap';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../i18n/i18n.key';
import { useTranslation } from 'react-i18next';
import { TopicRequest } from '../../request/topic.request';
import { TopicService } from '../../service/topic.service';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useParams } from 'react-router';
import { SubjectRes } from '../../response/subject.response';
import { SubjectSearchReq } from '../../request/subject.request';
import { SubjectService } from '../../service/subject.service';

const UpdateTopicPage = () => {
  const { t } = useTranslation();
  const { openTopic } = useSSNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    subjectId: '',
  });
  const [subjectList, setSubjectList] = useState<SubjectRes[]>([]);
  const [name, setName] = useState('');
  const [subjectId, setSubjectId] = useState('');

  const updateTopic = async (topicReq: TopicRequest) => {
    const [data, error] = await TopicService.updateTopic(
      id as string,
      topicReq,
    );

    if (error) {
      setLoading(false);
    } else {
      openTopic();
    }
  };

  const getTopic = async () => {
    const [topic, error] = await TopicService.getTopicById(id as string);

    setLoading(false);
    if (error) {
      openTopic();
    } else {
      setName(topic.name.en);
      setSubjectId(topic.subject.id);
    }
  };

  const fetchSubject = async () => {
    const [subjectList, err] = await SubjectService.getSubjectList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as SubjectSearchReq);

    setSubjectList(subjectList);
  };

  useEffect(() => {
    getTopic();
    fetchSubject();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (!validator.isLength(name, { min: 2, max: 30 })) {
      _errors.name = 'Please enter valid Name';
    }

    if (!validator.isLength(subjectId, { min: 2, max: 30 })) {
      _errors.subjectId = 'Please select subject';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      const topicReq = {
        name: {
          en: name,
        },
        subjectId: subjectId,
      } as TopicRequest;
      updateTopic(topicReq);
    }
  };

  const formStyle = {
    width: '96%',
    maxWidth: '769px',
    margin: 'auto',
  };

  return (
    <section className="my-3">
      <div
        className="rounded-16 bg-white p-4 ft-14 mt-lg-0 mt-5 custom-shadow text-nero z-1"
        style={formStyle}
      >
        <h5 className="mb-4 text-nero">{t(I18nKey.UPDATE_TOPIC)}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>{t(I18nKey.TOPIC_NAME_TEXT)}</Form.Label>

              <Form.Control
                required
                type="text"
                value={name}
                isInvalid={!!errors.name}
                onChange={(e) => setName(e.target.value)}
                className={`ft-14 px-1 shadow-none border ${
                  errors.name ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_TOPIC_NAME_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid name
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="subjectId" className="mb-3">
              <Form.Label>{t(I18nKey.SELECT_SUBJECT)}</Form.Label>

              <Form.Select
                required
                value={subjectId}
                isInvalid={!!errors.subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
                className={`ft-14 px-1 shadow-none border ${
                  errors.name ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.SELECT_SUBJECT)}
              >
                {subjectList.map((subject: SubjectRes) => {
                  return <option value={subject.id}>{subject.name.en}</option>;
                })}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors.subjectId}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="text-center mt-4">
              <Button
                variant="primary"
                type="submit"
                className="text-white ft-14 font-weight-600"
              >
                {t(I18nKey.SUBMIT)}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </section>
  );
};

export default UpdateTopicPage;
