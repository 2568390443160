import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../../i18n/i18n.key';
import { CKTextView } from '../../../../../view/ck-text.view';
import QuestionDetailsComponent from './question-details.component';
import ScoreComponent from '../score/score.component';
import { Marking } from '../../../../../model/marking.model';
import { SectionQuestion } from '../../../../../request/test.request';
import { QuestionType } from '../../../../../enum/question-type.enum';

export interface SectionQuestionGroupComponentProps {
  sectionQuestionList: SectionQuestion[];
  deleteQuestion: (questionId: string) => void;
  updateQuestionMarkingAndDuration: (
    questionId: string,
    marking: Marking,
    maxTime: number,
  ) => void;
  updateQuestionTypeMarkingAndDuration: (
    type: QuestionType,
    marking: Marking,
    maxTime: number,
  ) => void;
}

const SectionQuestionGroupComponent: React.FC<
  SectionQuestionGroupComponentProps
> = ({
  sectionQuestionList,
  deleteQuestion,
  updateQuestionMarkingAndDuration,
  updateQuestionTypeMarkingAndDuration,
}) => {
  const { t } = useTranslation();
  const [isShowViewDetails, setIsShowViewDetails] = useState(true);

  const updateQuestionGroupMark = (marking: Marking, maxTime: number) => {
    updateQuestionTypeMarkingAndDuration(
      sectionQuestionList[0]?.question?.type!,
      marking,
      maxTime,
    );
  };

  return (
    <>
      <Row className="ques--accordion-wrapper border border-gray ft-14 rounded-2 text-nero mb-2 mx-0">
        <Col xs={12}>
          <div className="text-nero d-flex align-items-center justify-content-between">
            <div>
              <span className="font-weight-700">
                {sectionQuestionList[0]?.question?.type}
              </span>

              <Button
                variant="white"
                style={{
                  width: '100px',
                }}
                className="p-0 mx-2 text-blue-500 ft-14 text-decoration-underline font-weight-600 shadow-none border-0 text-start"
                onClick={() => setIsShowViewDetails(!isShowViewDetails)}
              >
                {isShowViewDetails && <span>{t(I18nKey.VIEW_LESS_TEXT)}</span>}
                {!isShowViewDetails && (
                  <span>{t(I18nKey.VIEW_DETAILS_TEXT)}</span>
                )}
              </Button>
            </div>
            <div className="font-weight-500 text-lowercase">
              <b>{sectionQuestionList.length}</b> {t(I18nKey.QUESTIONS_TEXT)}
            </div>
            <div className="font-weight-500 text-lowercase">
              <b>
                {sectionQuestionList.reduce(
                  (partialSum, sectionQuestion: SectionQuestion) =>
                    partialSum + sectionQuestion.marking.positive,
                  0,
                )}
              </b>{' '}
              {t(I18nKey.MARKS_TEXT)}
            </div>

            <div className="ft-14 d-flex ">
              <ScoreComponent
                updateQuestionMark={updateQuestionGroupMark}
                marking={sectionQuestionList[0]?.marking}
                maxTime={sectionQuestionList[0]?.maxTime}
              />
            </div>
          </div>

          {isShowViewDetails && (
            <>
              {sectionQuestionList.map(
                (sectionQuestion: SectionQuestion, index: number) => {
                  return (
                    <div className="custom-ckeditor--view py-2">
                      <div
                        style={{
                          maxHeight: 104,
                          overflow: 'hidden',
                        }}
                      >
                        <b>Question {index + 1}. </b>
                        <CKTextView
                          initData={sectionQuestion.question.description.en}
                        />
                      </div>
                      <QuestionDetailsComponent
                        sectionQuestion={sectionQuestion}
                        deleteQuestion={deleteQuestion}
                        updateQuestionMarkingAndDuration={
                          updateQuestionMarkingAndDuration
                        }
                      />
                    </div>
                  );
                },
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default SectionQuestionGroupComponent;
