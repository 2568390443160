import { useEffect, useState } from 'react';
import { Button, Spinner, Form, InputGroup } from 'react-bootstrap';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../i18n/i18n.key';
import { useTranslation } from 'react-i18next';
import { ExamService } from '../../service/exam.service';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useParams } from 'react-router';
import { ExamRequest } from '../../request/exam.request';

const UpdateExamPage = () => {
  const { t } = useTranslation();
  const { openExam } = useSSNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
  });
  const [name, setName] = useState('');

  const updateExam = async (examReq: ExamRequest) => {
    const [data, error] = await ExamService.updateExam(id as string, examReq);

    if (error) {
      setLoading(false);
    } else {
      openExam();
    }
  };

  const getExam = async () => {
    const [exam, error] = await ExamService.getExamById(id as string);

    setLoading(false);
    if (error) {
      openExam();
    } else {
      setName(exam.name.en);
    }
  };

  useEffect(() => {
    getExam();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (!validator.isLength(name, { min: 2, max: 30 })) {
      _errors.name = 'Please enter valid Name';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      const examReq = {
        name: {
          en: name,
        },
      } as ExamRequest;
      updateExam(examReq);
    }
  };

  const formStyle = {
    width: '96%',
    maxWidth: '769px',
    margin: 'auto',
  };

  return (
    <section className="my-3">
      <div
        className="rounded-16 bg-white p-4 ft-14 mt-lg-0 mt-5 custom-shadow text-nero z-1"
        style={formStyle}
      >
        <h5 className="mb-4 text-nero">{t(I18nKey.UPDATE_EXAM)}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>{t(I18nKey.EXAM_NAME_TEXT)}</Form.Label>

              <Form.Control
                required
                type="text"
                value={name}
                isInvalid={!!errors.name}
                onChange={(e) => setName(e.target.value)}
                className={`ft-14 px-1 shadow-none border ${
                  errors.name ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_EXAM_NAME_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid name
              </Form.Control.Feedback>
            </Form.Group>
            <div className="text-center mt-4">
              <Button
                variant="primary"
                type="submit"
                className="text-white ft-14 font-weight-600"
              >
                {t(I18nKey.SUBMIT)}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </section>
  );
};

export default UpdateExamPage;
