import { useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useTheme } from '../../themes/theme.provider';
import { FilePickerView } from '../../view/file-picker.view';
import * as XLSX from 'xlsx';
import { CandidateService } from '../../service/candidate.service';
import { CandidateRequest } from '../../request/candidate.request';
import validator from 'validator';
import { CandidateInfo } from '../../response/candidate.response';
import { ParentInfo } from '../../model/parent-info.model';
import { Address } from '../../model/address.model';
import { Media } from '../../model/media.model';

const AddBulkCandidatePage = () => {
  const { t } = useTranslation();
  const { openCandidate, openAddCandidate } = useSSNavigate();
  const { theme } = useTheme();

  const [loading, setLoading] = useState(false);
  const [candidateReqList, setCandidateReqList] = useState<CandidateRequest[]>(
    [],
  );

  const saveAllCandidate = async () => {
    if (candidateReqList.length == 0) {
      return;
    }
    setLoading(true);

    const batchSize = 100;
    const totalRequests = candidateReqList.length;
    let processedCount = 0;

    while (processedCount < totalRequests) {
      const batch = candidateReqList.slice(
        processedCount,
        processedCount + batchSize,
      );
      const promiseList = batch.map((candidateReq) => {
        return CandidateService.saveCandidate(candidateReq);
      });

      try {
        const resultList = await Promise.all(promiseList);
        resultList.forEach(([data, error]) => {});
      } catch (err) {
        console.error('Error occurred:', err);
      }

      processedCount += batch.length;
    }
    setLoading(false);
    openCandidate();
  };

  const handleTemplateDownload = () => {
    const fileUrl = '/files/Candidate Sample Sheet.xlsx';

    // Create an invisible link element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'Candidate Sample Sheet.xlsx';

    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const parseFileData = async (fileList: File[]) => {
    setLoading(true);
    setCandidateReqList([]);
    const file = fileList[0];

    try {
      const parsedDataList = await parseFile(file);
      const candidateReqList = validateData(parsedDataList);
      setCandidateReqList(candidateReqList);
    } catch (error) {
      console.error('Error reading file:', error);
    }
    setLoading(false);
  };

  const validateData = (parsedDataList: any[]) => {
    const validateCandidateReqList: CandidateRequest[] = [];
    const invalidCandidateList = [];
    const date = new Date();
    const dateValid = new Date();

    const emptyList: string[] = [];
    const emptyParentList: ParentInfo[] = [];

    parsedDataList.forEach((candidate) => {
      const candidateReq = {
        candidateInfo: {
          name: '',
          phone: '',
          email: '',
          dateOfBirth: new Date(date.setFullYear(date.getFullYear() - 5)),
          gender: 'Male',
        } as CandidateInfo,
        status: 'active',
        address: {
          lineOne: '',
          lineTwo: '',
          street: '',
          city: '',
          state: '',
          pinCode: '',
        } as Address,
        profilePic: {} as Media,
        validTill: new Date(dateValid.setMonth(dateValid.getMonth() + 11)),
        batchIdList: emptyList,
        programIdList: emptyList,
        centerIdList: emptyList,
        subjectIdList: emptyList,
        examIdList: emptyList,
        parentList: emptyParentList,
        parentInfo: {
          name: '',
          phone: '',
          email: '',
          relationship: '',
          occupation: '',
        },
      } as CandidateRequest;

      let isError = false;

      const _errors: any = {
        candidateInfo: {},
        address: {},
        parentInfo: {},
      };

      // validation
      if (!validator.isLength(candidate['Name'], { min: 2, max: 30 })) {
        isError = true;
        _errors.candidateInfo.name = 'Please enter valid Name';
      } else {
        candidateReq.candidateInfo.name = candidate['Name'];
      }
      if (!validator.isMobilePhone(candidate['Mobile'], 'en-IN')) {
        isError = true;
        _errors.candidateInfo.phone = 'Please enter valid phone';
      } else {
        candidateReq.candidateInfo.phone = candidate['Mobile'];
      }
      if (!validator.isEmail(candidate['Email'])) {
        isError = true;
        _errors.candidateInfo.email = 'Please enter valid email';
      } else {
        candidateReq.candidateInfo.email = candidate['Email'];
      }
      if (
        new Date(candidate['DOB(DD-MM-YYYY)']).getTime() > new Date().getTime()
      ) {
        isError = true;
        _errors.candidateInfo.dateOfBirth = 'Please enter valid dob';
      } else {
        candidateReq.candidateInfo.dateOfBirth = candidate['DOB(DD-MM-YYYY)'];
      }
      if (
        !validator.isLength(candidate['Gender'], {
          min: 2,
          max: 30,
        })
      ) {
        isError = true;
        _errors.candidateInfo.gender = 'Please enter valid gender';
      } else {
        candidateReq.candidateInfo.gender = candidate['Gender'];
      }

      if (!validator.isLength(candidate['Status'], { min: 2, max: 30 })) {
        isError = true;
        _errors.status = 'Please select status';
      } else {
        candidateReq.status = candidate['Status'];
      }

      if (!validator.isLength(candidate['Line one'], { min: 0, max: 30 })) {
        isError = true;
        _errors.address.lineOne = 'Please enter address';
      } else {
        candidateReq.address.lineOne = candidate['Line one'];
        candidateReq.address.lineTwo = candidate['Line two'];
      }

      if (!validator.isLength(candidate['City'], { min: 0, max: 30 })) {
        isError = true;
        _errors.address.city = 'Please enter valid city';
      } else {
        candidateReq.address.city = candidate['City'];
      }

      if (!validator.isLength(candidate['State'], { min: 0, max: 30 })) {
        isError = true;
        _errors.address.state = 'Please enter valid state';
      } else {
        candidateReq.address.state = candidate['State'];
      }

      if (!validator.isLength(candidate['Pin Code'], { min: 0, max: 6 })) {
        isError = true;
        _errors.address.pinCode = 'Please enter valid pin code';
      } else {
        candidateReq.address.pinCode = candidate['Pin Code'];
      }

      if (
        new Date(candidate['Valid Till(DD-MM-YYYY)']).getTime() <
        new Date().getTime()
      ) {
        isError = true;
        _errors.validTill = 'Please select valid Date';
      } else {
        candidateReq.validTill = new Date(candidate['Valid Till(DD-MM-YYYY)']);
      }

      if (candidate['Batch'].length <= 0) {
        isError = true;
        _errors.batchIdList = 'Please select batch';
      } else {
        candidateReq.batchIdList = candidate['Batch']?.split(',') ?? [];
      }

      if (candidate['Program'].length == 0) {
        isError = true;
        _errors.programIdList = 'Please select program';
      } else {
        candidateReq.programIdList = candidate['Program']?.split(',') ?? [];
      }

      if (candidate['Subject'].length == 0) {
        isError = true;
        _errors.subjectIdList = 'Please select subject';
      } else {
        candidateReq.subjectIdList = candidate['Subject']?.split(',') ?? [];
      }

      if (candidate['Center'].length == 0) {
        isError = true;
        _errors.centerIdList = 'Please select center';
      } else {
        candidateReq.centerIdList = candidate['Center']?.split(',') ?? [];
      }

      if (candidate['Exam'].length == 0) {
        isError = true;
        _errors.examIdList = 'Please select exam';
      } else {
        candidateReq.examIdList = candidate['Exam']?.split(',') ?? [];
      }

      //parent validation

      if (!validator.isLength(candidate['Parent Name'], { min: 0, max: 30 })) {
        isError = true;
        _errors.parentInfo.name = 'Please enter valid Name';
      } else {
        candidateReq.parentInfo.name = candidate['Parent Name'];
      }
      if (
        candidate['Parent Phone'].length > 0 &&
        !validator.isMobilePhone(candidate['Parent Phone'], 'en-IN')
      ) {
        isError = true;
        _errors.parentInfo.phone = 'Please enter valid phone';
      } else {
        candidateReq.parentInfo.phone = candidate['Parent Phone'];
      }
      if (
        candidate['Parent Email'].length > 0 &&
        !validator.isEmail(candidate['Parent Email'])
      ) {
        isError = true;
        _errors.parentInfo.email = 'Please enter valid email';
      } else {
        candidateReq.parentInfo.email = candidate['Parent Email'];
      }

      if (
        !validator.isLength(candidate['Parent Relationship'], {
          min: 0,
          max: 30,
        })
      ) {
        isError = true;
        _errors.parentInfo.relationship = 'Please enter valid relationship';
      } else {
        candidateReq.parentInfo.relationship = candidate['Parent Relationship'];
      }

      if (
        !validator.isLength(candidate['Parent Occupation'], {
          min: 0,
          max: 30,
        })
      ) {
        isError = true;
        _errors.parentInfo.occupation = 'Please enter valid occupation';
      } else {
        candidateReq.parentInfo.occupation = candidate['Parent Occupation'];
      }

      if (isError) {
        invalidCandidateList.push(candidate);
      } else {
        candidateReq.parentList[0] = candidateReq.parentInfo;
        candidateReq.candidateInfo.dateOfBirth = new Date(
          candidateReq.candidateInfo.dateOfBirth,
        );
        candidateReq.validTill = new Date(candidateReq.validTill);

        validateCandidateReqList.push(candidateReq);
      }
    });

    return validateCandidateReqList;
  };

  const parseFile = (file: File): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = new Uint8Array(event.target!.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0]; // Assuming data is in the first sheet
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet, {
          dateNF: 'DD-MM-YYYY', // Specify the date format for parsing
          raw: false, // Dates will be converted to Date objects
        });

        resolve(parsedData);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  return (
    <>
      {candidateReqList.length == 0 ? (
        <section>
          <Row
            className="p-4"
            style={{
              minHeight: '96vh',
            }}
          >
            <Col
              xs={12}
              lg={6}
              className="rounded-0"
              style={{ borderRightStyle: 'solid', paddingRight: 96 }}
            >
              <h5 className="card-title font-weight-bold">
                Bulk Candidates Import
              </h5>
              <p className="font-weight-light mt-2">
                Upload multiple candidates in few simple steps
              </p>
              <div
                className="card-body p-4"
                style={{
                  backgroundColor: '#F7FBFF',
                }}
              >
                <div className="d-flex">
                  <img
                    style={{
                      width: 36,
                      height: 36,
                    }}
                    src="/images/prepare_csv.svg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="px-2">
                    <h5 className="card-title font-weight-bold">
                      Prepare csv file
                    </h5>
                    <p>Use Saissy’s .csv file template</p>
                  </div>
                </div>
                <img
                  style={{
                    width: 4,
                    height: 66,
                    marginLeft: 16,
                    marginBottom: 16,
                  }}
                  src="/images/verticle_line.svg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="d-flex">
                  <img
                    style={{
                      width: 36,
                      height: 36,
                    }}
                    src="/images/upload_csv.svg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="px-2">
                    <h5 className="card-title font-weight-bold">
                      Upload csv file
                    </h5>
                    <p>Data errors will be checked and notified</p>
                  </div>
                </div>
                <img
                  style={{
                    width: 4,
                    height: 66,
                    marginLeft: 16,
                    marginBottom: 16,
                  }}
                  src="/images/verticle_line.svg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="d-flex">
                  <img
                    style={{
                      width: 36,
                      height: 36,
                    }}
                    src="/images/import_candidate.svg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="px-2">
                    <h5 className="card-title font-weight-bold">
                      Import Candidates
                    </h5>
                    <p>Use Saissy’s .csv file template</p>
                  </div>
                </div>
              </div>

              <h5 className="card-title font-weight-bold mt-4 pt-4">
                Prepare CSV File
              </h5>
              <ul>
                <li>Upload multiple candidates in few simple steps</li>
                <li>Upload multiple candidates in few simple steps</li>
              </ul>

              <button
                type="button"
                className="btn btn-secondary mt-2"
                onClick={handleTemplateDownload}
              >
                <img
                  style={{
                    width: 24,
                    height: 24,
                  }}
                  src="/images/download.svg"
                  className="card-img-top"
                  alt="..."
                />{' '}
                Download .csv Template
              </button>
            </Col>
            <Col xs={12} lg={6} className="rounded-0 border-left text-center">
              {loading ? (
                <Spinner
                  style={{
                    marginTop: 48,
                    display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  animation="grow"
                />
              ) : (
                <FilePickerView
                  id="candidateSelector"
                  allowMultiple={false}
                  enableDragAndDrop={true}
                  allowedMimeTypes={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  ]}
                  onUpload={parseFileData}
                />
              )}

              <p className="pt-4">-or-</p>

              <button
                type="button"
                className="btn btn-secondary mt-2"
                onClick={openAddCandidate}
              >
                Add Single Candidate
              </button>
            </Col>
          </Row>
        </section>
      ) : (
        <Container>
          <h3>Imported candidate</h3>
          <Row
            className="rounded p-2"
            style={{
              backgroundColor: theme.greyColor,
            }}
          >
            <Col xs={3}>Name</Col>
            <Col xs={3}>Phone</Col>
            <Col xs={3}>Email</Col>
            <Col xs={1}>Gender</Col>
            <Col xs={1}>Password</Col>
            <Col xs={1}>Actions</Col>
          </Row>
          {candidateReqList?.map((candidateReq: CandidateRequest) => {
            return (
              <Row className="rounded border p-2">
                <Col xs={3}>{candidateReq.candidateInfo.name}</Col>
                <Col xs={3}>+91 {candidateReq.candidateInfo.phone}</Col>
                <Col xs={3}>{candidateReq.candidateInfo.email}</Col>
                <Col xs={1}>{candidateReq.candidateInfo.gender}</Col>
                <Col xs={1}>{candidateReq.candidateInfo.password}</Col>
                <Col xs={1}>
                  <Button
                    variant="link"
                    className="text-primary text-decoration-underline p-0 font-weight-600"
                    onClick={() => {}}
                  >
                    <img
                      src="/images/delete.svg"
                      alt=""
                      width={20}
                      height={20}
                    />
                  </Button>
                </Col>
              </Row>
            );
          })}
          <button
            type="button"
            className="btn btn-secondary mt-2"
            onClick={saveAllCandidate}
          >
            Save All
          </button>
        </Container>
      )}
    </>
  );
};

export default AddBulkCandidatePage;
