import { TopicRequest, TopicSearchReq } from '../request/topic.request';
import { TopicRes } from '../response/topic.response';
import httpClient from '../util/http-client';

export const TopicService = {
  getTopicById: async (id: string): Promise<(TopicRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/topic/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getTopicList: async (
    TopicSearchReq: TopicSearchReq,
  ): Promise<(TopicRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/topic-search', TopicSearchReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveTopic: async (
    topicRequest: TopicRequest,
  ): Promise<(TopicRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/topic`, topicRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateTopic: async (
    id: string,
    topicRequest: TopicRequest,
  ): Promise<(TopicRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(`/topic/${id}`, topicRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
