import { QuestionType } from '../../../../enum/question-type.enum';
import { QuestionOption } from '../../../../response/question.response';
import IntigerAnsEditComponent from './type/intiger-ans-edit.component';
import MultipleChoiceEditComponent from './type/multiple-choice-edit.component';
import SingleChoiceEditComponent from './type/single-choice-edit.component';

export interface QuestionHolderEditComponentProp {
  type: QuestionType;
  updateOptionList?: (optionList: QuestionOption[]) => void;
  updateAnswerList?: (answerList: string[]) => void;
  optionList?: QuestionOption[];
  answerList?: string[];
}

const QuestionHolderEditComponent: React.FC<
  QuestionHolderEditComponentProp
> = ({ type, updateOptionList, updateAnswerList, optionList, answerList }) => {
  const getAnswerView = (type: QuestionType): React.ReactNode => {
    const components: Record<QuestionType, React.ReactNode> = {
      [QuestionType.SINGLE_CHOICE]: (
        <SingleChoiceEditComponent
          updateOptionList={updateOptionList!}
          optionList={optionList}
        />
      ),
      [QuestionType.MULTIPLE_CHOICE]: (
        <MultipleChoiceEditComponent
          updateOptionList={updateOptionList!}
          optionList={optionList}
        />
      ),
      [QuestionType.INT_ANS]: (
        <IntigerAnsEditComponent
          updateAnswerList={updateAnswerList!}
          answerList={answerList}
        />
      ),
    };

    return components[type] || <p>Please Select Question Type</p>;
  };

  const questionDataView = getAnswerView(type);

  return <>{questionDataView}</>;
};

export default QuestionHolderEditComponent;
