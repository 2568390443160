import './auth.scss';
import LeftComponent from './component/left.component';
import { useEffect, useState } from 'react';
import LogInComponent from './component/log-in.component';
import ForgotPassComponent from './component/forgot-pass.component';
import ReSetPassComponent from './component/reset-pass.component';
import { SecurityManager } from '../../security/security-manager';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useSearchParams } from 'react-router-dom';

export enum AuthPageState {
  NORMAL = 'NORMAL',
  SEND_FORGOT_LINK = 'SEND_FORGOT_LINK',
  RESET_PASS = 'RESET_PASS',
}

export interface AuthPageStateProp {
  updatePageState: (newAuthPageState: AuthPageState) => void;
}

const AuthPage = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get('forgot_pass_token');

  const [authPageState, setAuthPageState] = useState(
    token && token.length > 10
      ? AuthPageState.RESET_PASS
      : AuthPageState.NORMAL,
  );

  const { openHome } = useSSNavigate();
  const loggedIn = SecurityManager.loggedIn();

  useEffect(() => {
    if (loggedIn) {
      openHome();
    }
  }, []);

  const updatePageState = (newAuthPageState: AuthPageState) => {
    setAuthPageState(newAuthPageState);
  };

  return (
    <div className="bg-skyblue h-vh-100 d-flex flex-md-row flex-column align-items-center justify-content-center px-5 position-relative circle--wrapper">
      <LeftComponent />

      {authPageState == AuthPageState.NORMAL && (
        <LogInComponent updatePageState={updatePageState} />
      )}
      {authPageState == AuthPageState.SEND_FORGOT_LINK && (
        <ForgotPassComponent updatePageState={updatePageState} />
      )}
      {authPageState == AuthPageState.RESET_PASS && (
        <ReSetPassComponent updatePageState={updatePageState} />
      )}
    </div>
  );
};

export default AuthPage;
