// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

export interface CKTextViewProp {
  initData?: string;
}

export const CKTextView = (props: CKTextViewProp) => {
  const { initData } = props;

  return (
    <div>
      <CKEditor
        editor={ClassicEditor as any}
        disabled={true}
        config={{
          toolbar: [],
        }}
        data={initData}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          // console.log('Editor is ready to use!', editor);
        }}
      />
    </div>
  );
};
