import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomUploadAdapterPlugin from './upload-adapter';

interface OnChangeHandler {
  (data: string): void;
}
export interface CKInputViewProp {
  initData?: string;
  placeholder?: string;
  onChange: OnChangeHandler;
}

export const CKInputView = (props: CKInputViewProp) => {
  const { placeholder, initData, onChange } = props;

  return (
    <div>
      <CKEditor
        editor={ClassicEditor as any}
        config={{
          placeholder: placeholder,
          toolbar: {
            items: [
              'heading',
              'bold',
              'italic',
              '|',
              'imageUpload',
              'MathType',
              'ChemType',
              '|',
              'bulletedList',
              'numberedList',
              '|',
              'link',
              'mediaEmbed',
              'insertTable',
              'blockQuote',
              'undo',
              'redo',
            ],
          },
          extraPlugins: [CustomUploadAdapterPlugin],
        }}
        data={initData}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          // console.log('Editor is ready to use!', editor);
        }}
        onChange={(event, editor: any) => {
          const data = editor.getData();
          onChange(data);
        }}
        onBlur={(event, editor) => {
          // console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          // console.log('Focus.', editor);
        }}
      />
    </div>
  );
};
