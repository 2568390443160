import {
  CandidateRequest,
  CandidateSearchReq,
} from '../request/candidate.request';
import { CandidateRes } from '../response/candidate.response';
import httpClient from '../util/http-client';

export const CandidateService = {
  getCandidateById: async (id: string): Promise<(CandidateRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/candidate/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getCandidateList: async (
    CandidateSearchReq: CandidateSearchReq,
  ): Promise<(CandidateRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(
        '/candidate-search',
        CandidateSearchReq,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveCandidate: async (
    candidateRequest: CandidateRequest,
  ): Promise<(CandidateRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/candidate`, candidateRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateCandidate: async (
    id: string,
    candidateRequest: CandidateRequest,
  ): Promise<(CandidateRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(`/candidate/${id}`, candidateRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
