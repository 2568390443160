import { ProgramRequest, ProgramSearchReq } from '../request/program.request';
import { ProgramRes } from '../response/program.response';
import httpClient from '../util/http-client';

export const ProgramService = {
  getProgramById: async (id: string): Promise<(ProgramRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/program/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getProgramList: async (
    ProgramSearchReq: ProgramSearchReq,
  ): Promise<(ProgramRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/program-search', ProgramSearchReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveProgram: async (
    programRequest: ProgramRequest,
  ): Promise<(ProgramRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/program`, programRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateProgram: async (
    id: string,
    programRequest: ProgramRequest,
  ): Promise<(ProgramRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(`/program/${id}`, programRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
