import React, { useEffect, useState } from 'react';
import { Dropdown, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../../i18n/i18n.key';
import { Marking } from '../../../../../model/marking.model';

export interface ScoreComponentProps {
  marking: Marking;
  maxTime: number;
  updateQuestionMark: (marking: Marking, durmaxTimeation: number) => void;
}

const ScoreComponent: React.FC<ScoreComponentProps> = ({
  marking,
  maxTime,
  updateQuestionMark,
}) => {
  const { t } = useTranslation();

  const [newMarking, setMarking] = useState(marking);

  const [newMaxTime, setMaxTime] = useState(maxTime);

  const [showDropdown, setShowDropdown] = useState(false);

  const setDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    setMarking(marking);
  }, [marking]);

  const updateMarks = () => {
    updateQuestionMark(newMarking, newMaxTime);
    setShowDropdown(false);
  };

  return (
    <React.Fragment>
      <div className={`bg-white py-1 rounded d-flex align-items-center px-2`}>
        <div className="d-flex">
          <div className="d-flex align-items-center me-2 ft-12">
            {maxTime > 0 && (
              <>
                <img src="/images/timed.svg" height={18} alt="" />
                <span className="mx-2 d-flex align-items-baseline">
                  <span className="ft-20 font-weight-600 me-1">{maxTime}</span>{' '}
                  sec
                </span>
              </>
            )}
            {maxTime <= 0 && <span className="mx-2">Untimed</span>}
          </div>

          <div className="d-flex align-items-baseline me-3 ft-12">
            <div className="d-flex align-items-baseline me-3 ft-12">
              <span className="bg-white ft-20 rounded-circle d-flex align-items-center justify-content-center white-circle text-dark-green">
                +{marking?.positive}
              </span>
              {t(I18nKey.POSITIVE_TEXT)}
            </div>
            <span className="bg-white ft-20 rounded-circle d-flex align-items-center justify-content-center white-circle text-red">
              {marking?.negative}
            </span>
            {t(I18nKey.NEGATIVE_TEXT)}
          </div>
          <div className="d-flex align-items-baseline ft-12">
            <span className="bg-white ft-20 rounded-circle d-flex align-items-center justify-content-center white-circle text-blue">
              +{marking?.partial}
            </span>
            {t(I18nKey.PARTIAL_TEXT)}
          </div>
        </div>

        <Dropdown
          className="edit--scores-drop ms-2"
          show={showDropdown}
          onToggle={setDropdown}
        >
          <Dropdown.Toggle
            variant="aliceblue"
            id="edit-drop-button"
            className="edit--scores-styles ms-2"
            onClick={setDropdown}
          >
            <img src="/images/edit-pen.svg" height={20} alt="" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="shadow border-0 mt-1 pt-4 py-2 px-3">
            <div className={`d-flex align-items-center ft-14 flex-column `}>
              <div
                className={`position-relative heading--top-section mb-4 me-0`}
              >
                <span className="position-absolute bg-white px-2 heading-name ft-12 font-weight-700 text-nero mx-2">
                  {t(I18nKey.POSITIVE_TEXT)}
                </span>
                <Form.Control
                  type="number"
                  id="positiveNumbers"
                  className="d-flex shadow-none rounded-2"
                  value={newMarking?.positive}
                  min={0}
                  onChange={(e: any) => {
                    setMarking((preState) => {
                      return {
                        ...preState,
                        positive: Number(e.target.value),
                      };
                    });
                  }}
                />
              </div>

              <div
                className={`position-relative heading--top-section mb-4 me-0`}
              >
                <span className="position-absolute bg-white px-2 heading-name ft-12 font-weight-700 text-nero mx-2">
                  {t(I18nKey.NEGATIVE_TEXT)}
                </span>
                <Form.Control
                  type="number"
                  id="negativeNumbers"
                  className="d-flex shadow-none rounded-2"
                  value={-1 * newMarking?.negative}
                  onChange={(e: any) => {
                    setMarking((preState) => {
                      return {
                        ...preState,
                        negative: -1 * e.target.value,
                      };
                    });
                  }}
                />
              </div>

              <div
                className={`position-relative heading--top-section mb-4 me-0`}
              >
                <span className="position-absolute bg-white px-2 heading-name ft-12 font-weight-700 text-nero mx-2">
                  {t(I18nKey.PARTIAL_TEXT)}
                </span>
                <Form.Control
                  type="number"
                  id="neutralNumbers"
                  className="d-flex shadow-none rounded-2"
                  value={newMarking?.partial}
                  onChange={(e: any) => {
                    setMarking((preState) => {
                      return {
                        ...preState,
                        partial: Number(e.target.value),
                      };
                    });
                  }}
                />
              </div>

              <div className={`position-relative heading--top-section mb-3`}>
                <span className="position-absolute bg-white px-2 heading-name ft-12 font-weight-600 text-nero mx-2">
                  {t(I18nKey.DURATION_TEXT)}
                </span>
                <Form.Control
                  type="text"
                  id="durationNumbers"
                  className="d-flex shadow-none pe-5"
                  value={newMaxTime}
                  onChange={(e: any) => {
                    setMaxTime(Number(e.target.value));
                  }}
                />
                <span className="text-nero ft-12 position-absolute sec--styles ms-2">
                  {t(I18nKey.SEC_TEXT)}
                </span>
              </div>
            </div>

            <Button
              variant="aliceblue"
              className="w-100 text-center mb-2 ft-14 font-weight-600 rounded-2"
              onClick={() => {
                updateMarks();
              }}
            >
              {t(I18nKey.SAVE_CHANGES_TEXT)}
            </Button>
            <Button
              variant="white"
              className="w-100 text-center ft-14"
              onClick={() => {
                setShowDropdown(false);
              }}
            >
              Cancel
            </Button>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </React.Fragment>
  );
};

export default ScoreComponent;
