import { useEffect, useState } from 'react';
import { Table, Button, Col, Container, Row } from 'react-bootstrap';
import { CandidateRes } from '../../response/candidate.response';
import { CandidateService } from '../../service/candidate.service';
import { CandidateSearchReq } from '../../request/candidate.request';
import { MiniBatch } from '../../model/mini-batch.model';
import { FloatingButtonView } from '../../view/floating-button.view';
import { I18nKey } from '../../i18n/i18n.key';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../themes/theme.provider';
import { useSnackbar } from 'notistack';

const CandidatePage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [candidateList, setCandidateList] = useState<CandidateRes[]>([]);
  const { openAddBulkCandidate, openUpdateCandidate } = useSSNavigate();

  const fetchCandidate = async () => {
    const [candidateList, err] = await CandidateService.getCandidateList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as CandidateSearchReq);

    setCandidateList(candidateList);
  };

  useEffect(() => {
    fetchCandidate();
  }, []);

  const openCandidatePortal = (candidate: CandidateRes) => {
    const win: any = window.open(
      `https://demo.saissy.ai/auth?id=${candidate.candidateInfo.phone}&password=${candidate.candidateInfo.password}`,
      '_blank',
    );
    win.focus();
  };

  const copyLink = (candidate: CandidateRes) => {
    const link = `https://demo.saissy.ai/auth?id=${candidate.candidateInfo.phone}&password=${candidate.candidateInfo.password}`;

    navigator.clipboard.writeText(link);

    enqueueSnackbar(t(I18nKey.LINK_COPIED), {
      variant: 'success',
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
    });
  };

  return (
    <section>
      <Container>
        <Row
          className="rounded p-2"
          style={{
            backgroundColor: theme.greyColor,
          }}
        >
          <Col xs={2}>Name</Col>
          <Col xs={2}>Phone</Col>
          <Col xs={3}>Email</Col>
          <Col xs={1}>Gender</Col>
          <Col xs={2}>Batch</Col>
          <Col xs={1}>Password</Col>
          <Col xs={1}>Actions</Col>
        </Row>
        {candidateList?.map((candidate: CandidateRes) => {
          return (
            <Row className="rounded border p-2">
              <Col xs={2}>
                <span>{candidate.candidateInfo.name}</span>
                <Button
                  variant="link"
                  className="text-primary text-decoration-underline p-0 font-weight-600"
                  onClick={() => {
                    openCandidatePortal(candidate);
                  }}
                  style={{ marginLeft: 2 }}
                >
                  <img
                    src="/images/visibility_green.svg"
                    alt=""
                    width={20}
                    height={20}
                  />
                </Button>
              </Col>
              <Col xs={2}>+91 {candidate.candidateInfo.phone}</Col>
              <Col xs={3}>{candidate.candidateInfo.email}</Col>
              <Col xs={1}>{candidate.candidateInfo.gender}</Col>
              <Col xs={2}>
                {candidate.batchList
                  .map((batch: MiniBatch) => {
                    return batch.name.en;
                  })
                  .toString()}
              </Col>
              <Col xs={1}>{candidate.candidateInfo.password}</Col>
              <Col xs={1}>
                <Button
                  variant="link"
                  className="text-primary text-decoration-underline pl-2 font-weight-600"
                  onClick={() => {
                    copyLink(candidate);
                  }}
                >
                  <img
                    src="/images/copy_button.svg"
                    alt=""
                    width={20}
                    height={20}
                  />
                </Button>
                <Button
                  variant="link"
                  className="text-primary text-decoration-underline p-0 font-weight-600"
                  onClick={() => {
                    openUpdateCandidate(candidate.id);
                  }}
                >
                  <img
                    src="/images/edit_button.svg"
                    alt=""
                    width={20}
                    height={20}
                  />
                </Button>
              </Col>
            </Row>
          );
        })}
      </Container>
      <FloatingButtonView
        innerElement={
          <>
            <img src="/images/add.svg" alt="" />
            <span className="ms-2">{t(I18nKey.ADD_CANDIDATE)}</span>
          </>
        }
        onClick={() => {
          openAddBulkCandidate();
        }}
      />
    </section>
  );
};

export default CandidatePage;
