import {
  QuestionRequest,
  QuestionSearchReq,
} from '../request/question.request';
import { QuestionRes } from '../response/question.response';
import httpClient from '../util/http-client';

export const QuestionService = {
  getQuestionById: async (id: string): Promise<(QuestionRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/question/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getQuestionList: async (
    questionSearchReq: QuestionSearchReq,
  ): Promise<(QuestionRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/question-search', questionSearchReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveQuestion: async (
    questionRequest: QuestionRequest,
  ): Promise<(QuestionRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/question`, questionRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateQuestion: async (
    id: string,
    questionRequest: QuestionRequest,
  ): Promise<(QuestionRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(`/question/${id}`, questionRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
