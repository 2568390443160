import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../i18n/i18n.key';

interface ConfirmationViewProps {
  isModalShow: boolean;
  onSuccess: Function;
  onCancel: Function;
  message: string;
  primaryBtnName: string;
}

const ConfirmationView: React.FC<ConfirmationViewProps> = ({
  isModalShow,
  onSuccess,
  onCancel,
  message,
  primaryBtnName,
}) => {
  const { t } = useTranslation();

  return (
    <Modal centered show={isModalShow} size="sm" className="discard--modal">
      <Modal.Body>
        <span className="ft-16 text-nero">{message}</span>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pb-3 pt-0">
        <Button
          variant="darkAliceBlue"
          className="px-4 me-2 font-weight-600 ms-0"
          onClick={() => onSuccess()}
        >
          {primaryBtnName}
        </Button>
        <Button
          onClick={() => {
            onCancel();
          }}
          variant="white"
          className="px-3 font-weight-600 border-0"
        >
          {t(I18nKey.DISCARD_TEXT)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationView;
