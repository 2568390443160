import { useEffect, useState } from 'react';
import { Button, Spinner, Form } from 'react-bootstrap';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../i18n/i18n.key';
import { useTranslation } from 'react-i18next';
import { CenterRequest } from '../../request/center.request';
import { CenterService } from '../../service/center.service';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useParams } from 'react-router';
import { ItemProp } from '../../model/item-prop.model';

const UpdateCenterPage = () => {
  const { t } = useTranslation();
  const { openCenter } = useSSNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    status: '',
  });
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');

  const updateCenter = async (centerReq: CenterRequest) => {
    const [data, error] = await CenterService.updateCenter(
      id as string,
      centerReq,
    );

    if (error) {
      setLoading(false);
    } else {
      openCenter();
    }
  };

  const getCenter = async () => {
    const [center, error] = await CenterService.getCenterById(id as string);

    setLoading(false);
    if (error) {
      openCenter();
    } else {
      setName(center.name.en);
      setStatus(center.status);
    }
  };

  useEffect(() => {
    getCenter();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (!validator.isLength(name, { min: 2, max: 30 })) {
      _errors.name = 'Please enter valid Name';
    }

    if (!validator.isLength(status, { min: 4, max: 6 })) {
      _errors.status = 'Please select status';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      const centerReq = {
        name: {
          en: name,
        },
        status: status,
      } as CenterRequest;
      updateCenter(centerReq);
    }
  };

  const formStyle = {
    width: '96%',
    maxWidth: '769px',
    margin: 'auto',
  };

  return (
    <section className="my-3">
      <div
        className="rounded-16 bg-white p-4 ft-14 ms-lg-5 mt-lg-0 mt-5 custom-shadow text-nero z-1"
        style={formStyle}
      >
        <h5 className="mb-4 text-nero">{t(I18nKey.UPDATE_CENTER)}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>{t(I18nKey.CENTER_NAME_TEXT)}</Form.Label>

              <Form.Control
                required
                type="text"
                value={name}
                isInvalid={!!errors.name}
                onChange={(e) => setName(e.target.value)}
                className={`ft-14 px-1 shadow-none border ${
                  errors.name ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_CENTER_NAME_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid name
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="status" className="mb-3">
              <Form.Label>{t(I18nKey.SELECT_STATUS)}</Form.Label>

              <Form.Select
                required
                value={status}
                isInvalid={!!errors.status}
                onChange={(e) => setStatus(e.target.value)}
                className={`ft-14 px-1 shadow-none border ${
                  errors.name ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.SELECT_STATUS)}
              >
                {[
                  {
                    value: 'closed',
                    label: 'Closed',
                  } as ItemProp,
                  {
                    value: 'live',
                    label: 'Live',
                  } as ItemProp,
                  {
                    value: 'hold',
                    label: 'Hold',
                  } as ItemProp,
                ].map((itemProp: ItemProp) => {
                  return (
                    <option value={itemProp.value}>{itemProp.label}</option>
                  );
                })}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="text-center mt-4">
              <Button
                variant="primary"
                type="submit"
                className="text-white ft-14 font-weight-600"
              >
                {t(I18nKey.SUBMIT)}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </section>
  );
};

export default UpdateCenterPage;
