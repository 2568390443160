import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../i18n/i18n.key';
import InstructionsComponent from './instruction.component';
import MultiSelectDropdown from '../../../../view/multi-select-drop-down.view';
import { BatchSearchReq } from '../../../../request/batch.request';
import { CenterSearchReq } from '../../../../request/center.request';
import { ExamSearchReq } from '../../../../request/exam.request';
import { ProgramSearchReq } from '../../../../request/program.request';
import { SubjectSearchReq } from '../../../../request/subject.request';
import { BatchRes } from '../../../../response/batch.response';
import { CenterRes } from '../../../../response/center.response';
import { ExamRes } from '../../../../response/exam.response';
import { ProgramRes } from '../../../../response/program.response';
import { SubjectRes } from '../../../../response/subject.response';
import { BatchService } from '../../../../service/batch.service';
import { CenterService } from '../../../../service/center.service';
import { ExamService } from '../../../../service/exam.service';
import { ProgramService } from '../../../../service/program.service';
import { SubjectService } from '../../../../service/subject.service';
import { ItemProp } from '../../../../model/item-prop.model';
import { TestRequest } from '../../../../request/test.request';
import { CreateTestStepsEnum } from '../../../../enum/test-type.enum';
import dayjs from 'dayjs';

export interface TestSetupComponentProps {
  testReq: TestRequest;
  updateTestReq: (testReq: any) => void;
  moveToStep: (step: CreateTestStepsEnum) => void;
  saveDraft: () => void;
}

const TestSetupComponent: React.FC<TestSetupComponentProps> = ({
  testReq,
  updateTestReq,
  moveToStep,
  saveDraft,
}) => {
  const { t } = useTranslation();

  const [batchList, setBatchList] = useState<BatchRes[]>([]);
  const [programList, setProgramList] = useState<ProgramRes[]>([]);
  const [centerList, setCenterList] = useState<CenterRes[]>([]);
  const [subjectList, setSubjectList] = useState<SubjectRes[]>([]);
  const [examList, setExamList] = useState<ExamRes[]>([]);

  const [duration, setDuration] = useState(0);

  const fetchData = async () => {
    const [batchList, err] = await BatchService.getBatchList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as BatchSearchReq);

    const [programList, err1] = await ProgramService.getProgramList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as ProgramSearchReq);

    const [centerList, err2] = await CenterService.getCenterList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as CenterSearchReq);

    const [subjectList, err3] = await SubjectService.getSubjectList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as SubjectSearchReq);

    const [examList, err4] = await ExamService.getExamList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as ExamSearchReq);

    setBatchList(batchList);
    setProgramList(programList);
    setCenterList(centerList);
    setSubjectList(subjectList);
    setExamList(examList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    updateTestReq({
      duration: 60 * duration,
    });
  }, [duration]);

  return (
    <div className="py-3">
      <div className="d-flex align-items-center w-100">
        <div className="ms-auto d-flex align-items-center mb-2">
          <Button
            variant="pale-lavender"
            className="mx-2 ft-14 font-weight-600"
            onClick={() => {
              moveToStep(CreateTestStepsEnum.Create);
            }}
          >
            <span className="ms-2">{t(I18nKey.PREVIEW_TEXT)}</span>
          </Button>

          <Button
            variant="pale-lavender"
            className="mx-2 ft-14 font-weight-600"
            onClick={() => {
              moveToStep(CreateTestStepsEnum.Summary);
            }}
          >
            <span className="ms-2">{t(I18nKey.NEXT_TEXT)}</span>
          </Button>

          <Button
            variant="pale-lavender"
            className="mx-2 ft-14 font-weight-600"
            onClick={saveDraft}
          >
            <span className="ms-2">{t(I18nKey.SAVE_DRAFT_TEXT)}</span>
          </Button>
        </div>
      </div>
      <div className={`position-relative heading--top-section mb-4 `}>
        <span className="position-absolute bg-white px-2 heading-name ft-14 font-weight-600 text-nero mx-2">
          {t(I18nKey.TITLE_TEXT)}
        </span>
        <Form.Control
          type="text"
          id="title"
          className="d-flex shadow-none"
          defaultValue={testReq.name?.en}
          onChange={(e: any) => {
            updateTestReq({
              name: {
                en: e.target.value,
              },
            });
          }}
        />
      </div>

      <div className={`d-flex align-items-left flex-column my-4 gap-3`}>
        <MultiSelectDropdown
          title={t(I18nKey.SELECT_EXAM_TEXT)}
          badgeBackgroud={'light-700'}
          badgeClassName={'rounded'}
          id={'test_exam'}
          placeholder={''}
          defaultSelected={testReq.examIdList}
          dataList={examList.map((exam: ExamRes) => {
            return {
              label: exam.name.en,
              value: exam.id,
            } as ItemProp;
          })}
          onChange={(data: any[]) => updateTestReq({ examIdList: data })}
          isInvalid={false}
          validationMessage={''}
          showBadge={false}
        />

        <MultiSelectDropdown
          title={t(I18nKey.SELECT_SUBJECT)}
          badgeBackgroud={'light-700'}
          badgeClassName={'rounded'}
          id={'test_subject'}
          placeholder={''}
          defaultSelected={testReq.subjectIdList}
          dataList={subjectList.map((subject: SubjectRes) => {
            return {
              label: subject.name.en,
              value: subject.id,
            } as ItemProp;
          })}
          onChange={(data: any[]) => updateTestReq({ subjectIdList: data })}
          isInvalid={false}
          validationMessage={''}
          showBadge={false}
        />

        <MultiSelectDropdown
          title={t(I18nKey.SELECT_CENTER)}
          badgeBackgroud={'light-700'}
          badgeClassName={'rounded'}
          id={'test_center'}
          placeholder={''}
          defaultSelected={testReq.centerIdList}
          dataList={centerList.map((center: CenterRes) => {
            return {
              label: center.name.en,
              value: center.id,
            } as ItemProp;
          })}
          onChange={(data: any[]) => updateTestReq({ centerIdList: data })}
          isInvalid={false}
          validationMessage={''}
          showBadge={false}
        />

        <MultiSelectDropdown
          title={t(I18nKey.SELECT_PROGRAM)}
          badgeBackgroud={'light-700'}
          badgeClassName={'rounded'}
          id={'test_program'}
          placeholder={''}
          defaultSelected={testReq.programIdList}
          dataList={programList.map((program: ProgramRes) => {
            return {
              label: program.name.en,
              value: program.id,
            } as ItemProp;
          })}
          onChange={(data: any[]) => updateTestReq({ programIdList: data })}
          isInvalid={false}
          validationMessage={''}
          showBadge={false}
        />

        <MultiSelectDropdown
          title={t(I18nKey.SELECT_BATCH)}
          badgeBackgroud={'light-700'}
          badgeClassName={'rounded'}
          id={'test_batch'}
          placeholder={''}
          defaultSelected={testReq.batchIdList}
          dataList={batchList.map((batch: BatchRes) => {
            return {
              label: batch.name.en,
              value: batch.id,
            } as ItemProp;
          })}
          onChange={(data: any[]) => updateTestReq({ batchIdList: data })}
          isInvalid={false}
          validationMessage={''}
          showBadge={false}
        />
      </div>

      <div className={`position-relative d-flex align-items-center mb-4 `}>
        <span className="bg-white ft-14 font-weight-600 text-nero me-3">
          {t(I18nKey.START_DATE_TIME_TEXT)}
        </span>
        <Form.Control
          type="date"
          id="starDate"
          className="d-flex shadow-none w-180 me-3"
          defaultValue={dayjs(testReq.startDate).format('YYYY-MM-DD')}
          onChange={(e: any) => {
            updateTestReq({
              startDate: new Date(e.target.value),
            });
          }}
        />
        <Form.Control
          type="time"
          id="starTime"
          className="d-flex shadow-none w-180 me-3"
          defaultValue={dayjs(testReq.startDate).format('HH:MM')}
          onChange={(e: any) => {
            const hour = e.target.value.split(':')[0];
            const min = e.target.value.split(':')[1];
            const newDate = new Date(testReq.startDate);
            newDate.setHours(hour);
            newDate.setMinutes(min);
            updateTestReq({
              startDate: newDate,
            });
          }}
        />
      </div>

      <div className={`position-relative d-flex align-items-center mb-4 `}>
        <span className="bg-white ft-14 font-weight-600 text-nero me-3">
          {t(I18nKey.END_DATE_TIME_TEXT)}
        </span>
        <Form.Control
          type="date"
          id="endDate"
          defaultValue={dayjs(testReq.endDate).format('YYYY-MM-DD')}
          className="d-flex shadow-none w-180 me-3"
          onChange={(e: any) => {
            updateTestReq({
              endDate: new Date(e.target.value),
            });
          }}
        />
        <Form.Control
          type="time"
          id="endTime"
          defaultValue={dayjs(testReq.endDate).format('HH:MM')}
          className="d-flex shadow-none w-180 me-3"
          onChange={(e: any) => {
            const hour = e.target.value.split(':')[0];
            const min = e.target.value.split(':')[1];
            const newDate = new Date(testReq.endDate);
            newDate.setHours(hour);
            newDate.setMinutes(min);
            updateTestReq({
              endDate: newDate,
            });
          }}
        />
      </div>

      <div
        className={`position-relative heading--top-section mb-4 d-flex align-items-center `}
      >
        <span className="position-absolute bg-white px-2 heading-name ft-14 font-weight-600 text-nero mx-2">
          {t(I18nKey.DURATION_TEXT)}
        </span>
        <Form.Control
          type="text"
          id="duration"
          defaultValue={duration}
          className="d-flex shadow-none w-180"
          onChange={(e: any) => {
            setDuration(e.target.value);
          }}
        />
        <span className="mx-2 ft-12 text-nero">{t(I18nKey.MINUTES_TEXT)}</span>
      </div>

      {/* <MultiSelectDropdown
        title={t(I18nKey.LANGUAGE_TEXT)}
        badgeBackgroud={'light-700'}
        badgeClassName={'rounded'}
        id={''}
        placeholder={''}
        dataList={[]}
        onChange={function (values: (string | number)[]): void {
          throw new Error('Function not implemented.');
        }}
        isInvalid={false}
        validationMessage={''}
        showBadge={false}
      /> */}

      <InstructionsComponent
        defaultData={testReq.instruction?.en}
        updateTestReq={updateTestReq}
      />
    </div>
  );
};

export default TestSetupComponent;
