import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../i18n/i18n.key';
import AllocationComponent from './allocation.component';
import OverallSummaryComponent from './overall-summary.component';
import SectionDetailsComponent from './section-details.component';
import { TestRequest } from '../../../../request/test.request';
import { CreateTestStepsEnum } from '../../../../enum/test-type.enum';

export interface SummaryComponentProps {
  testReq: TestRequest;
  saveTest: () => void;
  moveToStep: (step: CreateTestStepsEnum) => void;
  saveDraft: () => void;
  readonly?: boolean;
}

const SummaryComponent: React.FC<SummaryComponentProps> = ({
  testReq,
  saveTest,
  moveToStep,
  saveDraft,
  readonly,
}) => {
  const { t } = useTranslation();

  return (
    <div className="text-nero d-flex align-items-center flex-column my-2 px-3">
      <div className="d-flex align-items-center w-100">
        <h6 className="font-weight-700 text-nero ft-20 mx-2 my-0">
          {testReq.name?.en}
        </h6>
        {!readonly && (
          <div className="ms-auto d-flex align-items-center">
            <Button
              variant="light-700"
              className="mx-2 ft-14 font-weight-600"
              onClick={() => {
                moveToStep(CreateTestStepsEnum.TestSetup);
              }}
            >
              <span className="ms-2">{t(I18nKey.PREVIOUS_TEXT)}</span>
            </Button>
            <Button
              variant="pale-lavender"
              className="mx-2 ft-14 font-weight-600"
              onClick={saveDraft}
            >
              <span className="ms-2">{t(I18nKey.SAVE_DRAFT_TEXT)}</span>
            </Button>
            <Button
              variant="pale-lavender"
              className="mx-2 ft-14 font-weight-600"
              onClick={saveTest}
            >
              <img src="/images/publish.svg" alt="" />
              <span className="ms-2">{t(I18nKey.PUBLISH_TEXT)}</span>
            </Button>
          </div>
        )}
      </div>

      <div className="d-flex w-100 align-items-center my-3 flex-column">
        <OverallSummaryComponent testReq={testReq} />
        <AllocationComponent testReq={testReq} />
        <SectionDetailsComponent testReq={testReq} />
      </div>
    </div>
  );
};

export default SummaryComponent;
