import './App.css';
import { RouterProvider } from 'react-router';
import { mainRoute } from './util/main.route';
import { useI18n } from './i18n/i18n.hook';
import { useFont } from './font/font.hook';
import { SnackbarProvider } from 'notistack';

function App() {
  useI18n();
  useFont();

  return (
    //TODO: ADD theme provider
    //TODO: Add font size control
    <SnackbarProvider>
      <RouterProvider router={mainRoute} />
    </SnackbarProvider>
  );
}

export default App;
