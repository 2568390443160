import { ReactElement } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface FloatingButtonViewProps {
  innerElement: ReactElement;
  onClick: (e: any) => void;
}

export const FloatingButtonView: React.FC<FloatingButtonViewProps> = ({
  innerElement,
  onClick,
}) => {
  return (
    <Button
      onClick={(e: any) => {
        onClick(e);
      }}
      style={{
        position: 'fixed',
        right: 30,
        bottom: 50,
        background: '#FFFFFF',
      }}
      variant="white"
      className={`py-2 px-3 shadow d-flex ms-auto align-items-center font-weight-600 ft-14`}
    >
      {innerElement}
    </Button>
  );
};
