import { Row, Col, Spinner } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { QuestionSearchReq } from '../../../../../../../request/question.request';
import { QuestionRes } from '../../../../../../../response/question.response';
import { QuestionService } from '../../../../../../../service/question.service';
import QuestionFilterComponent from './question-filter.component';
import QuestionListComponent from './question-list.component';
import QuestionDetailComponent from './question-detail.component';

interface QuestionComponentProps {
  selectedQuestionList: QuestionRes[];
  setSelectedQuestion: (selectedQuestionList: QuestionRes[]) => void;
}

const QuestionComponent: React.FC<QuestionComponentProps> = ({
  selectedQuestionList,
  setSelectedQuestion,
}) => {
  const [loading, setLoading] = useState(true);
  const [questionList, setQuestionList] = useState<QuestionRes[]>([]);
  const [activeQuestion, setActiveQuestion] = useState<QuestionRes>();
  const searchTimeout = useRef<any>();

  const [questionSearchReq, setQuestionSearchReq] = useState({
    pageNumber: 1,
    itemsPerPage: 200,
  } as QuestionSearchReq);

  const updateSearchReq = (newQuestionSearchReq: QuestionSearchReq) => {
    setQuestionSearchReq((preState) => {
      return {
        ...preState,
        ...newQuestionSearchReq,
      };
    });
  };

  const fetchQuestion = async () => {
    const [questionList, err] =
      await QuestionService.getQuestionList(questionSearchReq);

    setQuestionList(questionList);
    setActiveQuestion(questionList[0]);
    setLoading(false);
  };

  useEffect(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    searchTimeout.current = setTimeout(() => {
      fetchQuestion();
    }, 500);
  }, [questionSearchReq]);

  return (
    <>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <>
          <QuestionFilterComponent
            questionSearchReq={questionSearchReq}
            updateSearchReq={updateSearchReq}
          />

          <Row className="mt-4 mb-2 mx-0">
            <Col sm={3}>
              {activeQuestion && (
                <QuestionListComponent
                  selectedQuestionList={selectedQuestionList}
                  questionList={questionList}
                  setActiveQuestion={setActiveQuestion}
                  activeQuestion={activeQuestion}
                  setSelectedQuestion={setSelectedQuestion}
                />
              )}
            </Col>
            <Col sm={9}>
              {activeQuestion && (
                <QuestionDetailComponent question={activeQuestion} />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default QuestionComponent;
