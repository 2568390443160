import { useEffect, useState } from 'react';
import { Button, Spinner, Form } from 'react-bootstrap';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../i18n/i18n.key';
import { useTranslation } from 'react-i18next';
import { CandidateRequest } from '../../request/candidate.request';
import { CandidateService } from '../../service/candidate.service';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { ItemProp } from '../../model/item-prop.model';
import { CandidateInfo } from '../../response/candidate.response';
import { Address } from '../../model/address.model';
import { Media } from '../../model/media.model';
import { ParentInfo } from '../../model/parent-info.model';
import { SubjectRes } from '../../response/subject.response';
import { SubjectService } from '../../service/subject.service';
import { SubjectSearchReq } from '../../request/subject.request';
import MultiSelectView from '../../view/multi-select.view';
import { BatchService } from '../../service/batch.service';
import { ProgramService } from '../../service/program.service';
import { BatchSearchReq } from '../../request/batch.request';
import { ProgramSearchReq } from '../../request/program.request';
import { CenterService } from '../../service/center.service';
import { CenterSearchReq } from '../../request/center.request';
import { ExamService } from '../../service/exam.service';
import { ExamSearchReq } from '../../request/exam.request';
import { BatchRes } from '../../response/batch.response';
import { ProgramRes } from '../../response/program.response';
import { CenterRes } from '../../response/center.response';
import { ExamRes } from '../../response/exam.response';
import dayjs from 'dayjs';

const AddCandidatePage = () => {
  const { t } = useTranslation();
  const { openCandidate } = useSSNavigate();

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    candidateInfo: {
      name: '',
      phone: '',
      email: '',
      dateOfBirth: '',
      gender: '',
    },
    status: '',
    address: {
      lineOne: '',
      lineTwo: '',
      street: '',
      city: '',
      state: '',
      pinCode: '',
    },
    profilePic: '',
    validTill: '',
    batchIdList: '',
    programIdList: '',
    centerIdList: '',
    subjectIdList: '',
    examIdList: '',
    parentInfo: {
      name: '',
      phone: '',
      email: '',
      relationship: '',
      occupation: '',
    },
  });

  const date = new Date();
  const dateValid = new Date();

  const emptyList: string[] = [];
  const emptyParentList: ParentInfo[] = [];

  const [candidateReq, setCandidateReq] = useState({
    candidateInfo: {
      name: '',
      phone: '',
      email: '',
      dateOfBirth: new Date(date.setFullYear(date.getFullYear() - 5)),
      gender: 'Male',
    } as CandidateInfo,
    status: 'active',
    address: {
      lineOne: '',
      lineTwo: '',
      street: '',
      city: '',
      state: '',
      pinCode: '',
    } as Address,
    profilePic: {} as Media,
    validTill: new Date(dateValid.setMonth(dateValid.getMonth() + 11)),
    batchIdList: emptyList,
    programIdList: emptyList,
    centerIdList: emptyList,
    subjectIdList: emptyList,
    examIdList: emptyList,
    parentList: emptyParentList,
    parentInfo: {
      name: '',
      phone: '',
      email: '',
      relationship: '',
      occupation: '',
    },
  } as CandidateRequest);

  const [batchList, setBatchList] = useState<BatchRes[]>([]);
  const [programList, setProgramList] = useState<ProgramRes[]>([]);
  const [centerList, setCenterList] = useState<CenterRes[]>([]);
  const [subjectList, setSubjectList] = useState<SubjectRes[]>([]);
  const [examList, setExamList] = useState<ExamRes[]>([]);

  const fetchData = async () => {
    const [batchList, err] = await BatchService.getBatchList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as BatchSearchReq);

    const [programList, err1] = await ProgramService.getProgramList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as ProgramSearchReq);

    const [centerList, err2] = await CenterService.getCenterList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as CenterSearchReq);

    const [subjectList, err3] = await SubjectService.getSubjectList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as SubjectSearchReq);

    const [examList, err4] = await ExamService.getExamList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as ExamSearchReq);

    setBatchList(batchList);
    setProgramList(programList);
    setCenterList(centerList);
    setSubjectList(subjectList);
    setExamList(examList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const saveCandidate = async (candidateReq: CandidateRequest) => {
    const [data, error] = await CandidateService.saveCandidate(candidateReq);

    if (error) {
      setLoading(false);
    } else {
      openCandidate();
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let isError = false;

    const _errors: any = {
      candidateInfo: {},
      address: {},
      parentInfo: {},
    };

    // validation
    if (
      !validator.isLength(candidateReq.candidateInfo.name, { min: 2, max: 30 })
    ) {
      isError = true;
      _errors.candidateInfo.name = 'Please enter valid Name';
    }
    if (!validator.isMobilePhone(candidateReq.candidateInfo.phone, 'en-IN')) {
      isError = true;
      _errors.candidateInfo.phone = 'Please enter valid phone';
    }
    if (!validator.isEmail(candidateReq.candidateInfo.email)) {
      isError = true;
      _errors.candidateInfo.email = 'Please enter valid email';
    }
    if (
      new Date(candidateReq.candidateInfo.dateOfBirth).getTime() >
      new Date().getTime()
    ) {
      isError = true;
      _errors.candidateInfo.dateOfBirth = 'Please enter valid dob';
    }
    if (
      !validator.isLength(candidateReq.candidateInfo.gender, {
        min: 2,
        max: 30,
      })
    ) {
      isError = true;
      _errors.candidateInfo.gender = 'Please enter valid gender';
    }

    if (!validator.isLength(candidateReq.status, { min: 2, max: 30 })) {
      isError = true;
      _errors.status = 'Please select status';
    }

    if (
      !validator.isLength(candidateReq.address.lineOne, { min: 0, max: 30 })
    ) {
      isError = true;
      _errors.address.lineOne = 'Please enter address';
    }

    if (!validator.isLength(candidateReq.address.city, { min: 0, max: 30 })) {
      isError = true;
      _errors.address.city = 'Please enter valid city';
    }

    if (!validator.isLength(candidateReq.address.state, { min: 0, max: 30 })) {
      isError = true;
      _errors.address.state = 'Please enter valid state';
    }

    if (!validator.isLength(candidateReq.address.pinCode, { min: 0, max: 6 })) {
      isError = true;
      _errors.address.pinCode = 'Please enter valid pin code';
    }

    if (new Date(candidateReq.validTill).getTime() < new Date().getTime()) {
      isError = true;
      _errors.validTill = 'Please select valid Date';
    }

    if (candidateReq.batchIdList.length <= 0) {
      isError = true;
      _errors.batchIdList = 'Please select batch';
    }

    if (candidateReq.programIdList.length == 0) {
      isError = true;
      _errors.programIdList = 'Please select program';
    }

    if (candidateReq.subjectIdList.length == 0) {
      isError = true;
      _errors.subjectIdList = 'Please select subject';
    }

    if (candidateReq.centerIdList.length == 0) {
      isError = true;
      _errors.centerIdList = 'Please select center';
    }

    if (candidateReq.examIdList.length == 0) {
      isError = true;
      _errors.examIdList = 'Please select exam';
    }

    //parent validation

    if (
      !validator.isLength(candidateReq.parentInfo.name, { min: 0, max: 30 })
    ) {
      isError = true;
      _errors.parentInfo.name = 'Please enter valid Name';
    }
    if (
      candidateReq.parentInfo.phone.length > 0 &&
      !validator.isMobilePhone(candidateReq.parentInfo.phone, 'en-IN')
    ) {
      isError = true;
      _errors.parentInfo.phone = 'Please enter valid phone';
    }
    if (
      candidateReq.parentInfo.email.length > 0 &&
      !validator.isEmail(candidateReq.parentInfo.email)
    ) {
      isError = true;
      _errors.parentInfo.email = 'Please enter valid email';
    }

    if (
      !validator.isLength(candidateReq.parentInfo.relationship, {
        min: 0,
        max: 30,
      })
    ) {
      isError = true;
      _errors.parentInfo.relationship = 'Please enter valid relationship';
    }

    if (
      !validator.isLength(candidateReq.parentInfo.occupation, {
        min: 0,
        max: 30,
      })
    ) {
      isError = true;
      _errors.parentInfo.occupation = 'Please enter valid occupation';
    }

    if (isError) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      candidateReq.parentList[0] = candidateReq.parentInfo;
      candidateReq.candidateInfo.dateOfBirth = new Date(
        candidateReq.candidateInfo.dateOfBirth,
      );
      candidateReq.validTill = new Date(candidateReq.validTill);
      saveCandidate(candidateReq);
    }
  };

  const formStyle = {
    width: '96%',
    maxWidth: '769px',
    margin: 'auto',
  };

  return (
    <section className="my-3">
      <div
        className="rounded-16 bg-white p-4 ft-14 mt-lg-0 mt-5 custom-shadow text-nero z-1"
        style={formStyle}
      >
        <h5 className="mb-4 text-nero">{t(I18nKey.ADD_CANDIDATE)}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>{t(I18nKey.CANDIDATE_NAME_TEXT)}</Form.Label>

              <Form.Control
                required
                type="text"
                value={candidateReq.candidateInfo.name}
                isInvalid={!!errors.candidateInfo.name}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      candidateInfo: {
                        ...preState.candidateInfo,
                        name: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.candidateInfo.name ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_CANDIDATE_NAME_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.candidateInfo.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="phone" className="mb-3">
              <Form.Label>{t(I18nKey.CANDIDATE_PHONE_TEXT)}</Form.Label>

              <Form.Control
                required
                type="text"
                maxLength={10}
                value={candidateReq.candidateInfo.phone}
                isInvalid={!!errors.candidateInfo.phone}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      candidateInfo: {
                        ...preState.candidateInfo,
                        phone: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.candidateInfo.phone ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_CANDIDATE_PHONE_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.candidateInfo.phone}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label>{t(I18nKey.CANDIDATE_EMAIL_TEXT)}</Form.Label>

              <Form.Control
                required
                type="text"
                value={candidateReq.candidateInfo.email}
                isInvalid={!!errors.candidateInfo.email}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      candidateInfo: {
                        ...preState.candidateInfo,
                        email: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.candidateInfo.email ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_CANDIDATE_EMAIL_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.candidateInfo.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="dateOfBirth" className="mb-3">
              <Form.Label>{t(I18nKey.CANDIDATE_DOB_TEXT)}</Form.Label>

              <Form.Control
                required
                type="date"
                value={dayjs(candidateReq.candidateInfo.dateOfBirth).format(
                  'YYYY-MM-DD',
                )}
                isInvalid={!!errors.candidateInfo.dateOfBirth}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      candidateInfo: {
                        ...preState.candidateInfo,
                        dateOfBirth: new Date(e.target.value),
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.candidateInfo.dateOfBirth ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_CANDIDATE_DOB_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.candidateInfo.dateOfBirth}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="gender" className="mb-3">
              <Form.Label>{t(I18nKey.SELECT_GENDER)}</Form.Label>

              <Form.Select
                required
                value={candidateReq.candidateInfo.gender}
                isInvalid={!!errors.candidateInfo.gender}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      candidateInfo: {
                        ...preState.candidateInfo,
                        gender: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.candidateInfo.gender ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.SELECT_GENDER)}
              >
                {[
                  {
                    value: 'male',
                    label: 'Male',
                  } as ItemProp,
                  {
                    value: 'female',
                    label: 'Female',
                  } as ItemProp,
                ].map((itemProp: ItemProp) => {
                  return (
                    <option value={itemProp.value}>{itemProp.label}</option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.candidateInfo.gender}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="status" className="mb-3">
              <Form.Label>{t(I18nKey.STATUS_TEXT)}</Form.Label>

              <Form.Select
                required
                value={candidateReq.status}
                isInvalid={!!errors.status}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      status: e.target.value,
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.status ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.SELECT_STATUS)}
              >
                {[
                  {
                    value: 'blocked',
                    label: 'Blocked',
                  } as ItemProp,
                  {
                    value: 'active',
                    label: 'Active',
                  } as ItemProp,
                  {
                    value: 'inActive',
                    label: 'InActive',
                  } as ItemProp,
                ].map((itemProp: ItemProp) => {
                  return (
                    <option value={itemProp.value}>{itemProp.label}</option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validTill" className="mb-3">
              <Form.Label>Select validTill Date</Form.Label>

              <Form.Control
                required
                type="date"
                value={dayjs(candidateReq.validTill).format('YYYY-MM-DD')}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      validTill: new Date(e.target.value),
                    };
                  })
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.validTill}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="batchIdList" className="mb-3">
              <MultiSelectView
                id="batchIdList"
                dataList={batchList.map((batch: BatchRes) => {
                  return {
                    label: batch.name.en,
                    value: batch.id,
                  } as ItemProp;
                })}
                onChange={(data: any[]) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      batchIdList: data,
                    };
                  })
                }
                placeholder={t(I18nKey.SELECT_BATCH)}
                title={t(I18nKey.BATCH_TEXT)}
                isInvalid={!!errors.batchIdList}
                validationMessage={errors.batchIdList}
              />
            </Form.Group>

            <Form.Group controlId="programIdList" className="mb-3">
              <MultiSelectView
                id="programIdList"
                dataList={programList.map((program: ProgramRes) => {
                  return {
                    label: program.name.en,
                    value: program.id,
                  } as ItemProp;
                })}
                onChange={(data: any[]) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      programIdList: data,
                    };
                  })
                }
                placeholder={t(I18nKey.SELECT_PROGRAM)}
                title={t(I18nKey.PROGRAM_TEXT)}
                isInvalid={!!errors.programIdList}
                validationMessage={errors.programIdList}
              />
            </Form.Group>

            <Form.Group controlId="centerIdList" className="mb-3">
              <MultiSelectView
                id="centerIdList"
                dataList={centerList.map((center: CenterRes) => {
                  return {
                    label: center.name.en,
                    value: center.id,
                  } as ItemProp;
                })}
                onChange={(data: any[]) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      centerIdList: data,
                    };
                  })
                }
                placeholder={t(I18nKey.SELECT_CENTER)}
                title={t(I18nKey.CENTER_TEXT)}
                isInvalid={!!errors.centerIdList}
                validationMessage={errors.centerIdList}
              />
            </Form.Group>

            <Form.Group controlId="subjectIdList" className="mb-3">
              <MultiSelectView
                id="subjectIdList"
                dataList={subjectList.map((subject: SubjectRes) => {
                  return {
                    label: subject.name.en,
                    value: subject.id,
                  } as ItemProp;
                })}
                onChange={(data: any[]) => {
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      subjectIdList: data,
                    };
                  });
                }}
                placeholder={t(I18nKey.SELECT_SUBJECT)}
                title={t(I18nKey.SUBJECT_TEXT)}
                isInvalid={!!errors.subjectIdList}
                validationMessage={errors.subjectIdList}
              />
            </Form.Group>

            <Form.Group controlId="examIdList" className="mb-3">
              <MultiSelectView
                id="examIdList"
                dataList={examList.map((exam: ExamRes) => {
                  return {
                    label: exam.name.en,
                    value: exam.id,
                  } as ItemProp;
                })}
                onChange={(data: any[]) => {
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      examIdList: data,
                    };
                  });
                }}
                placeholder={t(I18nKey.SELECT_EXAM)}
                title={t(I18nKey.EXAM_TEXT)}
                isInvalid={!!errors.examIdList}
                validationMessage={errors.examIdList}
              />
            </Form.Group>

            <Form.Group controlId="lineOne" className="mb-3">
              <Form.Label>{t(I18nKey.ADDRESS_LINE_ONE_TEXT)}</Form.Label>

              <Form.Control
                type="text"
                value={candidateReq.address.lineOne}
                isInvalid={!!errors.address.lineOne}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      address: {
                        ...preState.address,
                        lineOne: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.address.lineOne ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_ADDRESS_LINE_ONE_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address.lineOne}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="lineTwo" className="mb-3">
              <Form.Label>{t(I18nKey.ADDRESS_LINE_TWO_TEXT)}</Form.Label>

              <Form.Control
                type="text"
                value={candidateReq.address.lineTwo}
                isInvalid={!!errors.address.lineTwo}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      address: {
                        ...preState.address,
                        lineTwo: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.address.lineTwo ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_ADDRESS_LINE_TWO_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address.lineTwo}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="street" className="mb-3">
              <Form.Label>{t(I18nKey.ADDRESS_STREET_TEXT)}</Form.Label>

              <Form.Control
                type="text"
                value={candidateReq.address.street}
                isInvalid={!!errors.address.street}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      address: {
                        ...preState.address,
                        street: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.address.street ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_ADDRESS_STREET_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address.street}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="city" className="mb-3">
              <Form.Label>{t(I18nKey.ADDRESS_CITY_TEXT)}</Form.Label>

              <Form.Control
                type="text"
                value={candidateReq.address.city}
                isInvalid={!!errors.address.city}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      address: {
                        ...preState.address,
                        city: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.address.city ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_ADDRESS_CITY_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address.city}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="state" className="mb-3">
              <Form.Label>{t(I18nKey.ADDRESS_STATE_TEXT)}</Form.Label>

              <Form.Control
                type="text"
                value={candidateReq.address.state}
                isInvalid={!!errors.address.state}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      address: {
                        ...preState.address,
                        state: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.address.state ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_ADDRESS_STATE_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address.state}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="pinCode" className="mb-3">
              <Form.Label>{t(I18nKey.ADDRESS_PIN_CODE_TEXT)}</Form.Label>

              <Form.Control
                type="number"
                value={candidateReq.address.pinCode}
                isInvalid={!!errors.address.pinCode}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      address: {
                        ...preState.address,
                        pinCode: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.address.pinCode ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_ADDRESS_PIN_CODE_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address.pinCode}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="parentName" className="mb-3">
              <Form.Label>{t(I18nKey.PARENT_NAME_TEXT)}</Form.Label>

              <Form.Control
                type="text"
                value={candidateReq.parentInfo.name}
                isInvalid={!!errors.parentInfo.name}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      parentInfo: {
                        ...preState.parentInfo,
                        name: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.parentInfo.name ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_PARENT_NAME_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.parentInfo.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="parentEmail" className="mb-3">
              <Form.Label>{t(I18nKey.PARENT_EMAIL_TEXT)}</Form.Label>

              <Form.Control
                type="text"
                value={candidateReq.parentInfo.email}
                isInvalid={!!errors.parentInfo.email}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      parentInfo: {
                        ...preState.parentInfo,
                        email: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.parentInfo.email ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_PARENT_EMAIL_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.parentInfo.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="parentPhone" className="mb-3">
              <Form.Label>{t(I18nKey.PARENT_PHONE_TEXT)}</Form.Label>

              <Form.Control
                type="text"
                maxLength={10}
                value={candidateReq.parentInfo.phone}
                isInvalid={!!errors.parentInfo.phone}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      parentInfo: {
                        ...preState.parentInfo,
                        phone: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.parentInfo.phone ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_PARENT_PHONE_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.parentInfo.phone}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="parentRelationship" className="mb-3">
              <Form.Label>{t(I18nKey.PARENT_RELATIONSHIP_TEXT)}</Form.Label>

              <Form.Control
                type="text"
                value={candidateReq.parentInfo.relationship}
                isInvalid={!!errors.parentInfo.relationship}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      parentInfo: {
                        ...preState.parentInfo,
                        relationship: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.parentInfo.relationship ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_PARENT_RELATIONSHIP_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.parentInfo.relationship}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="parentRelationship" className="mb-3">
              <Form.Label>{t(I18nKey.PARENT_OCCUPATION_TEXT)}</Form.Label>

              <Form.Control
                type="text"
                value={candidateReq.parentInfo.occupation}
                isInvalid={!!errors.parentInfo.occupation}
                onChange={(e) =>
                  setCandidateReq((preState) => {
                    return {
                      ...preState,
                      parentInfo: {
                        ...preState.parentInfo,
                        occupation: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.parentInfo.occupation ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_PARENT_OCCUPATION_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.parentInfo.occupation}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="text-candidate mt-4">
              <Button
                variant="primary"
                type="submit"
                className="text-white ft-14 font-weight-600"
              >
                {t(I18nKey.SUBMIT)}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </section>
  );
};

export default AddCandidatePage;
