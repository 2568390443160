import { BatchRequest, BatchSearchReq } from '../request/batch.request';
import { BatchRes } from '../response/batch.response';
import httpClient from '../util/http-client';

export const BatchService = {
  getBatchById: async (id: string): Promise<(BatchRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/batch/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getBatchList: async (
    BatchSearchReq: BatchSearchReq,
  ): Promise<(BatchRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/batch-search', BatchSearchReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveBatch: async (
    batchRequest: BatchRequest,
  ): Promise<(BatchRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/batch`, batchRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateBatch: async (
    id: string,
    batchRequest: BatchRequest,
  ): Promise<(BatchRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(`/batch/${id}`, batchRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
