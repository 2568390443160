import { createBrowserRouter } from 'react-router-dom';
import { SecureRoute } from '../security/secure.route';
import MainHolder from '../page/main.holder';
import AuthPage from '../page/auth/auth.page';
import HomePage from '../page/home/home.page';
import QuestionPage from '../page/question/question.page';
import CreateTestPage from '../page/test/create/create-test.page';
import TestPage from '../page/test/test.page';
import CandidatePage from '../page/candidate/candidate.page';
import AddCandidatePage from '../page/candidate/candidate-add.page';
import UpdateCandidatePage from '../page/candidate/candidate-update.page';
import SubjectPage from '../page/subject/subject.page';
import AddSubjectPage from '../page/subject/subject-add.page';
import UpdateSubjectPage from '../page/subject/subject-update.page';
import TopicPage from '../page/topic/topic.page';
import AddTopicPage from '../page/topic/topic-add.page';
import UpdateTopicPage from '../page/topic/topic-update.page';
import ExamPage from '../page/exam/exam.page';
import AddExamPage from '../page/exam/exam-add.page';
import UpdateExamPage from '../page/exam/exam-update.page';
import ProgramPage from '../page/program/program.page';
import AddProgramPage from '../page/program/program-add.page';
import UpdateProgramPage from '../page/program/program-update.page';
import CenterPage from '../page/center/center.page';
import AddCenterPage from '../page/center/center-add.page';
import UpdateCenterPage from '../page/center/center-update.page';
import BatchPage from '../page/batch/batch.page';
import AddBatchPage from '../page/batch/batch-add.page';
import UpdateBatchPage from '../page/batch/batch-update.page';
import AddQuestionPage from '../page/question/add/add-question.page';
import EditQuestionPage from '../page/question/edit/edit-question.page';
import UpdateTestPage from '../page/test/create/update-test.page';
import TestDetailComponent from '../page/test/detail/test-detail.component';
import AddBulkCandidatePage from '../page/candidate/bulk-candidate-add.page';
import AddBulkQuestionPage from '../page/question/add/bulk-question-add.page';

export const mainRoute = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthPage />,
    children: [],
  },
  {
    element: <MainHolder />,
    children: [
      {
        path: '',
        element: <SecureRoute element={<HomePage />} />,
        children: [],
      },
      {
        path: '/test/history',
        element: <SecureRoute element={<TestPage />} />,
        children: [],
      },
      {
        path: '/test/create',
        element: <SecureRoute element={<CreateTestPage />} />,
        children: [],
      },
      {
        path: '/test/:id/update',
        element: <SecureRoute element={<UpdateTestPage />} />,
        children: [],
      },
      {
        path: '/test/:id',
        element: <SecureRoute element={<TestDetailComponent />} />,
        children: [],
      },
      {
        path: '/question',
        element: <SecureRoute element={<QuestionPage />} />,
        children: [],
      },
      {
        path: '/question/add',
        element: <SecureRoute element={<AddQuestionPage />} />,
        children: [],
      },
      {
        path: '/question/bulk-add',
        element: <SecureRoute element={<AddBulkQuestionPage />} />,
        children: [],
      },
      {
        path: '/question/:id/update',
        element: <SecureRoute element={<EditQuestionPage />} />,
        children: [],
      },
      {
        path: '/config/candidate',
        element: <SecureRoute element={<CandidatePage />} />,
        children: [],
      },
      {
        path: '/config/candidate/add',
        element: <SecureRoute element={<AddCandidatePage />} />,
        children: [],
      },
      {
        path: '/config/candidate/bulk-add',
        element: <SecureRoute element={<AddBulkCandidatePage />} />,
        children: [],
      },
      {
        path: '/config/candidate/:id/update',
        element: <SecureRoute element={<UpdateCandidatePage />} />,
        children: [],
      },
      {
        path: '/config/subject',
        element: <SecureRoute element={<SubjectPage />} />,
        children: [],
      },
      {
        path: '/config/subject/add',
        element: <SecureRoute element={<AddSubjectPage />} />,
        children: [],
      },
      {
        path: '/config/subject/:id/update',
        element: <SecureRoute element={<UpdateSubjectPage />} />,
        children: [],
      },
      {
        path: '/config/topic',
        element: <SecureRoute element={<TopicPage />} />,
        children: [],
      },
      {
        path: '/config/topic/add',
        element: <SecureRoute element={<AddTopicPage />} />,
        children: [],
      },
      {
        path: '/config/topic/:id/update',
        element: <SecureRoute element={<UpdateTopicPage />} />,
        children: [],
      },
      {
        path: '/config/exam',
        element: <SecureRoute element={<ExamPage />} />,
        children: [],
      },
      {
        path: '/config/exam/add',
        element: <SecureRoute element={<AddExamPage />} />,
        children: [],
      },
      {
        path: '/config/exam/:id/update',
        element: <SecureRoute element={<UpdateExamPage />} />,
        children: [],
      },
      {
        path: '/config/program',
        element: <SecureRoute element={<ProgramPage />} />,
        children: [],
      },
      {
        path: '/config/program/add',
        element: <SecureRoute element={<AddProgramPage />} />,
        children: [],
      },
      {
        path: '/config/program/:id/update',
        element: <SecureRoute element={<UpdateProgramPage />} />,
        children: [],
      },
      {
        path: '/config/center',
        element: <SecureRoute element={<CenterPage />} />,
        children: [],
      },
      {
        path: '/config/center/add',
        element: <SecureRoute element={<AddCenterPage />} />,
        children: [],
      },
      {
        path: '/config/center/:id/update',
        element: <SecureRoute element={<UpdateCenterPage />} />,
        children: [],
      },
      {
        path: '/config/batch',
        element: <SecureRoute element={<BatchPage />} />,
        children: [],
      },
      {
        path: '/config/batch/add',
        element: <SecureRoute element={<AddBatchPage />} />,
        children: [],
      },
      {
        path: '/config/batch/:id/update',
        element: <SecureRoute element={<UpdateBatchPage />} />,
        children: [],
      },
    ],
  },
  {
    path: '*',
    element: <h1>Page not found</h1>,
  },
]);
