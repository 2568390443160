import { useTranslation } from 'react-i18next';
import { QuestionRes } from '../../../response/question.response';
import { CKTextView } from '../../../view/ck-text.view';

export interface QuestionListComponentProps {
  questionList: QuestionRes[];
  activeQuestion: QuestionRes;
  setActiveQuestion: (question: QuestionRes) => void;
}

const QuestionListComponent: React.FC<QuestionListComponentProps> = ({
  questionList,
  activeQuestion,
  setActiveQuestion,
}) => {
  const { t } = useTranslation();

  const selectQuestion = (question: QuestionRes) => {
    setActiveQuestion(question);
  };

  return (
    <>
      <div className="flex-column question--list overflow-y-auto h-75vh">
        {questionList.map((question: QuestionRes, index: number) => {
          return (
            <div
              className={`cursor-pointer text-nero p-2 d-flex mb-1 rounded-2 question--item ${
                question.id == activeQuestion.id ? 'active' : ''
              }`}
              onClick={() => {
                selectQuestion(question);
              }}
            >
              <div className="d-flex flex-column">
                <h6 className="m-0 font-weight-600 mb-1">
                  Question {index + 1}
                </h6>
                <div className="text-nero ft-12">
                  <div
                    className="custom-ckeditor--view"
                    style={{
                      maxHeight: 84,
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${question.description.en}`,
                      }}
                    ></div>
                    {/* <CKTextView initData={} /> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default QuestionListComponent;
