import { I18nKey } from '../../i18n/i18n.key';
import { t } from 'i18next';
import { FloatingButtonView } from '../../view/floating-button.view';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import QuestionComponent from './component/question.component';

const QuestionPage = () => {
  const { openAddBulkQuestion } = useSSNavigate();

  return (
    <section className="my-2">
      <QuestionComponent />
      <FloatingButtonView
        innerElement={
          <>
            <img src="/images/add.svg" alt="" />
            <span className="ms-2">{t(I18nKey.ADD_QUESTION_TEXT)}</span>
          </>
        }
        onClick={() => {
          openAddBulkQuestion();
        }}
      />
    </section>
  );
};

export default QuestionPage;
