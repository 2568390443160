import { useState } from 'react';
import { Badge, Button, Dropdown } from 'react-bootstrap';
import { ItemProp } from '../model/item-prop.model';

export interface MultiSelectDropdownProps {
  title: string;
  badgeBackgroud: string;
  badgeClassName: string;
  id: string;
  placeholder: string;
  dataList: ItemProp[];
  onChange: (values: (string | number)[]) => void;
  defaultSelected?: string[];
  isInvalid: boolean | false;
  validationMessage: string | '';
  showBadge: boolean;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  title,
  badgeBackgroud,
  badgeClassName,
  showBadge,
  dataList,
  onChange,
  defaultSelected,
  isInvalid,
  validationMessage,
}) => {
  const [selectedItemList, setSelectedItemList] = useState<(string | number)[]>(
    defaultSelected ? defaultSelected : [],
  );

  const handleSelect = (value: any, event: Object) => {
    setSelectedItemList((selectedItemList) => {
      const _selectedItemList = [...selectedItemList];
      const index = _selectedItemList.indexOf(value);
      if (index > -1) {
        _selectedItemList.splice(index, 1);
      } else {
        _selectedItemList.push(value);
      }

      onChange(_selectedItemList);

      return [..._selectedItemList];
    });
  };

  const removeItem = (value: any) => {
    setSelectedItemList((selectedItemList) => {
      const _selectedItemList = [...selectedItemList];
      const index = _selectedItemList.indexOf(value);
      if (index > -1) {
        _selectedItemList.splice(index, 1);
      }

      onChange(_selectedItemList);

      return [..._selectedItemList];
    });
  };

  return (
    <>
      <div className={`d-flex align-items-center pt-2`}>
        <Dropdown
          onSelect={handleSelect}
          className="text-dimgray ft-14 tags--dropdown me-3"
        >
          <Dropdown.Toggle
            variant="white"
            id="tagsSelect"
            className="tags--select-list border-gray text-dimgray w-100 text-start d-flex align-items-center justify-content-between position-relative heading--top-section ft-14 py-2"
          >
            <span className="position-absolute bg-white px-2 heading-name ft-12 font-weight-600 text-nero">
              {title}
            </span>
            {showBadge && (
              <div className="d-flex align-items-center">
                <span className="font-weight-600">
                  {selectedItemList.length == 0 ? title : '-Select-'}
                </span>
                {selectedItemList.length > 0 && (
                  <Badge
                    bg="dimgray"
                    className="rounded-circle small filter-badge ms-1"
                  >
                    +{selectedItemList.length}
                  </Badge>
                )}
              </div>
            )}
            {!showBadge && (
              <span>{selectedItemList.length == 0 ? title : '-Select-'}</span>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu className="w-100 bg-off-white border-0 shadow ft-14 pt-2 custom--menu">
            {dataList.map((data: ItemProp, index: number) => {
              return (
                <Dropdown.Item
                  as="button"
                  eventKey={data.value}
                  className={`py-2 ${
                    selectedItemList.includes(data.value) ? 'bg-blue-200' : ''
                  }  `}
                >
                  {data.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        {!showBadge &&
          selectedItemList.length > 0 &&
          selectedItemList.map((data: any, index: number) => {
            return (
              <Badge
                bg={badgeBackgroud}
                key={`tag_name_badge_${index}`}
                className={`me-2 p-2 text-nero font-weight-500 d-flex align-items-center ft-12 ${badgeClassName}`}
              >
                <span className="me-3">
                  {
                    dataList.filter((itemProp: ItemProp) => {
                      return itemProp.value == data;
                    })[0]?.label
                  }
                </span>
                <Button
                  className="p-0 bg-transparent border-0 ms-auto d-flex"
                  onClick={() => removeItem(data)}
                >
                  <img src="/images/cancel.svg" height={14} alt="" />
                </Button>
              </Badge>
            );
          })}
      </div>
    </>
  );
};

export default MultiSelectDropdown;
