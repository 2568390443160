import { useEffect, useState } from 'react';
import { Table, Button, Col, Container, Row } from 'react-bootstrap';
import { SubjectRes } from '../../response/subject.response';
import { SubjectService } from '../../service/subject.service';
import { SubjectSearchReq } from '../../request/subject.request';
import { FloatingButtonView } from '../../view/floating-button.view';
import { I18nKey } from '../../i18n/i18n.key';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../themes/theme.provider';

const SubjectPage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [subjectList, setSubjectList] = useState<SubjectRes[]>([]);
  const { openAddSubject, openUpdateSubject } = useSSNavigate();

  const fetchSubject = async () => {
    const [subjectList, err] = await SubjectService.getSubjectList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as SubjectSearchReq);

    setSubjectList(subjectList);
  };

  useEffect(() => {
    fetchSubject();
  }, []);

  return (
    <section>
      <Container>
        <Row
          className="rounded p-2"
          style={{
            backgroundColor: theme.greyColor,
          }}
        >
          <Col xs={6}>Name</Col>
          <Col xs={6}>Actions</Col>
        </Row>
        {subjectList.map((subject: SubjectRes) => {
          return (
            <Row className="rounded border p-2">
              <Col xs={6}>{subject.name.en}</Col>
              <Col xs={6}>
                <Button
                  variant="link"
                  className="text-primary text-decoration-underline p-0 font-weight-600"
                  onClick={() => {
                    openUpdateSubject(subject.id);
                  }}
                >
                  <img
                    src="/images/edit_button.svg"
                    alt=""
                    width={20}
                    height={20}
                  />
                </Button>
              </Col>
            </Row>
          );
        })}
      </Container>

      <FloatingButtonView
        innerElement={
          <>
            <img src="/images/add.svg" alt="" />
            <span className="ms-2">{t(I18nKey.ADD_SUBJECT)}</span>
          </>
        }
        onClick={() => {
          openAddSubject();
        }}
      />
    </section>
  );
};

export default SubjectPage;
