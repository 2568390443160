import { useEffect, useState } from 'react';
import { Button, Spinner, Form } from 'react-bootstrap';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../i18n/i18n.key';
import { useTranslation } from 'react-i18next';
import { BatchRequest } from '../../request/batch.request';
import { BatchService } from '../../service/batch.service';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useParams } from 'react-router';
import { ItemProp } from '../../model/item-prop.model';

const UpdateBatchPage = () => {
  const { t } = useTranslation();
  const { openBatch } = useSSNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    status: '',
    startDate: '',
    endDate: '',
  });
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const updateBatch = async (batchReq: BatchRequest) => {
    const [data, error] = await BatchService.updateBatch(
      id as string,
      batchReq,
    );

    if (error) {
      setLoading(false);
    } else {
      openBatch();
    }
  };

  const getBatch = async () => {
    const [batch, error] = await BatchService.getBatchById(id as string);

    setLoading(false);
    if (error) {
      openBatch();
    } else {
      setName(batch.name.en);
      setStatus(batch.status);
      setStartDate(batch.start);
      setEndDate(batch.end);
    }
  };

  useEffect(() => {
    getBatch();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (!validator.isLength(name, { min: 2, max: 30 })) {
      _errors.name = 'Please enter valid Name';
    }

    if (!validator.isLength(status, { min: 4, max: 6 })) {
      _errors.status = 'Please select status';
    }

    if (!validator.isLength(startDate, { min: 1, max: 30 })) {
      _errors.startDate = 'Please select Start Date';
    }

    if (!validator.isLength(endDate, { min: 1, max: 30 })) {
      _errors.endDate = 'Please select End DATE';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      const batchReq = {
        name: {
          en: name,
        },
        status: status,
        start: new Date(startDate),
        end: new Date(endDate),
      } as BatchRequest;
      updateBatch(batchReq);
    }
  };

  const formStyle = {
    width: '96%',
    maxWidth: '769px',
    margin: 'auto',
  };

  return (
    <section className="my-3">
      <div
        className="rounded-16 bg-white p-4 ft-14 mt-lg-0 mt-5 custom-shadow text-nero z-1"
        style={formStyle}
      >
        <h5 className="mb-4 text-nero">{t(I18nKey.UPDATE_BATCH)}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>{t(I18nKey.BATCH_NAME_TEXT)}</Form.Label>

              <Form.Control
                required
                type="text"
                value={name}
                isInvalid={!!errors.name}
                onChange={(e) => setName(e.target.value)}
                className={`ft-14 px-1 shadow-none border ${
                  errors.name ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_BATCH_NAME_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid name
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="status" className="mb-3">
              <Form.Label>{t(I18nKey.SELECT_STATUS)}</Form.Label>

              <Form.Select
                required
                value={status}
                isInvalid={!!errors.status}
                onChange={(e) => setStatus(e.target.value)}
                className={`ft-14 px-1 shadow-none border ${
                  errors.name ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.SELECT_STATUS)}
              >
                {[
                  {
                    value: 'closed',
                    label: 'Closed',
                  } as ItemProp,
                  {
                    value: 'live',
                    label: 'Live',
                  } as ItemProp,
                  {
                    value: 'hold',
                    label: 'Hold',
                  } as ItemProp,
                ].map((itemProp: ItemProp) => {
                  return (
                    <option value={itemProp.value}>{itemProp.label}</option>
                  );
                })}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="startDate" className="mb-3">
              <Form.Label>Select Start Date</Form.Label>

              <Form.Control
                required
                type="date"
                value={startDate}
                defaultValue={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.startDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="endDate" className="mb-3">
              <Form.Label>Select End Date</Form.Label>

              <Form.Control
                required
                type="date"
                value={endDate}
                defaultValue={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.endDate}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="text-batch mt-4">
              <Button
                variant="primary"
                type="submit"
                className="text-white ft-14 font-weight-600"
              >
                {t(I18nKey.SUBMIT)}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </section>
  );
};

export default UpdateBatchPage;
