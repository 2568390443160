import { useEffect, useState } from 'react';
import { Table, Button, Col, Container, Row } from 'react-bootstrap';
import { TopicRes } from '../../response/topic.response';
import { TopicService } from '../../service/topic.service';
import { TopicSearchReq } from '../../request/topic.request';
import { FloatingButtonView } from '../../view/floating-button.view';
import { I18nKey } from '../../i18n/i18n.key';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../themes/theme.provider';
import { CenterRes } from '../../response/center.response';

const TopicPage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [topicList, setTopicList] = useState<TopicRes[]>([]);
  const { openAddTopic, openUpdateTopic } = useSSNavigate();

  const fetchTopic = async () => {
    const [topicList, err] = await TopicService.getTopicList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as TopicSearchReq);

    setTopicList(topicList);
  };

  useEffect(() => {
    fetchTopic();
  }, []);

  return (
    <section>
      <Container>
        <Row
          className="rounded p-2"
          style={{
            backgroundColor: theme.greyColor,
          }}
        >
          <Col xs={4}>Name</Col>
          <Col xs={4}>Subject Name</Col>
          <Col xs={4}>Actions</Col>
        </Row>
        {topicList.map((topic: TopicRes) => {
          return (
            <Row className="rounded border p-2">
              <Col xs={4}>{topic.name.en}</Col>
              <Col xs={4}>{topic.subject.name.en}</Col>
              <Col xs={4}>
                <Button
                  variant="link"
                  className="text-primary text-decoration-underline p-0 font-weight-600"
                  onClick={() => {
                    openUpdateTopic(topic.id);
                  }}
                >
                  <img
                    src="/images/edit_button.svg"
                    alt=""
                    width={20}
                    height={20}
                  />
                </Button>
              </Col>
            </Row>
          );
        })}
      </Container>

      <FloatingButtonView
        innerElement={
          <>
            <img src="/images/add.svg" alt="" />
            <span className="ms-2">{t(I18nKey.ADD_TOPIC)}</span>
          </>
        }
        onClick={() => {
          openAddTopic();
        }}
      />
    </section>
  );
};

export default TopicPage;
