import { Button } from 'react-bootstrap';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';

const HomePage = () => {
  const { openCandidate } = useSSNavigate();

  return (
    <div style={{ display: 'grid' }}>
      <h1 style={{ margin: 'auto' }}>Welcome to admin portal</h1>
      <Button onClick={openCandidate} style={{ margin: 'auto', marginTop: 64 }}>
        Add New Student
      </Button>
    </div>
  );
};

export default HomePage;
