import React, { useEffect, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../i18n/i18n.key';
import { CKInputView } from '../../../../view/ck-input.view';
import { CKTextView } from '../../../../view/ck-text.view';

export interface InstructionsComponentProp {
  defaultData?: string;
  updateTestReq: (testReq: any) => void;
}

const InstructionsComponent: React.FC<InstructionsComponentProp> = ({
  defaultData,
  updateTestReq,
}) => {
  const { t } = useTranslation();

  const [instruction, setInstruction] = useState(
    defaultData ? defaultData : '',
  );

  const [show, setShow] = useState(false);

  useEffect(() => {
    updateTestReq({
      instruction: {
        en: instruction,
      },
    });
  }, [instruction]);

  return (
    <>
      {instruction && instruction.length > 0 ? (
        <div>
          <h6 className="font-weight-600 mb-0">
            {t(I18nKey.INSTRUCTION_TEXT)}
          </h6>
          <div className="custom-ckeditor--view">
            <CKTextView initData={instruction.substring(0, 100)} />
            <Button
              variant="white"
              className="font-weight-600 border-0 p-0 ft-14 d-flex"
              onClick={() => setShow(true)}
            >
              See all
            </Button>
          </div>
        </div>
      ) : (
        <Button
          variant="white"
          onClick={() => setShow(true)}
          className="ft-14 border-0 text-nero my-3 px-0 py-2 d-flex align-items-center "
        >
          <img src="/images/add.svg" alt=""></img>
          <span className="ms-2 font-weight-600">
            {t(I18nKey.ADD_INSTRUCTION_TEXT)}
          </span>
        </Button>
      )}

      <Offcanvas
        show={show}
        onHide={() => setShow(false)}
        placement="end"
        className="add-edit-question--canvas w-75 rounded-start"
      >
        <Offcanvas.Header>
          <Button
            variant="white"
            onClick={() => setShow(false)}
            className="p-0 ms-auto d-flex align-items-center font-weight-600 ft-14 border-0 shadow-none"
          >
            <img src="/images/close.svg" alt="" className="me-2" />
            {t(I18nKey.CLOSE_TEXT)}
          </Button>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <h6 className="font-weight-600">{t(I18nKey.INSTRUCTION_TEXT)}</h6>
          <div>
            <div className="custom-ckeditor--edit-view border border-light-blue rounded pb-2 px-2">
              <CKInputView
                initData={instruction}
                placeholder={'Please enter descriptions'}
                onChange={(data: string) => {
                  setInstruction(data);
                }}
              />
            </div>
            <Button
              variant="light-600"
              className="font-weight-600 mt-3 mb-2 ft-14 d-flex ms-auto"
              onClick={() => setShow(false)}
            >
              {t(I18nKey.ADD_INSTRUCTION_TEXT)}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default InstructionsComponent;
