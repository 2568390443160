import { Form } from 'react-bootstrap';
import { CKTextView } from '../../../../../view/ck-text.view';
import {
  QuestionOption,
  QuestionRes,
} from '../../../../../response/question.response';

export interface MultipleChoiceComponentProps {
  question: QuestionRes;
}

const MultipleChoiceComponent: React.FC<MultipleChoiceComponentProps> = ({
  question,
}) => {
  return (
    <>
      {question.optionList.map((option: QuestionOption, index: number) => {
        return (
          <div className="single-choice-type">
            <Form.Check
              type="checkbox"
              label={<CKTextView initData={option.value.en} />}
              name="mcq"
              checked={option.correct}
              defaultChecked={option.correct}
              className={`mb-3 bg-white d-flex align-items-start py-2 px-2 signle-type-inputs border rounded ${
                option.correct
                  ? 'border-dimgray checked-type'
                  : 'border-light-gray'
              }`}
            />
          </div>
        );
      })}
    </>
  );
};

export default MultipleChoiceComponent;
