import { CenterRequest, CenterSearchReq } from '../request/center.request';
import { CenterRes } from '../response/center.response';
import httpClient from '../util/http-client';

export const CenterService = {
  getCenterById: async (id: string): Promise<(CenterRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/center/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getCenterList: async (
    CenterSearchReq: CenterSearchReq,
  ): Promise<(CenterRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/center-search', CenterSearchReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveCenter: async (
    centerRequest: CenterRequest,
  ): Promise<(CenterRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/center`, centerRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateCenter: async (
    id: string,
    centerRequest: CenterRequest,
  ): Promise<(CenterRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(`/center/${id}`, centerRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
