import { useState } from 'react';
import { useTheme } from '../../../themes/theme.provider';
import { MenuItem } from '../../../model/menu-item.model';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';

export interface MenuItemComponentProp {
  isLast?: boolean;
  isFirst?: boolean;
  menuItem: MenuItem;
}

const MenuItemComponent: React.FC<MenuItemComponentProp> = ({
  isFirst,
  isLast,
  menuItem,
}) => {
  const [expanded, setExpanded] = useState(false);

  const { theme } = useTheme();

  const { openPath } = useSSNavigate();

  return (
    <div
      className={`${isFirst ? 'rounded-top' : ''}${
        isLast ? 'rounded-bottom' : ''
      }`}
      style={{
        backgroundColor: expanded ? '#1A529C' : theme.secondaryColor,
        width: '100%',
        marginTop: 4,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'space-between',
          width: '100%',
          padding: 8,
          paddingTop: 16,
          cursor: 'pointer',
          background:
            window.location.pathname.includes(menuItem.path) &&
            menuItem.subMenuList.length == 0
              ? '#4D79B2'
              : '',
        }}
        onClick={() => {
          if (menuItem.subMenuList.length > 0) {
            setExpanded((preState) => !preState);
          } else {
            openPath(menuItem.path);
          }
        }}
      >
        <h6 style={{ color: theme.white, marginLeft: 4 }}>{menuItem.name}</h6>
        {menuItem.subMenuList.length > 0 && (
          <>
            {!expanded && (
              <img
                src="/images/arrow-expand.svg"
                alt=""
                width={20}
                height={20}
              />
            )}
            {expanded && (
              <img
                src="/images/arrow-close.svg"
                alt=""
                width={20}
                height={20}
              />
            )}
          </>
        )}
      </div>

      {expanded &&
        menuItem.subMenuList.map((subMenuItem: MenuItem) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignContent: 'start',
                width: '100%',
                padding: 8,
                paddingTop: 16,
                cursor: 'pointer',
                backgroundColor: window.location.pathname.includes(
                  subMenuItem.path,
                )
                  ? '#4D79B2'
                  : '',
              }}
              onClick={() => {
                openPath(`${menuItem.path}${subMenuItem.path}`);
              }}
            >
              <img
                src={`/images/${subMenuItem.icon}`}
                alt=""
                width={20}
                height={20}
                style={{ marginLeft: 8 }}
              />
              <h6 style={{ color: theme.white, marginLeft: 16 }}>
                {subMenuItem.name}
              </h6>
            </div>
          );
        })}
    </div>
  );
};

export default MenuItemComponent;
