import { ExamRequest, ExamSearchReq } from '../request/exam.request';
import { ExamRes } from '../response/exam.response';
import httpClient from '../util/http-client';

export const ExamService = {
  getExamById: async (id: string): Promise<(ExamRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/exam/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getExamList: async (
    ExamSearchReq: ExamSearchReq,
  ): Promise<(ExamRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/exam-search', ExamSearchReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveExam: async (examRequest: ExamRequest): Promise<(ExamRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/exam`, examRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateExam: async (
    id: string,
    examRequest: ExamRequest,
  ): Promise<(ExamRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(`/exam/${id}`, examRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
