import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { FilePickerView } from '../../../view/file-picker.view';
import { QuestionRequest } from '../../../request/question.request';
import { QuestionService } from '../../../service/question.service';
import axios from 'axios';
import { QuestionOption } from '../../../response/question.response';
import { Media } from '../../../model/media.model';
import SingleSelectDropdown from '../../../view/single-select-drop-down.view';
import { ItemProp } from '../../../model/item-prop.model';
import MultiSelectDropdown from '../../../view/multi-select-drop-down.view';
import { SubjectRes } from '../../../response/subject.response';
import { TopicRes } from '../../../response/topic.response';
import InputSelectView from '../../../view/input.select.view';
import { SubjectSearchReq } from '../../../request/subject.request';
import { TopicSearchReq } from '../../../request/topic.request';
import { SubjectService } from '../../../service/subject.service';
import { TopicService } from '../../../service/topic.service';
import { QuestionType } from '../../../enum/question-type.enum';
import QuestionHolderEditComponent from '../../shared/question/edit/question-holder-edit.component';
import { CKInputView } from '../../../view/ck-input.view';
import { useSnackbar } from 'notistack';

const AddBulkQuestionPage = () => {
  const { t } = useTranslation();
  const { openQuestion, openAddQuestion } = useSSNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [subjectList, setSubjectList] = useState<SubjectRes[]>([]);
  const [topicList, setTopicList] = useState<TopicRes[]>([]);

  const [loading, setLoading] = useState(false);
  const subjectId = useRef('');
  const [_subjectId, setSubjectId] = useState(subjectId.current);
  const topicIdList = useRef<string[]>([]);
  const tagList = useRef<string[]>([]);
  const [questionReqList, setQuestionReqList] = useState<
    (QuestionRequest & { warning: boolean })[]
  >([]);

  const fetchData = async () => {
    const [topicList, err2] = await TopicService.getTopicList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as TopicSearchReq);

    const [subjectList, err3] = await SubjectService.getSubjectList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as SubjectSearchReq);

    setSubjectList(subjectList);
    setTopicList(topicList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const saveAllQuestion = async () => {
    if (!subjectId.current || subjectId.current.length == 0) {
      enqueueSnackbar('Please select subject', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
      return;
    }
    if (topicIdList.current.length == 0) {
      enqueueSnackbar('Please select topic', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
      return;
    }
    if (tagList.current.length == 0) {
      enqueueSnackbar('Please select tag', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
      return;
    }
    if (questionReqList.length == 0) {
      return;
    }
    setLoading(true);

    const batchSize = 100;
    const totalRequests = questionReqList.length;
    let processedCount = 0;

    while (processedCount < totalRequests) {
      const batch = questionReqList.slice(
        processedCount,
        processedCount + batchSize,
      );
      const promiseList = batch.map((questionReq) => {
        return QuestionService.saveQuestion({
          ...questionReq,
          topicIdList: topicIdList.current,
          tagList: tagList.current,
          subjectId: subjectId.current,
        });
      });

      try {
        const resultList = await Promise.all(promiseList);
        resultList.forEach(([data, error]) => {});
      } catch (err) {
        console.error('Error occurred:', err);
      }

      processedCount += batch.length;
    }
    setLoading(false);
    openQuestion();
  };

  const handleTemplateDownload = () => {
    const fileUrl = '/files/Question Sample Template.docx';

    // Create an invisible link element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'Question Sample Template.docx';

    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const parseFileData = async (fileList: File[]) => {
    setLoading(true);
    setQuestionReqList([]);
    const file = fileList[0];

    try {
      const parsedDataList = await processFile(file);
      const questionReqList = validateData(parsedDataList);
      setQuestionReqList(questionReqList);
    } catch (error) {
      console.error('Error reading file:', error);
    }
    setLoading(false);
  };

  const getQuestionType = (parsedType: string) => {
    switch (parsedType.toLocaleLowerCase()) {
      case 'single_choice':
        return QuestionType.SINGLE_CHOICE;
      case 'integer':
        return QuestionType.INT_ANS;
      case 'multiple_choice':
        return QuestionType.MULTIPLE_CHOICE;
      default:
        // handle other cases or return a default value
        break;
    }
  };

  const validateData = (parsedDataList: any[]) => {
    return parsedDataList.map((parsedData: any) => {
      return {
        description: {
          en: parsedData.question ?? '',
        },
        instruction: {
          en: parsedData.instruction ?? '',
        },
        topicIdList: topicIdList.current,
        tagList: tagList.current,
        subjectId: subjectId.current,
        type: getQuestionType(parsedData.type),
        optionList:
          parsedData.optionList?.map((parsedOption: any, index: number) => {
            return {
              id: `${index}`,
              value: {
                en: parsedOption.data,
              },
              correct: parsedOption.correct,
            } as QuestionOption;
          }) ?? [],
        answerList: (parsedData.answer ? [parsedData.answer] : []) as string[],
        mediaList: [] as Media[],
        status: 'live',
        marking: {
          positive: Number(parsedData.positive ?? 0),
          negative: Number(parsedData.negative ?? 0) * -1,
          partial: Number(parsedData.partial ?? 0),
        },
        warning: parsedData.warning == true,
      } as QuestionRequest & { warning: boolean };
    });
  };

  const processFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    // Define the URL
    const url = `${process.env.REACT_APP_AI_BASE_URL}doc-parse`;

    // Define headers for FormData
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    // Make the Axios POST request
    const data = await axios.post(url, formData, { headers });

    return data.data;
  };

  return (
    <>
      {questionReqList.length == 0 ? (
        <section>
          <Row
            className="p-4"
            style={{
              minHeight: '96vh',
            }}
          >
            <Col
              xs={12}
              lg={6}
              className="rounded-0"
              style={{ borderRightStyle: 'solid', paddingRight: 96 }}
            >
              <h5 className="card-title font-weight-bold">
                Bulk Questions Import
              </h5>
              <p className="font-weight-light mt-2">
                Upload multiple questions in few simple steps
              </p>
              <div
                className="card-body p-4"
                style={{
                  backgroundColor: '#F7FBFF',
                }}
              >
                <div className="d-flex">
                  <img
                    style={{
                      width: 36,
                      height: 36,
                    }}
                    src="/images/prepare_csv.svg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="px-2">
                    <h5 className="card-title font-weight-bold">
                      Prepare doc file
                    </h5>
                    <p>Use Saissy’s .docx file template</p>
                  </div>
                </div>
                <img
                  style={{
                    width: 4,
                    height: 66,
                    marginLeft: 16,
                    marginBottom: 16,
                  }}
                  src="/images/verticle_line.svg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="d-flex">
                  <img
                    style={{
                      width: 36,
                      height: 36,
                    }}
                    src="/images/upload_csv.svg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="px-2">
                    <h5 className="card-title font-weight-bold">
                      Upload doc file
                    </h5>
                    <p>Data errors will be checked and notified</p>
                  </div>
                </div>
                <img
                  style={{
                    width: 4,
                    height: 66,
                    marginLeft: 16,
                    marginBottom: 16,
                  }}
                  src="/images/verticle_line.svg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="d-flex">
                  <img
                    style={{
                      width: 36,
                      height: 36,
                    }}
                    src="/images/import_candidate.svg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="px-2">
                    <h5 className="card-title font-weight-bold">
                      Import Questions
                    </h5>
                    <p>Use Saissy’s .docx file template</p>
                  </div>
                </div>
              </div>

              <h5 className="card-title font-weight-bold mt-4 pt-4">
                Prepare doc File
              </h5>
              <ul>
                <li>Upload multiple questions in few simple steps</li>
                <li>Upload multiple questions in few simple steps</li>
              </ul>

              <button
                type="button"
                className="btn btn-secondary mt-2"
                onClick={handleTemplateDownload}
              >
                <img
                  style={{
                    width: 24,
                    height: 24,
                  }}
                  src="/images/download.svg"
                  className="card-img-top"
                  alt="..."
                />{' '}
                Download .docx Template
              </button>
            </Col>
            <Col xs={12} lg={6} className="rounded-0 border-left text-center">
              {loading ? (
                <Spinner
                  style={{
                    marginTop: 48,
                    display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  animation="grow"
                />
              ) : (
                <FilePickerView
                  id="questionSelector"
                  allowMultiple={false}
                  enableDragAndDrop={true}
                  allowedMimeTypes={[
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  ]}
                  onUpload={parseFileData}
                />
              )}

              <p className="pt-4">-or-</p>

              <button
                type="button"
                className="btn btn-secondary mt-2"
                onClick={openAddQuestion}
              >
                Add Single Question
              </button>
            </Col>
          </Row>
        </section>
      ) : (
        <Container>
          <h3>Imported Question</h3>

          <Col className="mt-2 mb-4 d-flex ">
            <SingleSelectDropdown
              title={'Subject'}
              id="subject"
              placeholder={''}
              dataList={subjectList.map((subject: SubjectRes) => {
                return {
                  label: subject.name.en,
                  value: subject.id,
                } as ItemProp;
              })}
              onChange={(value: any) => {
                subjectId.current = value;
                setSubjectId(value);
                topicIdList.current = [];
              }}
              isInvalid={false}
              validationMessage={''}
            />

            <MultiSelectDropdown
              title={'Topic'}
              badgeBackgroud={'light-700'}
              badgeClassName={'rounded'}
              id={''}
              placeholder={''}
              dataList={topicList
                .filter((topic: TopicRes) => {
                  return topic.subject.id == _subjectId;
                })
                .map((topic: TopicRes) => {
                  return {
                    label: topic.name.en,
                    value: topic.id,
                  } as ItemProp;
                })}
              onChange={(values: any[]) => {
                topicIdList.current = values;
              }}
              isInvalid={false}
              validationMessage={''}
              showBadge={false}
            />

            <InputSelectView
              title={'Tags'}
              badgeBackgroud={'light-700'}
              badgeClassName={'rounded'}
              id="add_question_tag"
              onChange={(dataList: string[]) => {
                tagList.current = dataList;
              }}
              showBadge={true}
            />

            <button
              type="button"
              className="btn btn-secondary ml-4"
              onClick={saveAllQuestion}
            >
              Save All
            </button>
          </Col>

          {questionReqList?.map(
            (
              questionRequest: QuestionRequest & { warning: boolean },
              index: number,
            ) => {
              return (
                <Row
                  className={`rounded border p-2 m-4 ${
                    questionRequest.warning ? 'border-danger' : 'border-success'
                  }`}
                >
                  <div className="d-flex flex-column mb-3">
                    <h6 className="mb-2 font-weight-600">
                      Question {index + 1}.
                    </h6>

                    <div className="custom-ckeditor--edit-view border border-light-blue rounded pb-2 px-2 mb-2">
                      <CKInputView
                        placeholder={'Please enter question'}
                        initData={questionRequest.description.en}
                        onChange={(data: string) => {
                          setQuestionReqList((preQuestionReqList) => {
                            preQuestionReqList[index].description = {
                              en: data,
                            };
                            return preQuestionReqList;
                          });
                        }}
                      />
                    </div>

                    <QuestionHolderEditComponent
                      type={questionRequest.type as QuestionType}
                      optionList={questionRequest.optionList}
                      answerList={questionRequest.answerList}
                      updateOptionList={(optionList: any[]) => {
                        setQuestionReqList((preQuestionReqList) => {
                          preQuestionReqList[index].optionList = optionList;
                          return preQuestionReqList;
                        });
                      }}
                      updateAnswerList={(answerList: any[]) => {
                        setQuestionReqList((preQuestionReqList) => {
                          preQuestionReqList[index].answerList = answerList;
                          return preQuestionReqList;
                        });
                      }}
                    />
                  </div>
                </Row>
              );
            },
          )}
        </Container>
      )}
    </>
  );
};

export default AddBulkQuestionPage;
