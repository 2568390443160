import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { CKInputView } from '../../../view/ck-input.view';
import { QuestionType } from '../../../enum/question-type.enum';
import InputSelectView from '../../../view/input.select.view';
import SingleSelectDropdown from '../../../view/single-select-drop-down.view';
import MultiSelectDropdown from '../../../view/multi-select-drop-down.view';
import QuestionHolderEditComponent from '../../shared/question/edit/question-holder-edit.component';
import ConfirmationView from '../../../view/confirmation.view';
import EditScoreComponent from '../component/edit-score.component';
import { Marking } from '../../../model/marking.model';
import { ItemProp } from '../../../model/item-prop.model';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { QuestionRequest } from '../../../request/question.request';
import { SubjectSearchReq } from '../../../request/subject.request';
import { TopicSearchReq } from '../../../request/topic.request';
import { QuestionOption } from '../../../response/question.response';
import { SubjectRes } from '../../../response/subject.response';
import { TopicRes } from '../../../response/topic.response';
import { QuestionService } from '../../../service/question.service';
import { SubjectService } from '../../../service/subject.service';
import { TopicService } from '../../../service/topic.service';
import { useParams } from 'react-router';
import { MiniTopic } from '../../../model/mini-topic.model';

const EditQuestionPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isDiscardModalShow, setIsDiscardModalShow] = useState(false);

  let { id } = useParams();

  const { openQuestion } = useSSNavigate();

  const [subjectList, setSubjectList] = useState<SubjectRes[]>([]);
  const [topicList, setTopicList] = useState<TopicRes[]>([]);

  const [questionReq, setQuestionReq] = useState({} as QuestionRequest);

  const updateQuestion = async () => {
    const [data, error] = await QuestionService.updateQuestion(
      id as string,
      questionReq,
    );

    if (error) {
      setLoading(false);
    } else {
      openQuestion();
    }
  };

  const getQuestion = async () => {
    setLoading(true);
    const [question, error] = await QuestionService.getQuestionById(
      id as string,
    );

    setLoading(false);
    if (error) {
      openQuestion();
    } else {
      setQuestionReq((preState) => {
        return {
          ...preState,
          description: question.description,
          subjectId: question.subject.id,
          type: question.type,
          status: question.status,
          topicIdList: question.topicList.map((topic: MiniTopic) => {
            return topic.id;
          }),
          marking: question.marking,
          optionList: question.optionList,
          answerList: question.answerList,
          tagList: question.tagList,
        };
      });
    }
  };

  const fetchData = async () => {
    const [topicList, err2] = await TopicService.getTopicList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as TopicSearchReq);

    const [subjectList, err3] = await SubjectService.getSubjectList({
      pageNumber: 1,
      itemsPerPage: 200,
    } as SubjectSearchReq);

    setSubjectList(subjectList);
    setTopicList(topicList);
  };

  useEffect(() => {
    getQuestion();
    fetchData();
  }, []);

  const formStyle = {
    width: '96%',
    maxWidth: '769px',
    margin: 'auto',
  };

  const updateMarking = (marking: Marking) => {
    setQuestionReq((preState) => {
      return {
        ...preState,
        marking: {
          positive: Number(marking.positive),
          negative: marking.negative * -1,
          partial: Number(marking.partial),
        },
      };
    });
  };

  const updateOptionList = (optionList: QuestionOption[]) => {
    setQuestionReq((preState) => {
      return {
        ...preState,
        optionList: optionList,
      };
    });
  };

  const updateAnswerList = (answerList: string[]) => {
    setQuestionReq((preState) => {
      return {
        ...preState,
        answerList: answerList,
      };
    });
  };

  return (
    <section className="my-3">
      <div
        className="rounded-16 bg-white p-4 ft-14 mt-lg-0 mt-5 custom-shadow text-nero z-1"
        style={formStyle}
      >
        <h5 className="mb-4 text-nero">{t(I18nKey.UPDATE_QUESTION)}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <>
            <div className={`d-flex align-items-left flex-column gap-3`}>
              <SingleSelectDropdown
                title={'Subject'}
                id="subject"
                placeholder={''}
                defaultSelected={questionReq.subjectId}
                dataList={subjectList.map((subject: SubjectRes) => {
                  return {
                    label: subject.name.en,
                    value: subject.id,
                  } as ItemProp;
                })}
                onChange={(value: any) => {
                  setQuestionReq((preState) => {
                    return {
                      ...preState,
                      subjectId: value,
                    };
                  });
                }}
                isInvalid={false}
                validationMessage={''}
              />

              {/* <SingleSelectDropdown
                title={'Type'}
                id="type"
                placeholder={''}
                defaultSelected={questionReq.type}
                dataList={[
                  {
                    label: 'Single choice',
                    value: 'Single choice',
                  } as ItemProp,
                  {
                    label: 'Multiple choice',
                    value: 'Multiple choice',
                  } as ItemProp,
                ]}
                onChange={(value: any) => {
                  setQuestionReq((preState) => {
                    return {
                      ...preState,
                      type: value,
                    };
                  });
                }}
                isInvalid={false}
                validationMessage={''}
              /> */}

              <SingleSelectDropdown
                title={'Status'}
                id="status"
                defaultSelected={questionReq.status}
                placeholder={''}
                dataList={[
                  {
                    label: 'Draft',
                    value: 'draft',
                  } as ItemProp,
                  {
                    label: 'Live',
                    value: 'live',
                  } as ItemProp,
                  {
                    label: 'Closed',
                    value: 'closed',
                  } as ItemProp,
                ]}
                onChange={(value: any) => {
                  setQuestionReq((preState) => {
                    return {
                      ...preState,
                      status: value,
                    };
                  });
                }}
                isInvalid={false}
                validationMessage={''}
              />

              <MultiSelectDropdown
                title={'Topic'}
                badgeBackgroud={'light-700'}
                badgeClassName={'rounded'}
                id={''}
                placeholder={''}
                defaultSelected={questionReq.topicIdList}
                dataList={topicList
                  .filter((topic: TopicRes) => {
                    return topic.subject.id == questionReq.subjectId;
                  })
                  .map((topic: TopicRes) => {
                    return {
                      label: topic.name.en,
                      value: topic.id,
                    } as ItemProp;
                  })}
                onChange={(values: any[]) => {
                  setQuestionReq((preState) => {
                    return {
                      ...preState,
                      topicIdList: values,
                    };
                  });
                }}
                isInvalid={false}
                validationMessage={''}
                showBadge={false}
              />

              <InputSelectView
                title={'Tags'}
                badgeBackgroud={'light-700'}
                badgeClassName={'rounded'}
                id="add_question_tag"
                defaultValues={questionReq.tagList}
                onChange={(dataList: string[]) => {
                  setQuestionReq((preState) => {
                    return {
                      ...preState,
                      tagList: dataList,
                    };
                  });
                }}
                showBadge={true}
              />

              {false && (
                <div className="d-flex align-items-center mb-3">
                  <h6 className="my-0 ms-0 me-2 font-weight-600 text-capitalize">
                    {t(I18nKey.MARKS_TEXT)}
                  </h6>
                  <EditScoreComponent
                    updateMarking={updateMarking}
                    marking={questionReq.marking}
                  />
                </div>
              )}
            </div>

            <div className="d-flex flex-column mb-3">
              <h6 className="mb-2 font-weight-600">Question</h6>

              <div className="custom-ckeditor--edit-view border border-light-blue rounded pb-2 px-2 mb-2">
                <CKInputView
                  initData={questionReq.description.en}
                  placeholder={'Please enter question'}
                  onChange={(data: string) => {
                    setQuestionReq((preState) => {
                      return {
                        ...preState,
                        description: {
                          en: data,
                        },
                      };
                    });
                  }}
                />
              </div>

              <QuestionHolderEditComponent
                type={questionReq.type as QuestionType}
                optionList={questionReq.optionList}
                updateOptionList={updateOptionList}
                updateAnswerList={updateAnswerList}
              />

              <div className="text-candidate mt-4">
                <Button
                  variant="primary"
                  type="submit"
                  className="text-white ft-14 font-weight-600"
                  onClick={updateQuestion}
                >
                  {t(I18nKey.SUBMIT)}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      {isDiscardModalShow && (
        <ConfirmationView
          isModalShow={isDiscardModalShow}
          onSuccess={() => {
            setIsDiscardModalShow(true);
          }}
          onCancel={() => {
            setIsDiscardModalShow(false);
          }}
          message={t(I18nKey.ARE_YOU_SURE_TEXT)}
          primaryBtnName="Continue"
        />
      )}
    </section>
  );
};

export default EditQuestionPage;
