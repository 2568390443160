import {
  TestRequest,
  TestSearchReq,
  TestTokenReq,
} from '../request/test.request';
import { TestInfoRes, TestRes, TestTokenRes } from '../response/test.response';
import httpClient from '../util/http-client';

export const TestService = {
  getTestById: async (id: string): Promise<(TestRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/test/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getAccessToken: async (
    testTokenReq: TestTokenReq,
  ): Promise<(TestTokenRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/test/access-token', testTokenReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getTestList: async (
    testSearchReq: TestSearchReq,
  ): Promise<(TestInfoRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/test-search', testSearchReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveTest: async (testRequest: TestRequest): Promise<(TestRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/test`, testRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateTest: async (
    id: string,
    testRequest: TestRequest,
  ): Promise<(TestRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(`/test/${id}`, testRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
