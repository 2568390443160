import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../../i18n/i18n.key';
import CreateSectionComponent from './section/create-section.component';
import { TestRequest, TestSection } from '../../../../request/test.request';
import { CreateTestStepsEnum } from '../../../../enum/test-type.enum';

export interface CreateTestComponentProps {
  testReq: TestRequest;
  updateSection: (section: TestSection) => void;
  addNewSection: () => void;
  deleteSection: (id: string) => void;
  moveToStep: (step: CreateTestStepsEnum) => void;
  saveDraft: () => void;
}

const CreateTestComponent: React.FC<CreateTestComponentProps> = ({
  testReq,
  updateSection,
  addNewSection,
  deleteSection,
  moveToStep,
  saveDraft,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex align-items-center w-100">
        <div className="ms-auto d-flex align-items-right mb-2">
          <Button
            variant="pale-lavender"
            className="mx-2 ft-14 font-weight-600"
            onClick={() => {
              moveToStep(CreateTestStepsEnum.TestSetup);
            }}
          >
            <span className="ms-2">{t(I18nKey.NEXT_TEXT)}</span>
          </Button>
          <Button
            variant="pale-lavender"
            className="mx-2 ft-14 font-weight-600"
            onClick={saveDraft}
          >
            <span className="ms-2">{t(I18nKey.SAVE_DRAFT_TEXT)}</span>
          </Button>
        </div>
      </div>
      {testReq.sectionList?.map((section: TestSection) => {
        return (
          <CreateSectionComponent
            updateSection={updateSection}
            section={section}
            deleteSection={deleteSection}
          />
        );
      })}

      <Button
        variant="white"
        className="ft-14 border-0 text-nero my-3 px-0 py-2 d-flex align-items-center "
        onClick={addNewSection}
      >
        <img src="/images/add.svg" alt=""></img>
        <span className="ms-2 font-weight-600">
          {t(I18nKey.ADD_SECTION_TEXT)}
        </span>
      </Button>
    </>
  );
};

export default CreateTestComponent;
