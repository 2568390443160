import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import QuestionHolderComponent from '../../shared/question/read/question-holder.component';
import QuestionInfoComponent from './question-info.component';
import ScoreComponent from './score.component';
import { QuestionRes } from '../../../response/question.response';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';

interface QuestionDetailsComponentProps {
  question: QuestionRes;
}

const QuestionDetailComponent: React.FC<QuestionDetailsComponentProps> = ({
  question,
}) => {
  const { t } = useTranslation();

  const { openUpdateQuestion } = useSSNavigate();

  return (
    <>
      <div className="d-flex align-items-center">
        {/* <QuestionInfoComponent question={question} /> */}

        <div className="ms-auto d-flex align-items-center">
          {/* <div className="bg-white px-2 py-2">
            <b className="me-1">{t(I18nKey.DIFFICULTY_TEXT)}</b>
            <span className="bg-light-pink py-2 px-3 text-orange font-weight-600 rounded">
              Medium
            </span>
          </div> */}

          <ScoreComponent marking={question.marking} />

          <Button
            onClick={() => {
              openUpdateQuestion(question.id);
            }}
            variant="aliceblue"
            className={`edit--scores-styles ms-2`}
          >
            <img src="/images/edit-pen.svg" height={20} alt="" />
          </Button>
        </div>
      </div>

      <QuestionHolderComponent question={question} />
    </>
  );
};

export default QuestionDetailComponent;
