import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { QuestionOption } from '../../../../../response/question.response';

const keyPadArray = ['7', '8', '9', '4', '5', '6', '3', '2', '1', '0'];

export interface IntigerAnsEditComponentProps {
  updateAnswerList: (answerList: string[]) => void;
  answerList?: string[];
}

const IntigerAnsEditComponent: React.FC<IntigerAnsEditComponentProps> = ({
  updateAnswerList,
  answerList,
}) => {
  const [selectedNum, setselectedNum] = useState(
    answerList && answerList.length > 0 ? answerList[0] : '',
  );
  const [inputvalue, setInputvalue] = useState(selectedNum);

  const removeNumber = () => {
    if (inputvalue) {
      let arr = inputvalue.split('');
      arr.pop();
      let newStr = arr.join('');
      setInputvalue(newStr);
      if (!newStr) {
        setselectedNum('');
      }
    }
  };

  const handleKeyPadAction = (e: any) => {
    let num = e.target.textContent;
    // let newNum: any = inputvalue + '' + num;
    setInputvalue(num);
    setselectedNum(num);
  };

  const handleInput = (e: any) => {
    let newNumberStr = e.target.value;
    newNumberStr = newNumberStr.replace(/[^0-9]/g, '');

    setInputvalue(newNumberStr);
    setselectedNum(newNumberStr);
  };

  useEffect(() => {
    if (selectedNum && selectedNum.length > 0) {
      updateAnswerList([selectedNum]);
    }
  }, [selectedNum]);

  return (
    <div className="number-keypad">
      <Form.Control
        type="text"
        id="numberKeyPadInput"
        aria-describedby="passwordHelpBlock"
        value={inputvalue}
        onChange={handleInput}
        className="text-center me-2 number-input no-pointer-events"
      />

      <div className="d-flex my-4 flex-wrap">
        {keyPadArray.map((num, index) => {
          return (
            <Button
              variant={selectedNum === num ? 'white' : 'whisper'}
              onClick={handleKeyPadAction}
              className={`w-70-px px-3 py-2 me-2 mb-2 text-center c-pointer ${
                selectedNum === num ? 'border-primary' : 'border-transparent'
              } `}
            >
              {num}
            </Button>
          );
        })}
        <Button
          variant="whisper"
          className="w-70-px px-3 py-2 me-2 mb-2 text-center"
          onClick={removeNumber}
        >
          <img src="/images/keypad-cross.svg" alt="" />
        </Button>
      </div>
    </div>
  );
};

export default IntigerAnsEditComponent;
