import { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TooltipView } from '../../../../../view/tooltip.view';
import AddQuestionComponent from './add-question/add-question.component';
import {
  SectionQuestion,
  TestSection,
} from '../../../../../request/test.request';
import { I18nKey } from '../../../../../i18n/i18n.key';
import { Marking } from '../../../../../model/marking.model';
import { QuestionRes } from '../../../../../response/question.response';
import ScoreComponent from '../score/score.component';
import SectionQuestionGroupComponent from '../question/section-question-group.component';
import { groupList } from '../../../../../util/group-convertor';
import { QuestionType } from '../../../../../enum/question-type.enum';

export interface CreateSectionComponentProps {
  section: TestSection;
  deleteSection: (id: string) => void;
  updateSection: (section: TestSection) => void;
}

const CreateSectionComponent: React.FC<CreateSectionComponentProps> = ({
  section,
  updateSection,
  deleteSection,
}) => {
  const { t } = useTranslation();
  const [sectionName, setSectionName] = useState(section.name.en);

  const [isEditSectionName, setIsEditSectionName] = useState(false);
  const [newSection, setSection] = useState<TestSection>(section);

  useEffect(() => {
    updateSection(newSection);
  }, [newSection]);

  const updateSelectedQuestion = (selectedQuestionList: QuestionRes[]) => {
    setSection((preState) => {
      return {
        ...preState,
        questionList: selectedQuestionList.map(
          (questionRes: QuestionRes, index: number) => {
            return {
              id: questionRes.id,
              question: questionRes,
              timed: false,
              marking: questionRes.marking,
              maxTime: 0,
              bonus: false,
              shuffling: false,
              order: index,
            } as SectionQuestion;
          },
        ),
      };
    });
  };

  const updateSectionQuestionMark = (marking: Marking, maxTime: number) => {
    setSection((preState) => {
      return {
        ...preState,
        timed: maxTime > 0 ? true : false,
        maxTime: maxTime * preState.questionList.length,
        questionList: preState.questionList.map(
          (sectionQuestion: SectionQuestion) => {
            return {
              ...sectionQuestion,
              marking: marking,
              timed: maxTime > 0 ? true : false,
              maxTime: maxTime,
            };
          },
        ),
      };
    });
  };

  const updateQuestionTypeMarkingAndDuration = (
    type: QuestionType,
    marking: Marking,
    maxTime: number,
  ) => {
    setSection((preState) => {
      return {
        ...preState,
        questionList: preState.questionList.map(
          (sectionQuestion: SectionQuestion) => {
            if (sectionQuestion.question.type == type) {
              return {
                ...sectionQuestion,
                marking: marking,
                timed: maxTime > 0 ? true : false,
                maxTime: maxTime,
              };
            } else {
              return sectionQuestion;
            }
          },
        ),
      };
    });
  };

  const updateQuestionMarkingAndDuration = (
    questionId: string,
    marking: Marking,
    maxTime: number,
  ) => {
    setSection((preState) => {
      return {
        ...preState,
        questionList: preState.questionList.map(
          (sectionQuestion: SectionQuestion) => {
            if (sectionQuestion.question.id == questionId) {
              return {
                ...sectionQuestion,
                marking: marking,
                timed: maxTime > 0 ? true : false,
                maxTime: maxTime,
              };
            } else {
              return sectionQuestion;
            }
          },
        ),
      };
    });
  };

  const deleteQuestion = (questionId: string) => {
    setSection((preState) => {
      return {
        ...preState,
        questionList: preState.questionList.filter(
          (sectionQuestion: SectionQuestion) => {
            return sectionQuestion.id != questionId;
          },
        ),
      };
    });
  };

  return (
    <>
      <Row className="border border-blue-yonder-100 mt-3 m-0 rounded">
        <Col className="p-0">
          <div className="d-flex align-items-center mt-2 px-3">
            {!isEditSectionName && (
              <>
                <span className="font-weight-600 ft-20 d-flex align-items-center">
                  {newSection.name.en}
                </span>
                <TooltipView id="editSectionName" message="Edit section name">
                  <Button
                    variant="default"
                    className="border-0 text-nero me-0 ms-2 px-0"
                    onClick={() => setIsEditSectionName(true)}
                  >
                    <img src="/images/edit-pen.svg" height={20} alt=""></img>
                  </Button>
                </TooltipView>{' '}
              </>
            )}
            {isEditSectionName && (
              <>
                <Form.Control
                  type="text"
                  className="w-auto border-bottom border-gray border-0 rounded-0 shadow-none"
                  onChange={(e: any) => {
                    setSectionName(e.target.value);
                  }}
                />
                <Button
                  variant="gray"
                  className="border-0 text-nero mx-2 py-1 px-2"
                  onClick={() => {
                    setIsEditSectionName(false);
                    setSection((preState) => {
                      return {
                        ...preState,
                        name: {
                          en: sectionName,
                        },
                      };
                    });
                  }}
                >
                  <img src="/images/check_done.svg" height={16} alt=""></img>
                </Button>
              </>
            )}
            <div className="ms-auto d-flex align-items-center">
              <AddQuestionComponent
                selectedQuestionList={
                  newSection.questionList
                    ? newSection.questionList.map(
                        (sectionQuestion: SectionQuestion) => {
                          return sectionQuestion.question;
                        },
                      )
                    : []
                }
                updateSelectedQuestion={updateSelectedQuestion}
              />

              <Button
                variant="default"
                className="border-0 text-nero me-0 ms-2 px-0"
                onClick={() => {
                  deleteSection(newSection.id);
                }}
              >
                <img src="/images/delete.svg" height={20} alt=""></img>
              </Button>
            </div>
          </div>
          <div className="px-3 my-3">
            {groupList(
              newSection.questionList,
              (sectionQuestion: SectionQuestion) => {
                return sectionQuestion.question.type;
              },
            ).map((sectionQuestionList: SectionQuestion[], index: number) => {
              return (
                <SectionQuestionGroupComponent
                  sectionQuestionList={sectionQuestionList}
                  deleteQuestion={deleteQuestion}
                  updateQuestionMarkingAndDuration={
                    updateQuestionMarkingAndDuration
                  }
                  updateQuestionTypeMarkingAndDuration={
                    updateQuestionTypeMarkingAndDuration
                  }
                />
              );
            })}
          </div>

          <div className="bg-aliceblue px-3 py-2 rounded-bottom d-flex align-items-center">
            <div className="font-weight-600 bg-white px-3 py-2 border border-blue-yonder-100 me-2 rounded-2 d-flex">
              <span className="me-1">{section.fullMark}</span>
              {t(I18nKey.MARKS_TEXT)}
            </div>
            <div className="font-weight-600 bg-white px-3 py-2 border border-blue-yonder-100 me-2 rounded-2 d-flex">
              <span className="me-1">{section.questionList.length}</span>
              {t(I18nKey.QUESTIONS_TEXT)}
            </div>
            <div className="ms-auto d-flex align-items-center">
              <ScoreComponent
                updateQuestionMark={updateSectionQuestionMark}
                marking={section.questionList[0]?.marking}
                maxTime={section.questionList[0]?.maxTime}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CreateSectionComponent;
